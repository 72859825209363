import find from 'lodash/find'

/**
 * Enum for Item Condition.
 * @readonly
 * @enum {{key: string, value: string}}
 */

const itemConditionEnum = Object.freeze({
  NEW: { key: 'NEW', value: 'NEW', text: 'New' },
  NEW_WITH_WARRANTY: { key: 'NEW_WITH_WARRANTY', value: 'NEW_WITH_WARRANTY', text: 'New with Warranty' },
  NEW_OPEN_BOX: { key: 'NEW_OPEN_BOX', value: 'NEW_OPEN_BOX', text: 'New Open Box' },
  NEW_OEM: { key: 'NEW_OEM', value: 'NEW_OEM', text: 'New OEM' },
  REFURBISHED: { key: 'REFURBISHED', value: 'REFURBISHED', text: 'Refurbished' },
  REFURBISHED_WITH_WARRANTY: { key: 'REFURBISHED_WITH_WARRANTY', value: 'REFURBISHED_WITH_WARRANTY', text: 'Refurbished with Warranty' },
  USED_REFURBISHED: { key: 'USED_REFURBISHED', value: 'USED_REFURBISHED', text: 'Used - Refurbished' },
  USED_LIKE_NEW: { key: 'USED_LIKE_NEW', value: 'USED_LIKE_NEW', text: 'Used - Like New' },
  USED_VERY_GOOD: { key: 'USED_VERY_GOOD', value: 'USED_VERY_GOOD', text: 'Used - Very Good' },
  USED_GOOD: { key: 'USED_GOOD', value: 'USED_GOOD', text: 'Used - Good' },
  USED_ACCEPTABLE: { key: 'USED_ACCEPTABLE', value: 'USED_ACCEPTABLE', text: 'Used - Acceptable' },
  USED_POOR: { key: 'USED_POOR', value: 'USED_POOR', text: 'Used - Poor' },
  COLLECTIBLE_LIKE_NEW: { key: 'COLLECTIBLE_LIKE_NEW', value: 'COLLECTIBLE_LIKE_NEW', text: 'Collectible - Like New' },
  COLLECTIBLE_VERY_GOOD: { key: 'COLLECTIBLE_VERY_GOOD', value: 'COLLECTIBLE_VERY_GOOD', text: 'Collectible - Very Good' },
  COLLECTIBLE_GOOD: { key: 'COLLECTIBLE_GOOD', value: 'COLLECTIBLE_GOOD', text: 'Collectible - Good' },
  COLLECTIBLE_ACCEPTABLE: { key: 'COLLECTIBLE_ACCEPTABLE', value: 'COLLECTIBLE_ACCEPTABLE', text: 'Collectible - Acceptable' },
  COLLECTIBLE_POOR: { key: 'COLLECTIBLE_POOR', value: 'COLLECTIBLE_POOR', text: 'Collectible - Poor' },
  CLUB: { key: 'CLUB', value: 'CLUB', text: 'Club' },
})

function getItemConditionEnum(predicate, predicateKey = 'key') {
  const result = find(itemConditionEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getItemConditionEnumList() {
  const options = []
  Object
    .keys(itemConditionEnum)
    .map(key => options.push(
      {
        value: itemConditionEnum[key].value,
        text: itemConditionEnum[key].text,
      },
    ))
  return options
}

export default itemConditionEnum

export {
  getItemConditionEnum,
  getItemConditionEnumList,
}
