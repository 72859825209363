const WFSCarrierTypeEnum = Object.freeze({
  WFS: { key: 'WFS', value: 'WFS', text: 'WFS preferred carrier' },
  OTHER: { key: 'OTHER', value: 'OTHER', text: 'I will find a carrier or use my vehicles' },
  list: () => Object.values(WFSCarrierTypeEnum)
    .filter(value => typeof value === 'object')
    .map(value => ({
      value: value.value,
      text: value.text,
    })),
  find: query => Object.values(WFSCarrierTypeEnum)
    .find(value => typeof value === 'object' && (value.key === query || value.value === query)),
})

const WFSFreightTypeEnum = Object.freeze({
  SP:
  {
    key: 'SP',
    carrier: WFSCarrierTypeEnum.WFS.value,
    value: 'SP',
    text: 'Small Parcel',
  },
  LTL: {
    key: 'LTL',
    carrier: WFSCarrierTypeEnum.WFS.value,
    value: 'LTL',
    text: 'Less than truckload',
  },
  SPO:
  {
    key: 'SP0',
    carrier: WFSCarrierTypeEnum.OTHER.value,
    value: 'SP0',
    text: 'Small Parcel',
  },
  LTLO: {
    key: 'LTL0',
    carrier: WFSCarrierTypeEnum.OTHER.value,
    value: 'LTL0',
    text: 'Less than truckload',
  },
  list: carrier => Object.values(WFSFreightTypeEnum)
    .filter(value => typeof value === 'object' && value.carrier === carrier)
    .map(value => ({
      value: value.value,
      text: value.text,
    })),
  find: query => Object.values(WFSFreightTypeEnum).find(value => typeof value === 'object' && (value.key === query || value.value === query)),
})

export default WFSCarrierTypeEnum
export { WFSFreightTypeEnum }
