import find from 'lodash/find'
/**
 * Enum for Amazon Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const amzShipmentStatusEnum = Object.freeze({
  ALL: { key: 'ALL', value: 'ALL', text: 'Amz Status (All)' },
  WORKING: { key: 'WORKING', value: 'WORKING', text: 'Working' },
  SHIPPED: { key: 'SHIPPED', value: 'SHIPPED', text: 'Shipped' },
  RECEIVING: { key: 'RECEIVING', value: 'RECEIVING', text: 'Receiving' },
  CANCELLED: { key: 'CANCELLED', value: 'CANCELLED', text: 'Cancelled' },
  DELETED: { key: 'DELETED', value: 'DELETED', text: 'Deleted' },
  ERROR: { key: 'ERROR', value: 'ERROR', text: 'Error' },
  IN_TRANSIT: { key: 'IN_TRANSIT', value: 'IN_TRANSIT', text: 'In Transit' },
  DELIVERED: { key: 'DELIVERED', value: 'DELIVERED', text: 'Delivered' },
  CHECKED_IN: { key: 'CHECKED_IN', value: 'CHECKED_IN', text: 'Checked In' },
  CLOSED: { key: 'CLOSED', value: 'CLOSED', text: 'Closed' },
})

function getAmzShipmentStatusEnum(predicate, predicateKey = 'key') {
  const result = find(amzShipmentStatusEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getListAmzShipmentStatusEnum() {
  const result = Object.keys(amzShipmentStatusEnum).map(key => amzShipmentStatusEnum[key])
  return result
}

export default amzShipmentStatusEnum

export {
  getAmzShipmentStatusEnum,
  getListAmzShipmentStatusEnum,
}
