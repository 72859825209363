/* eslint-disable no-unused-vars */
import {
  onMounted, computed, ref, watch, toRef, nextTick,
} from '@vue/composition-api'
import { useWindowSize } from '@vueuse/core'

export default function useWfsForce() {
  const { width, height } = useWindowSize()

  const fullHeight = ref({
    height: '',
    scroolHeight: '',
  })

  function contentItemSize() {
    const header = document.getElementById('app-navbar')
    const headerHeight = header.clientHeight
    const footer = document.getElementById('app-footer')
    const footerHeight = footer.clientHeight
    const breadcrumb = document.getElementById('app-breadcrumb')
    const breadcrumbHeight = breadcrumb.clientHeight
    return headerHeight + footerHeight + breadcrumbHeight
  }

  function handleResize() {
    fullHeight.value.height = `${(height.value - contentItemSize())}px`
    fullHeight.value.scroolHeight = `${(height.value - contentItemSize() - 70)}px`
  }

  onMounted(() => {
    nextTick(() => {
      window.addEventListener('resize', handleResize)
    })
  })
  onMounted(() => {
    window.removeEventListener('resize', handleResize)
  })

  return {
    fullHeight,
    handleResize,
  }
}
