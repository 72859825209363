<template>
  <div class="container-fluid">
    <div class="row">
      <div v-if="showStoreSelect" class="col-12 col-lg-6 px-0 mb-1 p-sm-0">
        <div class="card card-psh border m-half h-100">
          <div class="bg-light-primary rounded-top p-1">
            <div class="psh-header mb-0 d-flex align-items-center">
              <div class="mr-1 border-0">
                <p-icon name="bi-printer-fill" size="36px" color="primary" />
              </div>
              <div class="d-flex flex-column">
                <h4 class="card-title mb-25">
                  PSH Printer Agent®
                </h4>
                <p class="card-text mb-0">
                  Support all with Zebra and DYMO
                </p>
              </div>
              <div class="flex-grow-1"></div>
              <p-avatar color="primary bg-darken-4" size="48px" :src="require('@/assets/images/logo/logo.svg')" />
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                To download the latest PSH Printer Agent® Software, please cick the download button below.
              </div>
              <div class="col-12 py-1">
                <div class="d-flex align-items-center">
                  <div>
                    <p-avatar color="primary bg-darken-4" :src="require('@/assets/images/pages/windows_logo.png')" />
                  </div>
                  <div class="px-1">
                    <h6 class="mb-0">
                      Windows
                    </h6>
                    <div class="d-flex flex-column">
                      <small class="mb-half">PSH Printer Agent v1.0.0 *compatibility starts from Windows OS 8.1 to higher.</small>
                      <a class="btn btn-sm btn-primary" :href="agentDownloadLink" style="max-width:140px">Download</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showStoreSelect" class="col-12 col-lg-6 px-0 mb-1 p-sm-0">
        <div class="card card-psh border m-half h-100">
          <div class="bg-light-primary rounded-top p-1">
            <div class="psh-header mb-0 d-flex align-items-center">
              <div class="mr-1 border-0">
                <p-icon name="bi-printer-fill" size="36px" color="primary" />
              </div>
              <div class="d-flex flex-column">
                <h4 class="card-title mb-25">
                  DYMO Printer Software
                </h4>
                <p class="card-text mb-0">
                  Support all with all 450 series/4XL, 550 series/5XL and LabelManagers
                </p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                To download the latest DYMO Software Drivers for all LabelWriters® and LabelManager®, please cick the download button below.
              </div>
              <div class="col-12 py-1">
                <div class="d-flex align-items-center">
                  <div>
                    <p-avatar color="primary bg-darken-4" :src="require('@/assets/images/pages/windows_logo.png')" />
                  </div>
                  <div class="px-1">
                    <h6 class="mb-0">
                      Windows
                    </h6>
                    <div class="d-flex flex-column">
                      <small class="mb-half">DCD Windows v1.3.2.18 *compatibility starts from Windows OS 8.1 to higher.</small>
                      <a class="btn btn-sm btn-primary" href="https://prepshiphub-dpc-public.s3.us-east-2.amazonaws.com/apps/DCDSetup1.3.2.18.exe" style="max-width:140px">Download</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 px-0 mb-1 p-sm-0">
        <div class="card card-psh border m-half h-100">
          <div class="bg-light-primary rounded-top p-1">
            <div class="psh-header mb-0 d-flex align-items-center">
              <div class="mr-1 border-0">
                <p-icon name="bi-printer-fill" size="36px" color="primary" />
              </div>
              <div class="d-flex flex-column">
                <h4 class="card-title mb-25">
                  Manage Printers
                </h4>
                <p class="card-text mb-0">
                  Set your default printer
                </p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div v-if="printers.length === 0" class="col-12">
              <div class="d-flex flex-column justify-content-center align-items-center h-100">
                <p-icon name="bi-printer-fill mb-1" size="36px" color="danger" />
                <h5>
                  No printer found
                </h5>
              </div>
            </div>
            <div v-if="showStoreSelect & printers.length > 0" class="px-1 py-half">
              <!-- Select Store -->
              <div class="row">
              <div class="form-group col-sm-12 col-lg-4">
                <label for="text-account-no">Account/Suite No</label>
                <dx-util-text-box
                  id="text-account-no"
                  v-model="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Account or Suite No"
                  @key-down="onGetCompanyByAccount"
                />
              </div>
              <div class="form-group col-sm-12 col-lg-4">
                <label for="text-company-id">Company Name</label>
                <dx-util-text-box
                  id="text-company-id"
                  v-model="companyName"
                  placeholder="Company Name"
                />
              </div>
              <div class="form-group col-sm-12 col-lg-4">
                <label for="text-company-id">Store Name</label>
                <dx-util-select-box
                  v-model="selectedStoreId"
                  :search-enabled="true"
                  :data-source="storeDataSource"
                  search-mode="contains"
                  search-expr="text"
                  display-expr="text"
                  value-expr="value"
                  placeholder="Store"
                  @selection-changed="onSelectedStore"
                />
              </div>
              </div>
              <!-- /Select Store -->
            </div>
            <div v-for="(printer, index) in printers" :key="index" class="col cursor-pointer" @click="setPrinterLabel(printer)">
              <div class="card border">
                <b-card-body class="d-flex justify-content-between align-items-center">
                  <div class="truncate">
                    <h4 class="mb-25 font-weight-bolder">
                      {{ printer.VendorName }}
                    </h4>
                    <span>{{ printer.Name }}</span>
                  </div>
                  <p-avatar :color="isCurrentPrinter(printer) ? `light-success` : `light-warning`" size="45px">
                    <p-icon size="28px" name="bi-printer-fill" />
                  </p-avatar>
                </b-card-body>
              </div>
            </div>
            <div v-for="(label, index) in printerLabels" :key="index" class="business-item px-1 py-half">
              <div class="d-flex align-items-center justify-content-start">
                <div class="d-flex align-items-center">
                  <div class="mr-1">
                    <p-avatar rounded size="36px" :color="label.printer.IsConnected ? 'light-success':'light-danger'">
                      <p-icon size="24px" name="bi-printer-fill" />
                    </p-avatar>
                  </div>
                  <div>
                    <h6 class="mb-half">
                      {{ label.printer.Name }}
                    </h6>
                    <small>{{ label.LabelName }}</small>
                  </div>
                </div>
                <div v-if="!label.printer.IsConnected" class="ml-1">
                  <span class="text-danger">
                    Cannot connected to this printer
                  </span>
                </div>
                <div class="ml-auto">
                  <dx-util-button
                    icon="save"
                    :text="isCurrentPrinterOption(label) ? 'Default Printer And Label' : 'Set As Default Printer And Label'"
                    :type="isCurrentPrinterOption(label) ? 'success' : 'normal'"
                    styling-mode="contained"
                    :disabled="isCurrentPrinterOption(label)"
                    @click="createOrUpdateCurrentPrinterSetting($event,label)"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 px-0 mb-1 p-sm-0">
        <div class="card card-psh border m-half h-100">
          <div class="bg-light-primary rounded-top p-1">
            <div class="psh-header mb-0 d-flex align-items-center">
              <div class="mr-1 border-0">
                <p-icon name="bi-printer-fill" size="36px" color="primary" />
              </div>
              <div class="d-flex flex-column">
                <h4 class="card-title mb-25">
                  Manage Label Printing
                </h4>
                <p class="card-text mb-0">
                  Manage printing options
                </p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div v-if="printers.length > 0" class="col-12 col-xl-9">
              <div class="d-flex justify-content-stretch my-1 align-items-center border p-1 rounded">
                <div class="w-100">
                  <h6 class="mb-25">
                    After Adding To FBA Batch
                  </h6>
                  <small>Labels will print for your items after adding it to an FBA batch.</small>
                </div>
                <div class="custom-control custom-switch">
                  <input id="customSwitch1" v-model="manageLabelPrintering.isAddedFBABatch" type="checkbox" class="custom-control-input" @change="onManageLabelPrinting()">
                  <label class="custom-control-label" for="customSwitch1"></label>
                </div>
              </div>
              <div class="d-flex justify-content-stretch my-1 align-items-center border p-1 rounded">
                <div class="w-100">
                  <h6 class="mb-25">
                    After Adding To MFN Batch
                  </h6>
                  <small>Labels will print for your items after adding it to an MFN batch.æ</small>
                </div>
                <div class="custom-control custom-switch">
                  <input id="customSwitch2" v-model="manageLabelPrintering.isAddedMFNBatch" type="checkbox" class="custom-control-input" @change="onManageLabelPrinting()">
                  <label class="custom-control-label" for="customSwitch2"></label>
                </div>
              </div>
              <div class="d-flex justify-content-stretch my-1 align-items-center border p-1 rounded">
                <div class="w-100">
                  <h6 class="mb-25">
                    After Adding To MFN Batch
                  </h6>
                  <small>Additional Labels will print when you increase the quantity of an item it your batch.</small>
                </div>
                <div class="custom-control custom-switch">
                  <input id="customSwitch3" v-model="manageLabelPrintering.isEditedQuantity" type="checkbox" class="custom-control-input" @change="onManageLabelPrinting()">
                  <label class="custom-control-label" for="customSwitch3"></label>
                </div>
              </div>
              <div class="d-flex justify-content-stretch my-1 align-items-center border p-1 rounded">
                <div class="w-100">
                  <h6 class="mb-25">
                    During Scan To Pack
                  </h6>
                  <small>Labels will print when you scan an item into box during the Box Content process.</small>
                </div>
                <div class="custom-control custom-switch">
                  <input id="customSwitch4" v-model="manageLabelPrintering.isDuringScanPack" type="checkbox" class="custom-control-input" @change="onManageLabelPrinting()">
                  <label class="custom-control-label" for="customSwitch4"></label>
                </div>
              </div>
              <div class="d-flex justify-content-stretch my-1 align-items-center border p-1 rounded">
                <div class="w-100">
                  <h6 class="mb-25">
                    Include Expiration Date
                  </h6>
                  <small>If you provide an expiration date, it will appear on any label for that item.</small>
                </div>
                <div class="custom-control custom-switch">
                  <input id="customSwitch5" v-model="manageLabelPrintering.isIncludedExp" type="checkbox" class="custom-control-input" @change="onManageLabelPrinting()">
                  <label class="custom-control-label" for="customSwitch5"></label>
                </div>
              </div>
              <div class="d-flex justify-content-stretch my-1 align-items-center border p-1 rounded">
                <div class="w-100">
                  <h6 class="mb-25">
                    Print Box Name
                  </h6>
                  <small>Print box name after box creation.</small>
                </div>
                <div class="custom-control custom-switch">
                  <input id="customSwitch6" v-model="manageLabelPrintering.isPrintBoxName" type="checkbox" class="custom-control-input" @change="onManageLabelPrinting()">
                  <label class="custom-control-label" for="customSwitch6"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import store from '@/store'
import usePrinterHelper from '@/libs/printer/printer-helper'
import isEmpty from 'lodash/isEmpty'
import { Notify } from '@robustshell/utils/index'
import managedKeysEnum from '@/enums/managedKeysEnum'
import valueTypeEnum from '@/enums/valueTypeEnum'
import scopeTypeEnum from '@/enums/scopeTypeEnum'
import tenantService from '@/http/requests/tenant/tenan.settings'
import useCurrentUser from '@/libs/app/current-user'
import companyService from '@/http/requests/company/companyService'
import commonEntityService from '@/http/requests/common/commonEntityService'
import printerserver from '@/http/requests/printer/printerserver'

export default {
  props: {
    storeId: {
      type: Number,
      required: false,
      default: 0,
    },
    showStoreSelect: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  setup() {
    const {
      userTenantId,
    } = useCurrentUser()
    const {
      selectedPrinter,
      printers,
      labels,
      getLabels,
      checkVersion,
    } = usePrinterHelper()

    return {
      selectedPrinter,
      printers,
      labels,
      userTenantId,
      checkVersion,
      getLabels,
    }
  },
  data() {
    return {
      logo: require('@/assets/images/logo/logo.svg'),
      height: 'auto',
      printerStatus: {
        isBrowserSupported: false,
        isFrameworkInstalled: false,
        isWebServicePresent: false,
        errorDetails: '',
        error: '',
      },
      printerLabels: [],
      manageLabelPrintering: {
        isAddedFBABatch: true,
        isAddedMFNBatch: false,
        isEditedQuantity: true,
        isDuringScanPack: true,
        isIncludedExp: true,
        isPrintBoxName: true,
      },
      currentPrinterSetting: {},
      currentSelectedPrinter: {
        Address: '',
        LabelId: '',
        Name: '',
        LabelName: '',
        PrinterName: '',
        VendorName: '',
        printer: {},
      },
      storeDataSource: [],
      companyName: '',
      accountNo: '',
      selectedStoreId: 0,
      agentDownloadLink: '',
    }
  },
  watch: {
    storeId: {
      immediate: true,
      handler(current) {
        this.selectedStoreId = current
      },
    },
  },
  async mounted() {
    this.getCurrentPrinterSetting()
    await this.getAgentDownloadLink()
  },
  methods: {
    async getAgentDownloadLink() {
      const result = await printerserver.getSystemDataByKey('AgentDownloadLink')
      this.agentDownloadLink = result.value
    },
    async setPrinterLabel(printerInfo) {
      await this.getLabels(printerInfo.VendorName)
      this.printerLabels.length = 0
      this.labels.forEach(item => {
        if (printerInfo) {
          const label = {
            printer: printerInfo,
            Id: item.Id,
            LabelName: item.Name,
          }
          this.printerLabels.push(label)
        }
      })
    },
    getCurrentPrinterSetting() {
      if (this.printers.length === 0) return
      if (this.selectedStoreId && this.selectedStoreId > 0) {
        tenantService.findByKeyAndReference(managedKeysEnum.CURRENTSELECTEDPRINTER.key, this.selectedStoreId).then(result => {
          const data = { ...result.data }
          if (data.id) {
            const printer = JSON.parse(data.value)
            this.currentPrinterSetting = {
              id: data.id,
              key: data.key,
              value: printer,
              tenantId: data.tenantId,
              scopeType: scopeTypeEnum.TENANTSCOPEFRONTEND.key,
              valueType: data.valueType,
              version: data.version,
              referenceId: data.referenceId,
            }
            this.currentSelectedPrinter = {
              Address: printer.Address,
              LabelId: printer.LabelId,
              LabelName: printer.LabelName,
              PrinterName: printer.PrinterName,
              VendorName: printer.VendorName,
              printer: { ...printer.printer },
            }
            if (!isEmpty(printer.manageLabelPrintering)) {
              this.manageLabelPrintering = { ...printer.manageLabelPrintering }
              // Initial set vuex store
              const currentSelectedPrinter = {
                LabelId: printer.LabelId,
                LabelName: printer.LabelName,
                Address: printer.Address,
                PrinterName: printer.PrinterName,
                VendorName: printer.VendorName,
                printer: { ...printer.printer },
                manageLabelPrintering: { ...printer.manageLabelPrintering },
              }
              this.updateStore(currentSelectedPrinter)
            }
          }
        })
      } else {
        Notify.danger('Please select a store')
      }
    },
    isCurrentPrinter(printer) {
      let result = false
      if (!isEmpty(this.currentSelectedPrinter)) {
        result = printer.Name === this.currentSelectedPrinter.PrinterName
      }
      return result
    },
    isCurrentPrinterOption(label) {
      let result = false
      if (!isEmpty(this.currentSelectedPrinter)) {
        result = label.Id === this.currentSelectedPrinter.LabelId && label.printer.Name === this.currentSelectedPrinter.PrinterName
      }
      return result
    },
    createOrUpdateCurrentPrinterSetting(e, item) {
      if (!this.selectedStoreId && this.selectedStoreId === 0) {
        Notify.danger('Please select a store')
        return
      }
      let data = {
        LabelId: item.Id,
        LabelName: item.LabelName,
        VendorName: item.printer.VendorName,
        Address: item.printer.Address,
        PrinterName: item.printer.Name,
        printer: item.printer,
        manageLabelPrintering: this.manageLabelPrintering,
      }

      data = JSON.stringify(data)
      const currentPrinter = {
        id: this.settingId,
        key: managedKeysEnum.CURRENTSELECTEDPRINTER.key,
        value: data,
        tenantId: this.userTenantId,
        scopeType: scopeTypeEnum.TENANTSCOPEFRONTEND.key,
        valueType: valueTypeEnum.JSON.key,
        version: null,
        referenceId: this.selectedStoreId,
      }
      if (this.currentPrinterSetting.id) {
        this.currentPrinterSetting.value = data
        const currentPrinterSetting = { ...this.currentPrinterSetting }
        tenantService
          .update(currentPrinterSetting)
          .then(result => {
            this.getCurrentPrinterSetting()
          })
          .then(() => {
            this.updateStore(data)
          })
      } else {
        tenantService
          .create(currentPrinter)
          .then(result => {
            this.getCurrentPrinterSetting()
          })
          .then(() => {
            this.updateStore(data)
          })
      }
    },
    onManageLabelPrinting() {
      if (this.currentPrinterSetting.id) {
        let data = {
          LabelId: this.currentSelectedPrinter.LabelId,
          LabelName: this.currentSelectedPrinter.LabelName,
          VendorName: this.currentSelectedPrinter.VendorName,
          Address: this.currentSelectedPrinter.Address,
          PrinterName: this.currentSelectedPrinter.PrinterName,
          printer: this.currentSelectedPrinter.printer,
          manageLabelPrintering: this.manageLabelPrintering,
        }
        data = JSON.stringify(data)

        this.currentPrinterSetting.value = data
        const currentPrinterSetting = { ...this.currentPrinterSetting }
        tenantService
          .update(currentPrinterSetting)
          .then(result => {
            this.getCurrentPrinterSetting()
          })
          .then(() => {
            this.updateStore(data)
          })
      } else {
        Notify.danger('Firstly, you must set the default printer.')
      }
    },
    updateStore(settings) {
      store.dispatch('fbaShipment/setPrinterSettings', settings)
      localStorage.setItem('printerSettings', JSON.stringify(settings))
    },
    onGetCompanyByAccount(e) {
      if (e.event.keyCode !== 13) return
      const accountNo = e.event.target.value
      this.getCompanyByAccountNo(accountNo)
    },
    getCompanyByAccountNo(accountNo) {
      if (!accountNo || accountNo === null || accountNo === 0 || accountNo === '' || accountNo === undefined) {
        Notify.error('Please enter a valid account no')
        return
      }
      companyService.fetchByAccountNo(accountNo).then(result => {
        this.companyName = result.data.name
        this.getStoresByAccountNo(accountNo)
      })
    },
    getStoresByAccountNo(accountNo) {
      commonEntityService
        .fetchStoresByParentCompanyAccountNo(accountNo)
        .then(result => {
          const data = result.data
          this.setStoreDataSource(data)
        })
    },
    setStoreDataSource(data) {
      this.storeDataSource.splice(0, this.storeDataSource.length)
      this.storeDataSource.push({
        value: 0,
        text: 'Select Store',
      })
      data.forEach(element => {
        this.storeDataSource.push({
          value: element.id,
          text: element.name,
        })
      })
    },
    onSelectedStore(e) {
      if (e && e.selectedItem && e.selectedItem.value !== 0) {
        this.selectedStoreId = e.selectedItem.value
        this.storeName = e.selectedItem.text
        this.getCurrentPrinterSetting()
      } else {
        this.selectedStoreId = 0
        this.storeName = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .status-printer{
    height: 7.5rem !important;
  }
  .psh-logo {
  height: 72px !important;
  width: 72px !important;
  background-image: url('../../../assets/images/logo/logo.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
