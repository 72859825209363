<template>
  <div class="container-fluid">
    <div class="row d-flex aling-items-stretch" :style="{ height: fullHeight.height }">
      <!--begin::Shipment Plans-->
      <div class="col-md-2 px-0">
        <!--begin::Shipment Plan List-->
        <div class="card card-batch batch-light mb-0 px-0 h-100">
          <div class="card-body p-0">
            <div class="d-flex flex-column justify-content-start">
              <div class="card-batch-title">
                <div class="d-flex flex-row justify-content-start px-0">
                  <h6 class="mr-auto m-0 align-self-center pl-1">
                    Shipment Plans
                  </h6>
                  <dx-switch
                    v-model="showOnlyOpenShipmentPlans"
                    :height="36"
                    :width="70"
                    switched-off-text="All"
                    switched-on-text="Open"
                    hint="Show only OPEN shipment plans"
                    @value-changed="onShowOnlyOpenShipmentPlans"
                  />
                  <dx-util-button
                    type="normal"
                    icon="plus"
                    hint="Create new WFS Shipment Plan"
                    :element-attr="{class: 'btn-primary'}"
                    @click="onClickCreateShipmentPlan"
                  />
                  <dx-util-button
                    type="normal"
                    icon="refresh"
                    hint="Refresh"
                    :element-attr="{class: 'btn-primary'}"
                    @click="onClickRefreshShipmentPlans"
                  />
                  <dx-util-button
                    type="normal"
                    icon="print"
                    hint="Printer Settings"
                    :element-attr="{class: 'btn-primary'}"
                    @click="onShowPrinterSettings"
                  />
                </div>
              </div>
              <div class="w-100">
                <dx-util-text-box v-model="searchText" mode="search" styling-mode="filled" placeholder="Search" :input-attr="{ style: 'color: #293046 !important;' }" @key-down="onFilterBatch" />
              </div>
              <dx-util-scroll-view show-scrollbar="onHover" direction="vertical" :height="fullHeight.scroolHeight">
                <div>
                  <div
                    v-for="(item, index) in shipmentPlans"
                    :key="`${item.id}`"
                    class="d-flex flex-row justify-content-start batch-list"
                    :class="{ 'selected-batch': selectedIndex === index }"
                    @click="onSelectedShipmentPlan(item, index)"
                  >
                    <div v-if="item.batchStatus === 'open'" class="align-self-center text-center mr-half" style="font-size: 2rem; padding-left: 0.625rem">
                      <b-icon :icon="item.packingType === $enums.PackingTypeEnum.individual.key ? 'layers-fill' : 'archive-fill'" class="border icon-border-warning rounded p-half" />
                    </div>
                    <div v-else-if="item.batchStatus === 'boxing'" class="align-self-center text-center mr-half" style="font-size: 2rem; padding-left: 0.625rem">
                      <b-icon-box class="border icon-border-success rounded p-half" />
                    </div>
                    <div v-else class="align-self-center text-center mr-half" style="font-size: 2rem; padding-left: 0.625rem">
                      <b-icon icon="truck" variant="secondary" sclass="mr-half" />
                    </div>
                    <div class="d-flex flex-column justify-content-start flex-grow-1 mr-half">
                      <p class="mb-0 list-item-title">
                        {{ item.batchName }}
                      </p>
                      <p class="mb-0 list-item-info">
                        {{ getEnumToValue($enums.WorkflowEnum, item.workflow).text }} {{ getEnumToValue($enums.ChannelEnum, item.channel).text }}
                      </p>
                    </div>
                    <div class="align-self-center mr-half">
                      <span v-b-tooltip.hover="{ variant: 'success' }" title="Item Count" class="badge badge-success">{{ item.itemCount }}</span>
                    </div>
                    <div class="align-self-center mr-half">
                      <span v-b-tooltip.hover="{ variant: 'info' }" title="MSKU Count" class="badge badge-primary">{{ item.skuCount }}</span>
                    </div>
                    <div class="align-self-center mr-half">
                      <span v-b-tooltip.hover="{ variant: 'warning' }" title="Batch Count" class="badge badge-warning">{{ item.batchCount }}</span>
                    </div>
                    <div class="align-self-center">
                      <b-dropdown size="md" variant="link" boundary="window" toggle-class="text-decoration-left p-0" no-caret right>
                        <template #button-content>
                          <b-icon icon="three-dots-vertical" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item :disabled="item.batchStatus !== 'open' && item.batchStatus !== 'boxing'" @click="onClickEditShipmentPlan(item, index)">
                          <b-icon icon="pencil-square" variant="warning" scale="1" class="mr-half" />
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item :disabled="item.batchStatus !== 'open'" @click="onClickDeleteShipmentPlan(item.id)">
                          <b-icon icon="trash" variant="danger" scale="1" class="mr-half" />
                          Delete
                        </b-dropdown-item>
                        <b-dropdown-item :disabled="item.batchStatus !== 'open'" @click="onClickSubmitShipmentPlan(item.id)">
                          <b-icon icon="cloud-upload-fill" variant="primary" scale="1" class="mr-half" />
                          Submit
                        </b-dropdown-item>
                        <b-dropdown-item :disabled="item.batchStatus !== 'boxing'" @click="onClickCloseShipmentPlan(item.id)">
                          <b-icon icon="check-square-fill" variant="success" scale="1" class="mr-half" />
                          Close
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </dx-util-scroll-view>
            </div>
          </div>
        </div>
        <!--end::Shipment Plan List-->
        <!--begin::Shipment Plan Create-->
        <dx-util-popup
          ref="createShipmentPlanPopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="640"
          :height="720"
          title="Create New Shipment Plan"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonShipmentPlanPopupOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonShipmentPlanPopupOptions" />
          <form @submit="handleSubmitShipmentPlanFrom">
            <dx-util-form
              ref="shipmentPlanFormRef"
              :form-data="shipmentPlanFormData"
              :col-count="1"
              :show-colon-after-label="false"
              :scrolling-enabled="true"
              label-location="top"
              @field-data-changed="onShipmentPlanFormFieldDataChanged"
              >
              <template #selected-address-template="{}">
                <div class="d-flex justify-content-start">
                  <div v-html="selectedShippingAddress" />
                </div>
              </template>
              <dx-util-simple-item data-field="batchName" editor-type="dxTextBox" :editor-options="shipmentPlanNameOptions">
                <dx-util-required-rule
                  message="Batch name is required."
                />
              </dx-util-simple-item>
              <dx-util-simple-item template="selected-address-template" />
              <dx-util-simple-item>
                <template #default>
                  <dx-util-select-box
                    v-model="shipmentPlanFormData.fromAddress"
                    :data-source="shippingAddress"
                    display-expr="text"
                    value-expr="id"
                    item-template="item"
                    @value-changed="onAddressValueChanged"
                  >
                    <template #item="{ data }">
                      <div v-html="data.textHtml" />
                    </template>
                  </dx-util-select-box>
                </template>
              </dx-util-simple-item>
              <dx-util-simple-item data-field="shipToCountryCode" :label="{ text: 'Ship to Country'}" editor-type="dxSelectBox" :editor-options="shipToCountryCodeOptions" />
              <dx-util-simple-item data-field="serviceChargeType" editor-type="dxSelectBox" :editor-options="serviceChargeTypeOptions" />
              <dx-util-simple-item data-field="packingType" :visible="false" editor-type="dxSelectBox" :editor-options="packingTypeOptions" />
              <dx-util-simple-item data-field="workflow" :visible="false" editor-type="dxSelectBox" :editor-options="workflowOptions" />
              <dx-util-simple-item data-field="shipmentType" :visible="false" editor-type="dxSelectBox" :editor-options="shipmentTypeOptions" />
            </dx-util-form>
          </form>
        </dx-util-popup>
        <!--end::Shipment Plan Create-->
      </div>
      <!--end::Shipment Plans-->
      <div class="col-md-10">
        <div class="row">
      <!--begin::Header Info-->
      <div class="col-12">
        <header-info />
      </div>
      <!--end::Header Info-->
      <!--begin::Shipment Plan Items-->
      <div v-if="showSearchBoxHeader" class="col-md-12">
        <!--begin::Shipment Plan Items Header-->
        <dx-util-text-box
            id="searchTextBox"
            ref="searchItemQueryTextBoxRef"
            v-model="searchItemQuery"
            :value="searchItemQuery"
            name="txtBoxSearch"
            styling-mode="filled"
            placeholder="Search items to add to shipment plan by UPC, GTIN, EAN, ISBN, SKU and Title"
            :element-attr="{style: 'border-radius: 0rem;'}"
            :show-clear-button="true"
            @value-changed="onClearSearchItem"
            @enter-key="onEnterSearchItem"
            @initialized="onInitializedSearchItem"
          >
            <dx-text-box-button
              id="searchTextBoxButton"
              :options="searchItemQueryButtonOptions"
              name="searchTextBoxButton"
              location="before"
              css-class="btn-default"
            />
        </dx-util-text-box>
        </div>
        <!--end::Shipment Plan Items Header-->
        <!--begin::Shipment Plan Items Datagrid-->
        <div v-if="showShipmentPlanItemList" class="col-md-12">
          <dx-data-grid
            id="shipmentPlanItemGrid"
            ref="shipmentPlanItemGridRef"
            :height="getShipmentPlanItemGridHeight"
            :data-source="shipmentPlanItems"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="false"
            :hover-state-enabled="true"
            :customize-columns="customizeColumns"
            @content-ready="onContentReady"
          >
            <dx-paging :enabled="false" />
            <dx-column data-field="catalogItem" caption="Item" cell-template="item-cell-template" />
            <template #item-cell-template="{ data }">
              <item-info :item="data.data" :item-info-visible="false" />
            </template>
            <dx-column data-field="upc" width="125" />
            <dx-column data-field="asin" width="125" />
            <dx-column data-field="supplier" caption="Supplier" :width="90" />
            <dx-column data-field="itemCondition" caption="Condition" :width="90" />
            <dx-column data-field="quantity" width="80" alignment="left" />
            <dx-column data-field="creationTime" caption="Added At" data-type="date" :width="90" :allow-sorting="false" />
            <dx-column data-field="planName" caption="Plan" :width="160" cell-template="planSelectionTemplate" />
            <template #planSelectionTemplate="{ data }">
              <dx-util-button
                :text="data.value ? data.value : 'Select a plan'"
                :hint="data.value ? data.value : 'Select a plan'"
                :type="data.value ? 'default' : 'danger'"
                :styling-mode="data.value ? 'filled' : 'outlined'"
                :element-attr="{ class: 'btn-block' }"
                @click="onClickPlanChage(data.data)"
              />
            </template>
            <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="120" :allow-resizing="false" :fixed="true" fixed-position="left" />
            <template #itemActions="{data}">
              <div>
                <dx-util-button
                  type="warning"
                  icon="bi bi-pencil-square"
                  class="mr-half"
                  hint="Update"
                  @click="onClickEditShipmentPlanItem(data)"
                />
                <dx-util-button
                  type="success"
                  icon="bi bi-coin"
                  class="mr-half"
                  hint="Charge Details"
                  @click="onClickAddCharges(data)"
                />
                <dx-util-button
                  type="danger"
                  icon="bi bi-trash-fill"
                  hint="Delete"
                  @click="onClickDeleteShipmentPlanItem(data)"
                />
              </div>
            </template>
            <!--endregion ::DataGrid actions -->
          </dx-data-grid>
        <!--end::Shipment Plan Items Datagrid-->
      </div>
      <!--end::Shipment Plan Items-->
      <!--begin::Create Item-->
      <div v-else class="col-md-12">
        <component
          :is="currentComponent"
          v-bind="currentComponentProps"
          @emited-close="onEmitCloseShipmentPlanItem"
          @emited-cancel="onEmitCancelCreateShipmentPlanItem"
        />
      </div>
    </div>
      </div>
      <!--end::Create Item-->
      <!--begin::Plan Selector-->
      <plan-selector
        :show-plan-selector="planSelector.isVisible"
        :plan-id="parseInt(planSelector.planId)"
        :product-id="parseInt(planSelector.productId)"
        :company-id="parseInt(companyId)"
        :account-no="parseInt(accountNo)"
        :use-apply-all-plan="true"
        :use-product-plan="false"
        @close="onEmitPlanSelectorClosed"
        @planUpdated="onEmitPlanSelectorPlanUpdated"
      />
      <!--end::Plan Selector-->
      <!--begin:: Update Shipment Plan Item -->
      <dx-util-popup
        ref="popupUpdateShipmentPlanItemRef"
        :show-close-button="true"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-title="true"
        :full-screen="true"
        title="Update Shipment Plan Item"
        content-template="popup-content"
        @shown="onShownUpdateShipmentPlanItemPopup"
        @hidden="onHiddenUpdateShipmentPlanItemPopup"
      >
        <dx-util-position at="center" my="center" />
        <template #popup-content>
          <dx-util-scroll-view width="100%" height="100%">
            <div>
              <update-shipment-plan-item v-if="updateShipmentPlanItemComponentRender" :shipment-plan-item-id="shipmentPlanItemId" @emited-close-update="onEmitCloseUpdateShipmentPlanItem" />
            </div>
          </dx-util-scroll-view>
        </template>
      </dx-util-popup>
      <!--end:: Update Shipment Plan Item -->
      <!--begin:: Submit Shipment Plan-->
      <dx-util-popup
        ref="popupSubmitShipmentPlanRef"
        :show-close-button="false"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="false"
        :full-screen="false"
        width="640"
        height="480"
        title="Submit Shipment Plan"
        content-template="popup-content"
      >
        <dx-util-position at="center" my="center" />
        <dx-util-toolbar-item toolbar="bottom" location="after" template="cancelPopupButton" />
        <template #cancelPopupButton>
          <dx-util-button id="popupSubmitShipmentPlanCancelButton" text="Cancel" type="danger" @click="onCancelSubmitShipmentPlan()" />
        </template>
        <dx-util-toolbar-item toolbar="bottom" location="before" template="submitPopupButton" />
        <template #submitPopupButton>
          <dx-util-button id="popupSubmitShipmentPlanSubmitButton" text="Submit" type="success" @click="onSubmitSubmitShipmentPlan()" />
        </template>
        <template #popup-content>
          <dx-util-scroll-view width="100%" height="100%">
            <div>
              <div class="d-flex flex-column justify-content-center align-items-center w-100">
                  <i class="bi bi-exclamation-circle-fill text-white display-1" />
                  <h2 class="text-white">
                    Confirmation
                  </h2>
                  <p class="mt-2" style="font-size: 1.25rem;">
                    Are you sure you want to submit this shipment plan?
                  </p>
                  <div class="w-50 mt-2">
                    <dx-util-number-box
                      v-model="shipmentPlanSubmitId"
                      :visible="false"
                    >
                    </dx-util-number-box>
                    <dx-util-date-box
                      v-model="shipmentPlanSubmitionDelayDate"
                      type="date"
                      display-format="MM/dd/yyyy"
                      placeholder="Delivery Date"
                      :min="new Date()"
                      :element-attr="{ style: 'width: 100%;' }"
                      label="Delivery Date"
                    >
                    </dx-util-date-box>
                  </div>
              </div>
            </div>
          </dx-util-scroll-view>
        </template>
      </dx-util-popup>
      <!--end:: Submit Shipment Plan-->
      <!--begin::PrinterSeetting -->
      <dx-util-popup
        ref="refPrinterSettingsPopup"
        :drag-enabled="false"
        :close-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"
        title="Printer Settings"
        :width="getPopupWidth"
        :height="getPopupHeight"
        content-template="popup-content"
      >
        <dx-util-position at="center" my="center" />
        <template #popup-content>
        <dx-util-scroll-view height="100%" width="100%">
          <div class="container-fluid">
            <printer-settings :show-store-select="false" :store-id="storeId" />
          </div>
        </dx-util-scroll-view>
        </template>
      </dx-util-popup>
      <!--end::PrinterSeetting-->
    </div>
  </div>
</template>
<script>
import store from '@/store'
import wfsService from '@/http/requests/wfs'
import commonService from '@/http/requests/common/commonEntityService'
import SearchFilter from '@/http/models/search-filters/WfsShipmentPlansSearchFilter'
import useConfirmation from '@/libs/app/confirmation'
import moment from 'moment'
import usePrinter from '@/libs/printer/print'
import Pager from '@core/dev-extreme/utils/pager'
import { ValueToEnum, Notify } from '@robustshell/utils/index'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { DxSwitch } from 'devextreme-vue/switch'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
import isNaN from 'lodash/isNaN'
import useCurrentUser from '@/libs/app/current-user'
import serviceChargeTypeEnum, { getListServiceChargeTypeEnum } from '@/enums/service-charge-type.enum'
import PlanSelector from '@/views/apps/product/products/components/PlanSelector.vue'
import PrinterSettings from '@/views/apps/settings/PrinterSettings.vue'
import useWfsForce from './wfsForce'
import shipToCountryCodeEnum, { getShipToCountryCodeEnumList } from './consts/shipto-country-code.enum'
import CreateShipmentPlanItem from './components/CreateShipmentPlanItem.vue'
import UpdateShipmentPlanItem from './components/UpdateShipmentPlanItem.vue'
import ShipmentBatchBoxing from './components/ShipmentBatchBoxing.vue'
import ItemInfo from './components/ItemInfo.vue'
import HeaderInfo from './components/Header.vue'

export default {
  components: {
    ItemInfo,
    DxSwitch,
    DxTextBoxButton,
    CreateShipmentPlanItem,
    PrinterSettings,
    UpdateShipmentPlanItem,
    ShipmentBatchBoxing,
    PlanSelector,
    HeaderInfo,
  },
  mixins: [GridBase],
  setup() {
    const { fullHeight, handleResize } = useWfsForce()
    const {
      userFullname,
      userUsername,
      userTenantId,
      userWarehouseId,
    } = useCurrentUser()

    const { pshConfirm } = useConfirmation()
    const { printFnskuLabel } = usePrinter()

    return {
      fullHeight,
      handleResize,
      userFullname,
      userUsername,
      userTenantId,
      userWarehouseId,
      printFnskuLabel,
      pshConfirm,
    }
  },
  data() {
    return {
      planSelector: {
        isVisible: false,
        planId: null,
        productId: null,
        itemId: null,
      },
      currentComponent: 'CreateShipmentPlanItem',
      workflow: '',
      accountNo: '',
      companyId: 0,
      storeId: 0,
      selectedIndex: -1,
      shipmentPlans: [],
      selectedShipmentPlanId: null,
      showOnlyOpenShipmentPlans: true,
      searchText: '',
      searchItemQuery: '',
      shipmentPlanItems: [],
      isReadOnlyShipmentPlanMode: false,
      shipmentPlanFormDataDefault: {},
      shipmentPlanFormData: {
        selectedShippingAddress: {},
        id: null,
        batchName: '',
        fromAddress: null,
        packingType: 'individual',
        channel: 'wfs',
        workflow: 'private_flow',
        labelingPreference: 'SELLER_LABEL',
        shipmentType: 'SP',
        companyId: 0,
        storeId: 0,
        invoiceId: null,
        itemCount: 0,
        warehouseId: 0,
        batchStatus: 'open',
        notes: '',
        serviceChargeType: serviceChargeTypeEnum.PER_ITEM.value,
        shipToCountryCode: shipToCountryCodeEnum.US.value,
      },
      packingTypes: [],
      workflows: [],
      shipmentTypes: [],
      shippingAddress: [],
      selectedShippingAddress: '',
      showSearchBoxHeader: true,
      showShipmentPlanItemList: true,
      currentComponentProps: {},
      shipmentPlanItemId: null,
      shipmentPlanSubmitionDelayDate: new Date(),
      shipmentPlanSubmitId: null,
      updateShipmentPlanItemComponentRender: false,
    }
  },
  computed: {
    getShipmentPlanItemGridHeight() {
      return this.fullHeight.height - 36
    },
    btnElementAttr() {
      return {
        class: 'btn-sm',
      }
    },
    searchItemQueryButtonOptions() {
      const self = this
      return {
        text: 'SEARCH',
        type: 'default',
        icon: 'search',
        height: '36px',
        elementAttr: { style: 'padding: 1px 0 1px' },
        onClick: e => {
          self.$nextTick(() => {
            const seachTextBox = this.$refs.searchItemQueryTextBoxRef.instance
            let searchText = seachTextBox.option('value')
            searchText = searchText.trim()
            if (searchText) {
              self.onSearchItem(searchText)
            }
          })
        },
      }
    },
    // #region ::Shipment Plan Create
    createShipmentPlanPopup() {
      const popupIns = this.$refs.createShipmentPlanPopupRef.instance
      return popupIns
    },
    shipmentPlanNameOptions() {
      return {
        readOnly: this.isReadOnlyShipmentPlanMode,
      }
    },
    packingTypeOptions() {
      return {
        dataSource: this.packingTypes,
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
        visible: false,
        readOnly: this.showOnlyOpenShipmentPlans,
      }
    },
    workflowOptions() {
      return {
        dataSource: this.workflows,
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
        readOnly: this.showOnlyOpenShipmentPlans,
        visible: false,
      }
    },
    shipmentTypeOptions() {
      return {
        dataSource: this.shipmentTypes,
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
        visible: false,
        readOnly: this.showOnlyOpenShipmentPlans,
      }
    },
    serviceChargeTypeOptions() {
      return {
        dataSource: getListServiceChargeTypeEnum(),
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
        readOnly: this.showOnlyOpenShipmentPlans,
      }
    },
    shipToCountryCodeOptions() {
      return {
        dataSource: getShipToCountryCodeEnumList(),
        valueExpr: 'value',
        displayExpr: 'text',
        searchEnabled: false,
        readOnly: this.showOnlyOpenShipmentPlans,
      }
    },
    saveButtonShipmentPlanPopupOptions() {
      return {
        text: 'Create',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmitShipmentPlanFrom(e)
        },
      }
    },
    cancelButtonShipmentPlanPopupOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.createShipmentPlanPopup.hide()
          Object.assign(this.shipmentPlanFormData, this.shipmentPlanFormDataDefault)
        },
      }
    },
    // #endregion ::Shipment Plan Create
    // #region ::Shipment Plan Item Update Popup
    popupUpdateShipmentPlanItemIns() {
      const popupIns = this.$refs.popupUpdateShipmentPlanItemRef.instance
      return popupIns
    },
    popupSubmitShipmentPlanIns() {
      const popupIns = this.$refs.popupSubmitShipmentPlanRef.instance
      return popupIns
    },
    // #endregion ::Shipment Plan Item Update Popup
  },
  watch: {
    searchText() {
      this.getShipmentPlans()
    },
  },
  created() {
    // Route from the Account selection page
    // Set the workflow, accountNo, companyId, and storeId from the route params
    const {
      workflow, accountNo, companyId, storeId, shipmentPlanId,
    } = this.$route.params
    this.workflow = workflow
    this.accountNo = accountNo
    this.companyId = companyId
    this.storeId = parseInt(storeId, 10)
    this.selectedShipmentPlanId = parseInt(shipmentPlanId, 10)
    this.getShippingAddress()
  },
  mounted() {
    this.handleResize()
    Object.assign(this.shipmentPlanFormDataDefault, this.shipmentPlanFormData)
    Object.keys(this.$enums.PackingTypeEnum).map(key => this.packingTypes.push({ value: this.$enums.PackingTypeEnum[key].value, text: this.$enums.PackingTypeEnum[key].text }))
    Object.keys(this.$enums.WorkflowEnum).map(key => this.workflows.push({ value: this.$enums.WorkflowEnum[key].value, text: this.$enums.WorkflowEnum[key].text }))
    Object.keys(this.$enums.ShipmentTypeEnum).map(key => this.shipmentTypes.push({ value: this.$enums.ShipmentTypeEnum[key].value, text: this.$enums.ShipmentTypeEnum[key].text }))

    this.$nextTick(async () => {
      await this.getShipmentPlans()
    })
  },
  methods: {
    getPopupWidth() {
      return window.innerWidth / 1.5
    },
    getPopupHeight() {
      return window.innerHeight / 1.5
    },
    getEnumToValue(enumType, value) {
      return ValueToEnum(enumType, value, 'value')
    },
    async getShipmentPlans() {
      // Get the shipment plans for the account
      // Set the shipment plans
      const pager = new Pager({ pageSize: 100 })
      const filter = new SearchFilter()
      filter.accountNo = this.accountNo
      filter.companyId = this.companyId
      filter.storeId = this.storeId
      filter.channel = 'wfs'
      filter.batchStatus = this.showOnlyOpenShipmentPlans ? 'all_but_closed' : 'all'
      filter.q = this.searchText
      const result = await wfsService.getShimentPlansByQuery(filter, pager.toCreatePageable())

      if (result.length === 0) {
        return
      }

      this.setShipmentPlan(result)
      const index = this.selectedShipmentPlanId ? findIndex(this.shipmentPlans, p => p.id === this.selectedShipmentPlanId) : -1
      if (index === -1) {
        this.selectedIndex = 0
        const item = this.shipmentPlans[0]
        this.onSelectedShipmentPlan(item, 0)
      } else if (this.shipmentPlans.length > 0) {
        this.selectedIndex = index
        this.onSelectedShipmentPlan(this.shipmentPlans[index], index)
      }
    },
    async onShowOnlyOpenShipmentPlans(e) {
      // Set the showOnlyOpenShipmentPlans
      this.showOnlyOpenShipmentPlans = e.value
      await this.getShipmentPlans()
    },
    setShipmentPlan(data) {
      // Set the shipmentPlans
      this.shipmentPlans.length = 0
      data.forEach(item => {
        const batch = {
          id: item.id,
          amzBatchStatus: item.amzBatchStatus,
          avgAmzRank: item.avgAmzRank,
          batchName: item.batchName,
          batchStatus: ValueToEnum(this.$enums.BatchStatusEnum, item.batchStatus).value,
          carrier: item.carrier,
          channel: ValueToEnum(this.$enums.ChannelEnum, item.channel).value,
          companyId: item.companyId,
          fromAddress: item.fromAddress,
          batchCount: item.batchCount,
          itemCount: item.itemCount,
          itemQty: item.itemQty,
          labelingPreference: ValueToEnum(this.$enums.LabelingPreferenceEnum, item.labelingPreference).value,
          shipToCountryCode: item.shipToCountryCode,
          notes: item.notes,
          packingType: ValueToEnum(this.$enums.PackingTypeEnum, item.packingType).value,
          shipmentType: ValueToEnum(this.$enums.ShipmentTypeEnum, item.shipmentType).value,
          serviceChargeType: ValueToEnum(this.$enums.ServiceChargeTypeEnum, item.serviceChargeType).value,
          storeId: item.storeId,
          warehouseId: item.warehouseId,
          workflow: ValueToEnum(this.$enums.WorkflowEnum, item.workflow).value,
          skuCount: item.skuCount,
          totalCost: item.totalCost,
          totalSales: item.totalSales,
        }
        this.shipmentPlans.push(batch)
      })
    },
    onSelectedShipmentPlan(item, index = 0) {
      const { batchStatus } = item
      if (batchStatus === 'open') {
        this.showSearchBoxHeader = true
        this.showShipmentPlanItemList = true
      }
      if (batchStatus === 'boxing') {
        this.showSearchBoxHeader = false
        this.showShipmentPlanItemList = false
        this.selectedIndex = index
        const parsed = parseInt(item.id, 10)
        this.selectedShipmentPlanId = isNaN(parsed) ? null : parsed
        this.configureCreateShipmentPlanItemComponent('ShipmentBatchBoxing', batchStatus)
        this.configureRouter()
        return
      }
      if (batchStatus === 'closed') {
        this.showSearchBoxHeader = false
        this.showShipmentPlanItemList = false
        this.selectedIndex = index
        const parsed = parseInt(item.id, 10)
        this.selectedShipmentPlanId = isNaN(parsed) ? null : parsed
        this.configureCreateShipmentPlanItemComponent('ShipmentBatchBoxing', batchStatus)
        this.configureRouter()
        return
      }
      this.selectedIndex = index
      this.selectedShipmentPlanId = item.id
      const filters = {
        companyId: 0,
        accountNo: '',
        q: '',
        storeId: 0,
        warehouseId: 0,
        beginDate: null,
        endDate: null,
        tenantType: 'SERVING',
        batchId: this.selectedShipmentPlanId,
        status: 'ALL',
        batchStatus: 'all',
      }
      wfsService.getShipmentPlanItemDataByQuery(filters).then(result => {
        this.shipmentPlanItems.length = 0
        if (result.length > 0) {
          this.shipmentPlanItems = [...result]
        }
      }).then(() => {
        this.configureRouter()
      })
    },
    configureRouter() {
      const { shipmentPlanId } = this.$route.params
      if (!shipmentPlanId || parseInt(shipmentPlanId, 10) !== this.selectedShipmentPlanId) {
        this.$router
          .push({
            name: 'wfs-shipments-workflow',
            params: {
              workflow: 'shipment-plan',
              accountNo: this.accountNo,
              companyId: this.companyId,
              storeId: this.storeId,
              shipmentPlanId: this.selectedShipmentPlanId,
            },
          })
      }
    },
    onGetShipmentPlanItemsById(id) {
      const parsed = parseInt(id, 10)
      this.selectedShipmentPlanId = isNaN(parsed) ? null : parsed
      if (isNaN(parsed)) { return }
      const filters = {
        companyId: 0,
        accountNo: '',
        q: '',
        storeId: 0,
        warehouseId: 0,
        beginDate: null,
        endDate: null,
        tenantType: 'SERVING',
        batchId: parsed,
        status: 'ALL',
        batchStatus: 'all',
      }
      wfsService.getShipmentPlanItemDataByQuery(filters).then(result => {
        if (result.length > 0) {
          this.shipmentPlanItems.length = 0
          this.shipmentPlanItems = [...result]
        }
      })
    },
    onFilterBatch(e) {
      if (e.keyCode === 13) {
        this.getShipmentPlans()
      }
    },
    // #region ::Shipment Plan Create
    getShippingAddress() {
      this.shippingAddress.splice(0, this.shippingAddress.length)
      commonService.fetchAddressesByTenantAndCompany(this.companyId).then(result => {
        const { data } = result
        data.forEach(item => {
          let line = item.line1
          line += item.line2 ? ` ${item.line2}` : ''
          line += item.line3 ? ` ${item.line3}` : ''
          const addressStringHtml = `${item.name}<br />${line} Suite # ${this.accountNo}<br />${item.city}, ${item.state} ${item.zipcode}`
          const addressString = `${item.name} ${line} Suite # ${this.accountNo} ${item.city}, ${item.state} ${item.zipcode}`
          this.shippingAddress.push({
            id: item.id,
            textHtml: addressStringHtml,
            text: addressString,
          })
        })
      })
    },
    onAddressValueChanged(e) {
      const selected = find(this.shippingAddress, p => p.id === e.value)
      this.selectedShippingAddress = selected.textHtml
    },
    onShipmentPlanFormFieldDataChanged(e) {
      if (e.dataField === 'packingType') {
        if (e.value) {
          const packType = e.value
          if (packType === this.$enums.PackingTypeEnum.case_packed.key) {
            this.shipmentPlanFormData.batchName = this.createShipmentPlanName(' CP')
            e.component.repaint()
          } else {
            this.shipmentPlanFormData.batchName = this.createShipmentPlanName()
            e.component.repaint()
          }
        }
      }
    },
    onClickCreateShipmentPlan() {
      this.showOnlyOpenShipmentPlans = false
      const address = this.shippingAddress[0]
      this.shipmentPlanFormData.fromAddress = address.id
      this.selectedShippingAddress = address.textHtml
      this.shipmentPlanFormData.batchName = this.createShipmentPlanName()
      this.createShipmentPlanPopup.show()
    },
    onClickRefreshShipmentPlans() {
      this.getShipmentPlans()
    },
    onClickEditShipmentPlan(item, index) {
      this.isReadOnlyShipmentPlanMode = true
      this.selectedIndex = index
      this.shipmentPlanFormData = { ...item }
      const selectedAddress = find(this.shippingAddress, p => p.id === this.shipmentPlanFormData.fromAddress)
      if (selectedAddress) {
        this.selectedShippingAddress = selectedAddress.textHtml
      }
      this.createShipmentPlanPopup.show()
    },
    createShipmentPlanName(casePack = '') {
      const now = new Date()
      const formatedDate = moment(now).format('YYYY-MM-DD HH:mm:ss')
      const userName = this.userFullname.split(' ')[0].charAt(0) + this.userFullname.split(' ')[1].charAt(0)
      const planName = `${this.userTenantId}-${userName}${casePack} ${formatedDate}`
      return planName
    },
    async handleSubmitShipmentPlanFrom(e) {
      this.createShipmentPlanPopup.hide()
      const form = this.$refs.shipmentPlanFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        const data = { ...this.shipmentPlanFormData }
        data.companyId = this.companyId
        data.storeId = this.storeId
        data.warehouseId = this.userWarehouseId
        if (data.id === null) {
          const result = await wfsService.createShipmentPlan(data)
          Object.assign(this.shipmentPlanFormData, this.shipmentPlanFormDataDefault)
          if (result) {
            this.selectedShipmentPlanId = result.id
            this.getShipmentPlans()
          }
        } else {
          await wfsService.updateShipmentPlan(data)
          this.selectedShipmentPlanId = data.id
          this.getShipmentPlans()
        }
      }
    },
    // #endregion ::Shipment Plan Create,
    // #region ::Shipment Plan Delete
    async onClickDeleteShipmentPlan(id) {
      if (id) {
        const confirm = await this.pshConfirm(
          'Delete Shipment Plan',
          'Are you sure you want to delete this shipment plan? This action cannot be undone.',
        )
        if (confirm) {
          await wfsService.deleteShipmentPlan(id)
          this.$nextTick(async () => {
            await this.getShipmentPlans()
          })
        }
      } else {
        Notify.warning('Please select a plan.')
      }
    },
    // #endregion ::Shipment Plan Delete
    async onClickSubmitShipmentPlan(id) {
      this.shipmentPlanSubmitId = id
      this.popupSubmitShipmentPlanIns.show()
    },
    async onSubmitSubmitShipmentPlan() {
      const date = new Date(this.shipmentPlanSubmitionDelayDate)
      const deliveryDate = date.toISOString()
      await wfsService.submitShipmentPlan(this.shipmentPlanSubmitId, deliveryDate)
      await this.getShipmentPlans()
      Notify.success('Shipment Plan submitted successfully.')
      this.popupSubmitShipmentPlanIns.hide()
    },
    onCancelSubmitShipmentPlan() {
      this.shipmentPlanSubmitId = null
      this.popupSubmitShipmentPlanIns.hide()
    },
    async onClickCloseShipmentPlan(id) {
      if (id) {
        const confirm = await this.pshConfirm(
          'Close Shipment Plan',
          'Are you sure you want to close this shipment plan?',
        )
        if (confirm) {
          await wfsService.closeShipmentPlan(id)
          await this.getShipmentPlans()
        }
      } else {
        Notify.warning('Please select a plan.')
      }
    },
    // #region ::Search Filter
    onInitializedSearchItem(e) {
      setTimeout(() => {
        e.component.focus()
      }, 0)
    },
    onClearSearchItem(e) {
      if (e.value === '' || e.value === null || typeof e.value === 'undefined') {
        this.searchItemQuery = ''
        this.showShipmentPlanItemList = true
      } else {
        this.searchItemQuery = e.value
      }
    },
    onEnterSearchItem(e) {
      if (e.event.keyCode === 13) {
        if (e.event.target.value) {
          const searchItemQuery = e.event.target.value.trim()
          e.component.option('value', searchItemQuery)
          this.onSearchItem(searchItemQuery)
        } else {
          this.showShipmentPlanItemList = true
        }
      }
    },
    async onSearchItem(query) {
      if (query) {
        const filter = {
          q: query,
          fieldEnum: 'SKU',
          storeId: this.storeId,
          batchId: this.selectedShipmentPlanId,
        }
        try {
          const data = await wfsService.searchItem(filter)
          const itemExist = find(data, p => p.itemExists === true)
          if (itemExist) {
            this.shipmentPlanItems.length = 0
            this.shipmentPlanItems = itemExist.existingItems
            return
          }
          this.showShipmentPlanItemList = false
          this.configureCreateShipmentPlanItemComponent('CreateShipmentPlanItem')
        } catch (err) {
          const messeage = err.message || err
          Notify.error(messeage)
        }
      } else {
        this.showShipmentPlanItemList = true
        this.searchItemQuery = ''
      }
    },
    // #endregion ::Search Filter
    // #region ::Dynamic Component
    configureCreateShipmentPlanItemComponent(componentName, status = '') {
      this.currentComponent = componentName

      let storeId = parseInt(this.storeId, 10)
      storeId = isNaN(storeId) ? 0 : storeId

      let companyId = parseInt(this.companyId, 10)
      companyId = isNaN(companyId) ? 0 : companyId

      const porps = {
        storeId: storeId,
        companyId: companyId,
        searchText: this.searchItemQuery,
        shipmentPlanId: this.selectedShipmentPlanId,
        planStatus: status,
      }
      this.currentComponentProps = porps
    },
    onEmitCancelCreateShipmentPlanItem() {
      this.showShipmentPlanItemList = true
      this.searchItemQuery = ''
    },
    async onEmitCloseShipmentPlanItem(item) {
      const settings = store.getters['wfsForce/getPrinterSettings']
      if (settings && settings.manageLabelPrintering && !settings.manageLabelPrintering.isAddedFBABatch) return

      const payload = {
        barcode: item.upc,
        title: item.title,
        expirationDate: item.expirationDate,
        copies: item.quantity,
      }
      await this.printFnskuLabel(payload)

      this.showShipmentPlanItemList = true
      this.searchItemQuery = ''
    },
    // #endregion ::Dynamic Component
    // #region ::Shipment Plan Item Actions
    async onClickEditShipmentPlanItem(item) {
      const { data } = item
      this.shipmentPlanItemId = data.id
      this.popupUpdateShipmentPlanItemIns.show()
    },
    onClickCancePopupUpdateShipmentPlanItem() {
      this.popupUpdateShipmentPlanItemIns.hide()
    },
    onEmitCloseUpdateShipmentPlanItem() {
      this.popupUpdateShipmentPlanItemIns.hide()
    },
    onShownUpdateShipmentPlanItemPopup() {
      this.updateShipmentPlanItemComponentRender = true
    },
    onHiddenUpdateShipmentPlanItemPopup() {
      this.updateShipmentPlanItemComponentRender = false
      this.onGetShipmentPlanItemsById(this.selectedShipmentPlanId)
    },
    onClickDeleteShipmentPlanItem(e) {
      const { data } = e
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this item from this Shipment Plan? Once confirmed, this item will be removed from the Shipment Plan and the reserved items will be return to the inventory.', {
          title: 'Confirm Delete',
          size: 'lg',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'warning',
          headerClass: 'px-1 py-half border-bottom-0',
          bodyClass: 'px-1 py-half',
          footerClass: 'd-flex flex-row px-1 py-half border-top-1',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            wfsService.deleteShipmentPlanItem(data.id)
              .then(() => {
                this.onGetShipmentPlanItemsById(this.selectedShipmentPlanId)
              })
          }
        })
        .catch(err => {
          Notify.error(err)
        })
    },
    onClickAddCharges(data) {
      Notify.info(`Not implemented yet.${data.id}`)
    },
    onClickPlanChage(data) {
      this.planSelector.isVisible = true
      this.planSelector.productId = data.productId
      this.planSelector.itemId = data.id
      this.planSelector.planId = data.planId
    },
    async onEmitPlanSelectorPlanUpdated(payload) {
      const { planId, applyAll } = payload
      const id = this.planSelector.itemId
      await wfsService.updateShipmentPlanItemPlanId(id, planId, applyAll)
      this.onGetShipmentPlanItemsById(this.selectedShipmentPlanId)
      this.onEmitPlanSelectorClosed()
    },
    onEmitPlanSelectorClosed() {
      this.planSelector.isVisible = false
      this.planSelector.productId = null
      this.planSelector.itemId = null
      this.planSelector.planId = null
    },
    // #endregion ::Shipment Plan Item Actions

    onShowPrinterSettings() {
      const popup = this.$refs.refPrinterSettingsPopup.instance
      popup.show()
    },
  },
}
</script>
<style lang="scss" scoped>
$base-light: #dfe6e9;
$base-light-boder: #b2bec3;
$base-light-reverse: #293046;

$base-gray: #9a9fad;
$base-gray-boder: #b2bec3;
$base-gray-reverse: #293046;

$base-purple: #3c4256;
$base-purple-boder: #b2bec3;
$base-purple-reverse: #dfe6e9;
.card-batch {
  border-radius: 0px !important;
  font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans, Helvetica Neue, Arial, sans-serif;
  text-align: justify;
  text-justify: inter-word;
  .card-batch-title {
    height: 36px;
    h6 {
      font-size: 1.375rem;
      font-weight: 400;
    }
    .dx-button-normal {
      font-weight: 500;
      height: 36px;
      border: none;
      border-radius: 0px;
    }
  }
  .dx-textbox {
    border: 0px transparent;
  }
  .batch-list {
    cursor: pointer;
    display: block;
    .list-item-title {
      font-size: 1rem;
    }
    .list-item-info {
      font-size: 0.879rem;
    }
  }
  &.batch-light {
    background-color: $base-light;
    .card-batch-title {
      color: $base-light-reverse;
      border: none;
      h6 {
        color: $base-light-reverse;
      }
      .dx-button-normal {
        background-color: $base-light-boder;
        color: $base-light-reverse;
        &:hover {
          background-color: rgba($base-light-reverse, 0.8);
          color: rgba($base-light, 0.8);
        }
      }
    }
    .batch-list {
      border-bottom: 1px solid $base-light-boder;
      &:nth-of-type(1) {
        border-top: 1px solid $base-light-boder;
      }
      .icon-border-warning {
        border-color: #ffd6a5 !important;
        background-color: #ffd6a5 !important;
        color: #1d3557 !important;
      }
      .icon-border-success {
        border-color: #caffbf !important;
        background-color: #caffbf !important;
        color: #1d3557 !important;
      }
      .list-item-title {
        color: $base-light-reverse;
      }
      .list-item-info {
        color: $base-light-reverse;
      }
      &.selected-batch {
        background-color: $base-light-reverse;
        .list-item-title {
          color: $base-light;
        }
        .list-item-info {
          color: $base-light;
        }
      }
    }
  }
  &.batch-light-semi {
    background-color: $base-gray;
    .card-batch-title {
      color: $base-gray-reverse;
      border-bottom: 1px solid $base-gray-boder;
      border-right: 1px solid $base-gray-boder;
      h6 {
        color: $base-gray-reverse;
      }
      .dx-button-normal {
        background-color: $base-gray-boder;
        color: $base-gray-reverse;
        &:hover {
          background-color: rgba($base-gray-reverse, 0.8);
          color: rgba($base-gray, 0.8);
        }
      }
    }
    .batch-list {
      border-bottom: 1px solid $base-gray-boder;
      &:nth-of-type(1) {
        border-top: 1px solid $base-gray-boder;
      }
      .icon-border-warning {
        border-color: #f39c12 !important;
      }
      .list-item-title {
        color: $base-gray-reverse;
      }
      .list-item-info {
        color: $base-gray-reverse;
      }
      &.selected-batch {
        background-color: $base-gray-reverse;
        .list-item-title {
          color: $base-gray;
        }
        .list-item-info {
          color: $base-gray;
        }
      }
    }
  }
  &.batch-purple {
    background-color: $base-purple;
    .card-batch-title {
      color: $base-purple-reverse;
      border-bottom: 1px solid $base-purple-boder;
      border-right: 1px solid $base-purple-boder;
      h6 {
        color: $base-purple-reverse;
      }
      .dx-button-normal {
        background-color: $base-purple-boder;
        color: $base-purple-reverse;
        &:hover {
          background-color: rgba($base-purple-reverse, 0.8);
          color: rgba($base-purple, 0.8);
        }
      }
    }
    .batch-list {
      border-bottom: 1px solid $base-purple-boder;
      &:nth-of-type(1) {
        border-top: 1px solid $base-purple-boder;
      }
      .icon-border-warning {
        border-color: #f39c12 !important;
      }
      .list-item-title {
        color: $base-purple-reverse;
      }
      .list-item-info {
        color: $base-purple-reverse;
      }
      &.selected-batch {
        background-color: $base-light-reverse;
        .list-item-title {
          color: $base-light;
        }
        .list-item-info {
          color: $base-light;
        }
      }
    }
  }
}
</style>
