<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <!--Begin:: Inventory Item Info-->
        <div class="col-12">
          <div class="card mb-0 border">
            <div class="card-header py-1 px-1">
              <dx-util-text-box v-model="inventoryItemSearchText" width="100%" mode="search" @key-down="getInventoryDataSearchByFilter" />
            </div>
            <div class="card-body">
            <div class="row">
            <!--Begin:: Header -->
            <div class="col-12 border-bottom new-item-header">
              <div class="row">
                <div class="col-6 py-1 text-yellow">
                  Item
                </div>
                <div class="col-2 py-1 text-yellow">
                  Quantities
                </div>
                <div class="col-1 py-1 text-yellow">
                  Receive
                </div>
                <div class="col-1 py-1 text-yellow">
                  Condition
                </div>
                <div class="col-2 py-1 text-yellow">
                  Source
                </div>
              </div>
            </div>
            <!--End:: Header -->
            <div v-for="(item, index) in existInventoryItems" :key="`${item.id}`" class="col-12 new-item-detail" :class="index < inventoryItems.length - 1 ? 'border-bottom': ''">
              <div class="row">
                <div class="col-6">
                  <item-info
                    :item="item"
                    :is-printable="false"
                    :is-truncated="true"
                    :item-info-visible="true"
                  />
                </div>
                <div class="col-2 d-flex flex-column">
                  <div class="d-flex flex-row justify-content-start align-content-center">
                    <div v-b-tooltip.hover="{ variant: 'info' }" title="Receive all avaible quantity" class="flex-fill px-0">
                      Available
                      <span class="count-item count-success cursor-pointer" @click="setAvailableQuantity(item)">
                        {{ item.quantity - item.reservedCount }}
                        <i class="bi-caret-right-fill" />
                      </span>
                    </div>
                    <div v-if="item.shippedCount > 0" class="flex-fill px-0">
                      Shipped
                      <span class="count-item" :class="item.shippedCount > 0 ? 'count-warning' : ''">
                        {{ item.shippedCount }}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-start">
                    <div v-if="item.reservedCount > 0" class="flex-fill px-0">
                      Reserved
                      <span class="count-item" :class="item.reservedCount > 0 ? 'count-warning' : ''">
                        {{ item.reservedCount }}
                      </span>
                    </div>
                    <div v-if="item.countInBundle > 0" class="flex-fill px-0">
                      In Bundle
                      <span class="count-item" :class="item.countInBundle > 0 ? 'count-warning' : ''">
                        {{ item.countInBundle }}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-start">
                    <div v-if="item.sortedCount > 0" class="flex-fill px-0">
                      Sorted
                      <span class="count-item" :class="item.sortedCount > 0 ? 'count-warning' : ''">
                        {{ item.sortedCount }}
                      </span>
                    </div>
                    <div v-if="item.countPerBundle > 0" class="flex-fill px-0">
                      Per Bundle
                      <span class="count-item">
                        <span v-if="item.countPerBundle > 0" class="badge badge-danger rounded">
                          {{ item.countPerBundle }}
                        </span>
                        <span v-else> {{ item.countPerBundle }}</span>
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-start">
                    <div v-if="item.damagedCount > 0" class="flex-fill px-0">
                      Damaged
                      <span class="count-item" :class="item.damagedCount > 0 ? 'count-danger' : ''">
                        {{ item.damagedCount }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-1 align-self-center">
                  <dx-util-number-box
                    v-model="item.inUsedCount"
                    width="100%"
                    :read-only="isReadOnlyQuantity"
                    :min="0"
                    :max="item.quantity"
                    :show-spin-buttons="false"
                    :input-attr="{ style: 'padding-top:0px; padding-bottom:0px ' }"
                    @value-changed="onChangeAddQuantity($event, item)"
                  />
                </div>
                <div class="col-1 align-self-center text-center">
                  <span v-if="item.itemCondition" class="badge badge-pill badge-info">
                    {{ item.itemCondition }}
                  </span>
                  <span v-else class="badge badge-pill badge-warning">
                    -
                  </span>
                </div>
                <div class="col-2 d-flex flex-column align-self-center">
                  <span class="badge mb-half" :class="`badge-${getEnumToValue($enums.ItemSourceEnum, item.itemSource).variant}`">
                    {{ getEnumToValue($enums.ItemSourceEnum, item.itemSource).text }}
                  </span>
                </div>
              </div>
            </div>
            <div v-for="(item, index) in inventoryItems" :key="`${item.id}`" class="col-12 new-item-detail" :class="index < inventoryItems.length - 1 ? 'border-bottom': ''">
              <div class="row">
                <div class="col-6">
                  <item-info
                    :item="item"
                    :is-printable="false"
                    :is-truncated="true"
                    :item-info-visible="true"
                  />
                </div>
                <div class="col-2 d-flex flex-column">
                  <div class="d-flex flex-row justify-content-start align-content-center">
                    <div v-b-tooltip.hover="{ variant: 'info' }" title="Receive all avaible quantity" class="flex-fill px-0">
                      Available
                      <span class="count-item count-success cursor-pointer" @click="setAvailableQuantity(item)">
                        {{ item.quantity }}
                        <i class="bi-caret-right-fill" />
                      </span>
                    </div>
                    <div v-if="item.shippedCount > 0" class="flex-fill px-0">
                      Shipped
                      <span class="count-item" :class="item.shippedCount > 0 ? 'count-warning' : ''">
                        {{ item.shippedCount }}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-start">
                    <div v-if="item.reservedCount > 0" class="flex-fill px-0">
                      Reserved
                      <span class="count-item" :class="item.reservedCount > 0 ? 'count-warning' : ''">
                        {{ item.reservedCount }}
                      </span>
                    </div>
                    <div v-if="item.countInBundle > 0" class="flex-fill px-0">
                      In Bundle
                      <span class="count-item" :class="item.countInBundle > 0 ? 'count-warning' : ''">
                        {{ item.countInBundle }}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-start">
                    <div v-if="item.sortedCount > 0" class="flex-fill px-0">
                      Sorted
                      <span class="count-item" :class="item.sortedCount > 0 ? 'count-warning' : ''">
                        {{ item.sortedCount }}
                      </span>
                    </div>
                    <div v-if="item.countPerBundle > 0" class="flex-fill px-0">
                      Per Bundle
                      <span class="count-item">
                        <span v-if="item.countPerBundle > 0" class="badge badge-danger rounded">
                          {{ item.countPerBundle }}
                        </span>
                        <span v-else> {{ item.countPerBundle }}</span>
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-start">
                    <div v-if="item.damagedCount > 0" class="flex-fill px-0">
                      Damaged
                      <span class="count-item" :class="item.damagedCount > 0 ? 'count-danger' : ''">
                        {{ item.damagedCount }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-1 align-self-center">
                  <dx-util-number-box
                    v-model="item.inUsedCount"
                    width="100%"
                    :read-only="isReadOnlyQuantity"
                    :min="0"
                    :max="(item.quantity + item.inUsedCoun)"
                    :show-spin-buttons="false"
                    :input-attr="{ style: 'padding-top:0px; padding-bottom:0px ' }"
                    @value-changed="onChangeAddQuantity($event, item)"
                  />
                </div>
                <div class="col-1 align-self-center text-center">
                  <span v-if="item.itemCondition" class="badge badge-pill badge-info">
                    {{ item.itemCondition }}
                  </span>
                  <span v-else class="badge badge-pill badge-warning">
                    -
                  </span>
                </div>
                <div class="col-2 d-flex flex-column align-self-center">
                  <span class="badge mb-half" :class="`badge-${getEnumToValue($enums.ItemSourceEnum, item.itemSource).variant}`">
                    {{ getEnumToValue($enums.ItemSourceEnum, item.itemSource).text }}
                  </span>
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>

        </div>
        <!--End:: Inventory Item Info-->
        <!--Begin:: Actions-->
        <div class="col-12">
          <div class="card mb-0 border">
            <div class="card-body">
              <div class="row">
                <div class="col-12 d-flex flex-row justify-content-end">
                  <dx-util-button
                    text="Cancel"
                    type="danger"
                    class="mr-1"
                    @click="onClickCancel"
                  />
                  <dx-util-button
                    text="Update"
                    type="success"
                    @click="onClickUpdateItemQuantity"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--End:: Actions-->
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable vue/no-unused-components */

import {
  ref, computed, onBeforeMount, onMounted,
} from '@vue/composition-api'
import useCurrentUser from '@/libs/app/current-user'
import wfsService from '@/http/requests/wfs'
import inventoryService from '@/http/requests/inventory/inventoryService'
import shipService from '@/http/requests/ship/shipService'
import { formatedDateStr, formatedIsoDateStr } from '@/@core/utils/dateHelper.js'
import SearchFilter from '@/http/models/search-filters/ReceivePageSearchFilter'
import isEmpty from 'lodash/isEmpty'
import { ValueToEnum, Notify } from '@robustshell/utils/index'
import Pager from '@core/dev-extreme/utils/pager'
import { DxHtmlEditor, DxToolbar } from 'devextreme-vue/html-editor'
import { v4 as uuidv4 } from 'uuid'
import { watch } from '@chenfengyuan/vue-barcode'
import ItemInfo from './ItemInfo.vue'
import useWfsShipmentItem from '../useWfsShipmentItem.js'
import fieldEnum, { getFieldEnum } from '../consts/field-type.enum.js'

// const defaultItemImageUrl = require('@/assets/images/undraw/no_images.svg')
// TODO: shipment batch item product id

export default {
  components: {
    ItemInfo,
    DxHtmlEditor,
    DxToolbar,
  },
  props: {
    shipmentPlanItemId: {
      type: Number,
      required: false,
      default: () => null,
    },
    barcode: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  emits: ['emited-close'],
  setup(props, ctx) {
    const { shipmentPlanItemId, barcode } = props

    const companyId = ref(null)
    const storeId = ref(null)
    const inventoryItemSearchText = ref(barcode || '')

    const { isServingTenant, userWarehouseId } = useCurrentUser()
    const {
      shipmentPlanItem,
      addShipmentItemBasicData,
    } = useWfsShipmentItem()

    const isReadOnlyQuantity = ref(false)
    const renderInventoryItem = ref(false)
    const existInventoryItems = ref([])
    const existInventoryItemsIds = ref([])
    const inventoryItems = ref([])

    // #region Existing Inventory Items
    async function getInventoryByIds() {
      const ids = existInventoryItemsIds.value.map(item => item.id)
      const result = await inventoryService.getByIds(ids)
      if (result && result.length > 0) {
        existInventoryItems.value = result.map(item => {
          const shipmentDate = formatedIsoDateStr(item.shipmentDate)
          const exprDate = formatedDateStr(item.expirationDate)
          const catalogItem = item.catalogItem ? item.catalogItem : null
          const itemReserved = existInventoryItemsIds.value.find(x => x.id === item.id)
          const usedCount = itemReserved ? itemReserved.qty : 0
          return {
            id: item.id,
            amzRank: item.amzRank,
            batchParentId: null,
            itemSource: 'inventory',
            companyId: item.companyId,
            storeId: item.storeId,
            storeName: item.storeName,
            warehouseId: item.warehouseId,
            productId: item.productId,
            mskuFnskuPairId: item.mskuFnskuPairId,
            title: item.title,
            upc: item.upc,
            asin: item.asin,
            msku: item.msku,
            fnsku: item.fnsku,
            packType: null,
            planId: item.planId,
            palletId: item.palletId,
            priceList: null,
            quantity: item.quantity,
            quantityPerCase: 0,
            reservedCount: usedCount,
            receivedCount: null,
            sortedCount: null,
            damagedCount: null,
            shippedCount: item.sentCount,
            countInBundle: null,
            countPerBundle: null,
            itemAction: item.itemAction,
            itemCost: item.itemCost,
            salePrice: item.salePrice,
            itemCondition: item.itemCondition,
            supplier: item.supplier,
            batchId: item.batchId,
            batchName: item.batchName,
            tracking: item.tracking,
            orderNumber: item.orderNumber,
            notes: item.notes,
            catalogItem: item.catalogItem,
            productType: item.productType,
            taxCode: null,
            expirationDate: exprDate,
            listingItem: '',
            inUsedCount: usedCount,
            shipmentDate: shipmentDate,
            smallImage: item.smallImage,
            largeImage: item.largeImage,
            batchUploadedAt: item.batchUploadedAt,
          }
        })
      }
    }
    // #endregion
    // #region Inventory Item Search
    async function getInventoryData() {
      inventoryItems.value.length = 0
      const filter = new SearchFilter()
      const pager = new Pager({ pageSize: 20 })
      filter.companyId = companyId.value
      filter.storeId = storeId.value
      filter.q = inventoryItemSearchText.value
      filter.itemAction = 'WFS'
      if (filter.upc && filter.q.startsWith('0')) {
        filter.q = filter.q.substring(1)
      }
      const result = await shipService.fetchFbaShipmentBatchParentItemByQuery(filter, pager.toCreatePageable())

      if (!isEmpty(result.data.content)) {
        const ids = existInventoryItemsIds.value.map(item => item.id)
        result.data.content.forEach(item => {
          if (ids.includes(item.id)) {
            return
          }
          const shipmentDate = formatedIsoDateStr(item.shipmentDate)
          const exprDate = formatedDateStr(item.expirationDate)
          const catalogItem = item.catalogItem ? item.catalogItem : null
          const itemData = {
            uuid: uuidv4(),
            id: item.id,
            amzRank: item.amzRank,
            batchParentId: item.batchParentId,
            itemSource: item.itemSource,
            companyId: item.companyId,
            storeId: item.storeId,
            storeName: item.storeName,
            warehouseId: item.warehouseId,
            productId: item.productId,
            mskuFnskuPairId: item.mskuFnskuPairId,
            title: item.title,
            upc: item.upc,
            asin: item.asin,
            msku: item.msku,
            fnsku: item.fnsku,
            packType: item.packType,
            planId: item.planId,
            palletId: item.palletId,
            priceList: item.priceList,
            quantity: item.quantity,
            quantityPerCase: item.quantityPerCase,
            reservedCount: item.reservedCount,
            receivedCount: item.receivedCount,
            sortedCount: item.sortedCount,
            damagedCount: item.damagedCount,
            shippedCount: item.shippedCount,
            countInBundle: item.countInBundle,
            countPerBundle: item.countPerBundle,
            itemAction: item.itemAction,
            itemCost: item.itemCost,
            salePrice: item.salePrice,
            itemCondition: item.itemCondition,
            supplier: item.supplier,
            batchId: item.batchId,
            batchName: item.batchName,
            tracking: item.tracking,
            orderNumber: item.orderNumber,
            notes: item.notes,
            catalogItem: catalogItem,
            productType: item.productType,
            taxCode: item.taxCode,
            expirationDate: exprDate,
            listingItem: '',
            inUsedCount: 0,
            shipmentDate: shipmentDate,
            smallImage: item.smallImage,
            largeImage: item.largeImage,
            batchUploadedAt: item.batchUploadedAt,
          }
          inventoryItems.value.push(itemData)
        })
      }
      renderInventoryItem.value = true
    }
    async function getInventoryDataSearchByFilter(e) {
      if (e.event.keyCode !== 13) return
      await getInventoryData()
      e.component.option('value', '')
    }
    // #endregion
    function setShipmentItemData(itemData) {
      shipmentPlanItem.value.id = itemData.id
      shipmentPlanItem.value.companyId = itemData.companyId
      shipmentPlanItem.value.warehouseId = userWarehouseId
      shipmentPlanItem.value.storeId = itemData.storeId
      shipmentPlanItem.value.shipmentPlanId = itemData.batchParentId
      shipmentPlanItem.value.quantity = itemData.quantity
      shipmentPlanItem.value.vendorPackQty = 0
      shipmentPlanItem.value.innerPackQty = 0
      shipmentPlanItem.value.productId = itemData.productId
      shipmentPlanItem.value.mskuFnskuPairId = itemData.mskuFnskuPairId
      shipmentPlanItem.value.cost = itemData.itemCost
      shipmentPlanItem.value.salePrice = itemData.salePrice
      shipmentPlanItem.value.supplier = itemData.supplier
      shipmentPlanItem.value.title = itemData.title
      shipmentPlanItem.value.itemDesc = ''
      shipmentPlanItem.value.barcode = itemData.upc
      shipmentPlanItem.value.barcodeType = getFieldEnum(itemData.fnsku).value
      shipmentPlanItem.value.itemId = itemData.asin
      shipmentPlanItem.value.msku = itemData.msku
      shipmentPlanItem.value.mskuAuto = false
      shipmentPlanItem.value.itemCondition = itemData.itemCondition
      shipmentPlanItem.value.planId = itemData.planId
      shipmentPlanItem.value.expirationDate = itemData.expirationDate
      shipmentPlanItem.value.notes = itemData.notes
      shipmentPlanItem.value.productGroup = ''
      shipmentPlanItem.value.packType = itemData.packType
      shipmentPlanItem.value.createType = null
      shipmentPlanItem.value.mpItem = { ...itemData.mpItem }

      shipmentPlanItem.value.itemDesc = itemData.mpItem && itemData.mpItem.Item ? itemData.mpItem.Item.itemDesc : ''

      // id, qty
      itemData.inventoryMappedItems.items.forEach(item => {
        const shipmentItemBasicData = {
          id: item.id,
          productId: itemData.productId,
          inventoryId: item.id,
          mskuFnskuPairId: itemData.mskuFnskuPairId,
          itemSource: 'inventory',
          quantity: item.qty,
        }
        addShipmentItemBasicData(shipmentItemBasicData)
      })
    }
    async function getShipmentItemById() {
      try {
        if (!shipmentPlanItemId) {
          return
        }
        const result = await wfsService.getShipmentPlanItemById(shipmentPlanItemId)
        if (result) {
          setShipmentItemData(result)
          companyId.value = result.companyId
          storeId.value = result.storeId
          existInventoryItemsIds.value = result.inventoryMappedItems.items
          await getInventoryByIds()
          await getInventoryData()
        }
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      }
    }

    onBeforeMount(async () => {
      await getShipmentItemById()
    })

    function getEnumToValue(enumType, value) {
      return ValueToEnum(enumType, value, 'value')
    }

    // #region Inventory Item Actions

    function setAvailableQuantity(item) {
      item.inUsedCount = item.quantity
      Notify.success('Set available quantity')
    }
    function onChangeAddQuantity(e, item) {
      item.inUsedCount = e.value
      const shipmentItemBasicData = {
        uuid: item.uuid,
        id: item.id,
        productId: item.productId,
        inventoryId: item.id,
        mskuFnskuPairId: item.mskuFnskuPairId,
        itemSource: item.itemSource ? item.itemSource : 'inventory',
        quantity: e.value,
      }
      shipmentPlanItem.value.quantity += e.value
      addShipmentItemBasicData(shipmentItemBasicData)
    }
    async function onClickUpdateItemQuantity() {
      if (shipmentPlanItem.value.quantity === 0) {
        Notify.error('Please add quantity')
        return
      }
      const data = {
        id: shipmentPlanItem.value.id,
        quantity: shipmentPlanItem.value.quantity,
        items: shipmentPlanItem.value.items,
      }
      try {
        await wfsService.updateShipmentPlanItemQuantity(data)
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      } finally {
        ctx.emit('emited-close-update', null)
      }
    }
    // #endregion

    // #region Shipment Item Create

    function onClickCancel(e) {
      ctx.emit('emited-close-update', null)
    }
    // #endregion
    return {
      isReadOnlyQuantity,
      inventoryItems,
      existInventoryItems,
      inventoryItemSearchText,
      renderInventoryItem,
      getInventoryDataSearchByFilter,
      shipmentPlanItem,
      isServingTenant,
      getEnumToValue,
      setAvailableQuantity,
      onChangeAddQuantity,
      onClickUpdateItemQuantity,
      onClickCancel,
    }
  },
}
</script>
<style lang="scss">
  .new-item-header {
    font-display: block;
    font-weight: 500;
    font-size: 1rem;
  }

  .new-item-detail {
    .count-item {
      font-display: block;
      font-weight: 500;

      &.count-success {
        color: rgba(0, 184, 148, 1);
      }

      &.count-warning {
        color: rgba(253, 203, 110, 1);
      }

      &.count-danger {
        color: rgba(214, 48, 49, 1);
      }

      &.count-info {
        color: rgba(0, 206, 201, 1);
      }

      &::before {
        content: ': ';
        color: rgba(223, 230, 233, 1);
      }
    }
}
</style>
