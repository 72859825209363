import find from 'lodash/find'
/**
 * Enum for Amazon Transport Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const transportStatusEnum = Object.freeze({
  WORKING: { key: 'WORKING', value: 'WORKING', text: 'Working' },
  ESTIMATING: { key: 'ESTIMATING', value: 'ESTIMATING', text: 'Estimating' },
  ESTIMATED: { key: 'ESTIMATED', value: 'ESTIMATED', text: 'Estimated' },
  ERROR_ON_ESTIMATING: { key: 'ERROR_ON_ESTIMATING', value: 'ERROR_ON_ESTIMATING', text: 'Error On Estimating' },
  CONFIRMING: { key: 'CONFIRMING', value: 'CONFIRMING', text: 'Confirming' },
  CONFIRMED: { key: 'CONFIRMED', value: 'CONFIRMED', text: 'Confirmed' },
  ERROR_ON_CONFIRMING: { key: 'ERROR_ON_CONFIRMING', value: 'ERROR_ON_CONFIRMING', text: 'Error On Confirming' },
  VOIDING: { key: 'VOIDING', value: 'VOIDING', text: 'Voiding' },
  VOIDED: { key: 'VOIDED', value: 'VOIDED', text: 'Voided' },
  ERROR_IN_VOIDING: { key: 'ERROR_IN_VOIDING', value: 'ERROR_IN_VOIDING', text: 'Error InVoiding' },
  ERROR: { key: 'ERROR', value: 'ERROR', text: 'Error' },
})

function getTransportStatusEnum(predicate, predicateKey = 'key') {
  const result = find(transportStatusEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

export default transportStatusEnum

export {
  getTransportStatusEnum,
}
