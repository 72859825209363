import find from 'lodash/find'

/**
 * Enum for Tax Code.
 * @readonly
 * @enum {{key: string, value: string}}
 */

const taxCodeEnum = Object.freeze({
  A1_ANIMAL14: { key: 'A1_ANIMAL14', value: 'A1_ANIMAL14', text: 'A1_ANIMAL14' },
  A1_TOOLS1: { key: 'A1_TOOLS1', value: 'A1_TOOLS1', text: 'A1_TOOLS1' },
  A_AUTOMOTIVE_MOTOROIL: { key: 'A_AUTOMOTIVE_MOTOROIL', value: 'A_AUTOMOTIVE_MOTOROIL', text: 'A_AUTOMOTIVE_MOTOROIL' },
  A_BABY_BRSTPUMP: { key: 'A_BABY_BRSTPUMP', value: 'A_BABY_BRSTPUMP', text: 'A_BABY_BRSTPUMP' },
  A_BABY_CARSEAT: { key: 'A_BABY_CARSEAT', value: 'A_BABY_CARSEAT', text: 'A_BABY_CARSEAT' },
  A_BABY_CLOTH: { key: 'A_BABY_CLOTH', value: 'A_BABY_CLOTH', text: 'A_BABY_CLOTH' },
  A_BABY_DIAPER: { key: 'A_BABY_DIAPER', value: 'A_BABY_DIAPER', text: 'A_BABY_DIAPER' },
  A_BOOKS_GEN: { key: 'A_BOOKS_GEN', value: 'A_BOOKS_GEN', text: 'A_BOOKS_GEN' },
  A_BOOKS_RELIG: { key: 'A_BOOKS_RELIG', value: 'A_BOOKS_RELIG', text: 'A_BOOKS_RELIG' },
  A_BUNDLE_PERDCL: { key: 'A_BUNDLE_PERDCL', value: 'A_BUNDLE_PERDCL', text: 'A_BUNDLE_PERDCL' },
  A_CLSFD_52W_4Q: { key: 'A_CLSFD_52W_4Q', value: 'A_CLSFD_52W_4Q', text: 'A_CLSFD_52W_4Q' },
  A_CLSFD_52WKLY: { key: 'A_CLSFD_52WKLY', value: 'A_CLSFD_52WKLY', text: 'A_CLSFD_52WKLY' },
  A_CLSFD_ANNUAL: { key: 'A_CLSFD_ANNUAL', value: 'A_CLSFD_ANNUAL', text: 'A_CLSFD_ANNUAL' },
  A_CLSFD_SEMIANNL: { key: 'A_CLSFD_SEMIANNL', value: 'A_CLSFD_SEMIANNL', text: 'A_CLSFD_SEMIANNL' },
  A_CLTH_ATHL: { key: 'A_CLTH_ATHL', value: 'A_CLTH_ATHL', text: 'A_CLTH_ATHL' },
  A_CLTH_BATH: { key: 'A_CLTH_BATH', value: 'A_CLTH_BATH', text: 'A_CLTH_BATH' },
  A_CLTH_BOOKBAG: { key: 'A_CLTH_BOOKBAG', value: 'A_CLTH_BOOKBAG', text: 'A CLTH BOOKBAG' },
  A_CLTH_BUCKLS: { key: 'A_CLTH_BUCKLS', value: 'A_CLTH_BUCKLS', text: 'A CLTH BUCKLS' },
  A_CLTH_COMPON: { key: 'A_CLTH_COMPON', value: 'A_CLTH_COMPON', text: 'A CLTH COMPON' },
  A_CLTH_CSTUMS: { key: 'A_CLTH_CSTUMS', value: 'A_CLTH_CSTUMS', text: 'A CLTH CSTUMS' },
  A_CLTH_FABRIC: { key: 'A_CLTH_FABRIC', value: 'A_CLTH_FABRIC', text: 'A CLTH FABRIC' },
  A_CLTH_FORMAL: { key: 'A_CLTH_FORMAL', value: 'A_CLTH_FORMAL', text: 'A CLTH FORMAL' },
  A_CLTH_FUR: { key: 'A_CLTH_FUR', value: 'A_CLTH_FUR', text: 'A CLTH FUR' },
  A_CLTH_GEN: { key: 'A_CLTH_GEN', value: 'A_CLTH_GEN', text: 'A CLTH GEN' },
  A_CLTH_HAIRACCESSORIES: { key: 'A_CLTH_HAIRACCESSORIES', value: 'A_CLTH_HAIRACCESSORIES', text: 'A CLTH HAIRACCESSORIES' },
  A_CLTH_HANDKE: { key: 'A_CLTH_HANDKE', value: 'A_CLTH_HANDKE', text: 'A CLTH HANDKE' },
  A_CLTH_HBAGS: { key: 'A_CLTH_HBAGS', value: 'A_CLTH_HBAGS', text: 'A CLTH HBAGS' },
  A_CLTH_IFUR: { key: 'A_CLTH_IFUR', value: 'A_CLTH_IFUR', text: 'A CLTH IFUR' },
  A_CLTH_MASK: { key: 'A_CLTH_MASK', value: 'A_CLTH_MASK', text: 'A CLTH MASK' },
  A_CLTH_PROTECTIVE: { key: 'A_CLTH_PROTECTIVE', value: 'A_CLTH_PROTECTIVE', text: 'A CLTH PROTECTIVE' },
  A_CLTH_UMBRELLA: { key: 'A_CLTH_UMBRELLA', value: 'A_CLTH_UMBRELLA', text: 'A CLTH UMBRELLA' },
  A_COLLECTIBLE_COIN: { key: 'A_COLLECTIBLE_COIN', value: 'A_COLLECTIBLE_COIN', text: 'A COLLECTIBLE COIN' },
  A_COMP_COMPUTER: { key: 'A_COMP_COMPUTER', value: 'A_COMP_COMPUTER', text: 'A COMP COMPUTER' },
  A_COMP_EDUSOFT: { key: 'A_COMP_EDUSOFT', value: 'A_COMP_EDUSOFT', text: 'A COMP EDUSOFT' },
  A_COMP_EREADER: { key: 'A_COMP_EREADER', value: 'A_COMP_EREADER', text: 'A COMP EREADER' },
  A_COMP_GAMPER: { key: 'A_COMP_GAMPER', value: 'A_COMP_GAMPER', text: 'A COMP GAMPER' },
  A_COMP_PDA: { key: 'A_COMP_PDA', value: 'A_COMP_PDA', text: 'A COMP PDA' },
  A_COMP_PERIPH: { key: 'A_COMP_PERIPH', value: 'A_COMP_PERIPH', text: 'A COMP PERIPH' },
  A_COMP_PRINT: { key: 'A_COMP_PRINT', value: 'A_COMP_PRINT', text: 'A COMP PRINT' },
  A_COMP_PRTSUP: { key: 'A_COMP_PRTSUP', value: 'A_COMP_PRTSUP', text: 'A COMP PRTSUP' },
  A_COMP_SCANNER: { key: 'A_COMP_SCANNER', value: 'A_COMP_SCANNER', text: 'A COMP SCANNER' },
  A_COMP_SOFTOP: { key: 'A_COMP_SOFTOP', value: 'A_COMP_SOFTOP', text: 'A COMP SOFTOP' },
  A_COMP_SOFTRC: { key: 'A_COMP_SOFTRC', value: 'A_COMP_SOFTRC', text: 'A COMP SOFTRC' },
  A_COMP_SOUNDANDVIDEOCARDS: { key: 'A_COMP_SOUNDANDVIDEOCARDS', value: 'A_COMP_SOUNDANDVIDEOCARDS', text: 'A COMP SOUNDANDVIDEOCARDS' },
  A_COMP_STORAGEMEDIA: { key: 'A_COMP_STORAGEMEDIA', value: 'A_COMP_STORAGEMEDIA', text: 'A COMP STORAGEMEDIA' },
  A_EGOODS_DIGITALAUDIOBOOKS: { key: 'A_EGOODS_DIGITALAUDIOBOOKS', value: 'A_EGOODS_DIGITALAUDIOBOOKS', text: 'A EGOODS DIGITALAUDIOBOOKS' },
  A_EGOODS_DIGITALBOOKS: { key: 'A_EGOODS_DIGITALBOOKS', value: 'A_EGOODS_DIGITALBOOKS', text: 'A EGOODS DIGITALBOOKS' },
  A_EGOODS_DIGITALBOOK_RENTAL: { key: 'A_EGOODS_DIGITALBOOK_RENTAL', value: 'A_EGOODS_DIGITALBOOK_RENTAL', text: 'A EGOODS DIGITALBOOK RENTAL' },
  A_EGOODS_DIGITALGAMES: { key: 'A_EGOODS_DIGITALGAMES', value: 'A_EGOODS_DIGITALGAMES', text: 'A EGOODS DIGITALGAMES' },
  A_EGOODS_DIGITALMUSIC: { key: 'A_EGOODS_DIGITALMUSIC', value: 'A_EGOODS_DIGITALMUSIC', text: 'A EGOODS DIGITALMUSIC' },
  A_EGOODS_DIGITALNEWS: { key: 'A_EGOODS_DIGITALNEWS', value: 'A_EGOODS_DIGITALNEWS', text: 'A EGOODS DIGITALNEWS' },
  A_EGOODS_DIGITALNEWSSUBS: { key: 'A_EGOODS_DIGITALNEWSSUBS', value: 'A_EGOODS_DIGITALNEWSSUBS', text: 'A EGOODS DIGITALNEWSSUBS' },
  A_EGOODS_DIGITALPERDCL: { key: 'A_EGOODS_DIGITALPERDCL', value: 'A_EGOODS_DIGITALPERDCL', text: 'A EGOODS DIGITALPERDCL' },
  A_EGOODS_DIGITALPERDCLSUBS: { key: 'A_EGOODS_DIGITALPERDCLSUBS', value: 'A_EGOODS_DIGITALPERDCLSUBS', text: 'A EGOODS DIGITALPERDCLSUBS' },
  A_EGOODS_MISC1: { key: 'A_EGOODS_MISC1', value: 'A_EGOODS_MISC1', text: 'A EGOODS MISC1' },
  A_EGOODS_ONLINEGAMINGSUBS: { key: 'A_EGOODS_ONLINEGAMINGSUBS', value: 'A_EGOODS_ONLINEGAMINGSUBS', text: 'A EGOODS ONLINEGAMINGSUBS' },
  A_EGOODS_SOFT: { key: 'A_EGOODS_SOFT', value: 'A_EGOODS_SOFT', text: 'A EGOODS SOFT' },
  A_EMERGENCY_SUPPLIES: { key: 'A_EMERGENCY_SUPPLIES', value: 'A_EMERGENCY_SUPPLIES', text: 'A EMERGENCY SUPPLIES' },
  A_FOOD_BKTCDY50_90: { key: 'A_FOOD_BKTCDY50_90', value: 'A_FOOD_BKTCDY50_90', text: 'A FOOD BKTCDY50 90' },
  A_FOOD_BKTGN50_75: { key: 'A_FOOD_BKTGN50_75', value: 'A_FOOD_BKTGN50_75', text: 'A FOOD BKTGN50 75' },
  A_FOOD_BKTGN76_90: { key: 'A_FOOD_BKTGN76_90', value: 'A_FOOD_BKTGN76_90', text: 'A FOOD BKTGN76 90' },
  A_FOOD_CARBFLAVORWTR_NONSWEET: { key: 'A_FOOD_CARBFLAVORWTR_NONSWEET', value: 'A_FOOD_CARBFLAVORWTR_NONSWEET', text: 'A FOOD CARBFLAVORWTR NONSWEET' },
  A_FOOD_CARBSFTDK: { key: 'A_FOOD_CARBSFTDK', value: 'A_FOOD_CARBSFTDK', text: 'A FOOD CARBSFTDK' },
  A_FOOD_CARBWTR: { key: 'A_FOOD_CARBWTR', value: 'A_FOOD_CARBWTR', text: 'A FOOD CARBWTR' },
  A_FOOD_CNDY: { key: 'A_FOOD_CNDY', value: 'A_FOOD_CNDY', text: 'A FOOD CNDY' },
  A_FOOD_CNDYFL: { key: 'A_FOOD_CNDYFL', value: 'A_FOOD_CNDYFL', text: 'A FOOD CNDYFL' },
  A_FOOD_FLAVORWATER_NONSWEET: { key: 'A_FOOD_FLAVORWATER_NONSWEET', value: 'A_FOOD_FLAVORWATER_NONSWEET', text: 'A FOOD FLAVORWATER NONSWEET' },
  A_FOOD_GEN: { key: 'A_FOOD_GEN', value: 'A_FOOD_GEN', text: 'A FOOD GEN' },
  A_FOOD_JUICE0_50: { key: 'A_FOOD_JUICE0_50', value: 'A_FOOD_JUICE0_50', text: 'A FOOD JUICE0 50' },
  A_FOOD_JUICE51_99: { key: 'A_FOOD_JUICE51_99', value: 'A_FOOD_JUICE51_99', text: 'A FOOD JUICE51 99' },
  A_FOOD_NCARBWTR: { key: 'A_FOOD_NCARBWTR', value: 'A_FOOD_NCARBWTR', text: 'A FOOD NCARBWTR' },
  A_FOOD_PETFOOD: { key: 'A_FOOD_PETFOOD', value: 'A_FOOD_PETFOOD', text: 'A FOOD PETFOOD' },
  A_FOOD_TRAILMIXWCANDY: { key: 'A_FOOD_TRAILMIXWCANDY', value: 'A_FOOD_TRAILMIXWCANDY', text: 'A FOOD TRAILMIXWCANDY' },
  A_FOOD_VEGSEED: { key: 'A_FOOD_VEGSEED', value: 'A_FOOD_VEGSEED', text: 'A FOOD VEGSEED' },
  A_FOOD_WINE: { key: 'A_FOOD_WINE', value: 'A_FOOD_WINE', text: 'A FOOD WINE' },
  A_GEN_NOTAX: { key: 'A_GEN_NOTAX', value: 'A_GEN_NOTAX', text: 'A GEN NOTAX' },
  A_GEN_RENTAL: { key: 'A_GEN_RENTAL', value: 'A_GEN_RENTAL', text: 'A GEN RENTAL' },
  A_GEN_TAX: { key: 'A_GEN_TAX', value: 'A_GEN_TAX', text: 'A GEN TAX' },
  A_HLTH_BABYSUPPLS: { key: 'A_HLTH_BABYSUPPLS', value: 'A_HLTH_BABYSUPPLS', text: 'A HLTH BABYSUPPLS' },
  A_HLTH_BANDKIT: { key: 'A_HLTH_BANDKIT', value: 'A_HLTH_BANDKIT', text: 'A HLTH BANDKIT' },
  A_HLTH_BRAILLE: { key: 'A_HLTH_BRAILLE', value: 'A_HLTH_BRAILLE', text: 'A HLTH BRAILLE' },
  A_HLTH_CONTACTSOLN: { key: 'A_HLTH_CONTACTSOLN', value: 'A_HLTH_CONTACTSOLN', text: 'A HLTH CONTACTSOLN' },
  A_HLTH_CONTRCEPV: { key: 'A_HLTH_CONTRCEPV', value: 'A_HLTH_CONTRCEPV', text: 'A HLTH CONTRCEPV' },
  A_HLTH_COSMETIC: { key: 'A_HLTH_COSMETIC', value: 'A_HLTH_COSMETIC', text: 'A HLTH COSMETIC' },
  A_HLTH_DIABSUPPLS: { key: 'A_HLTH_DIABSUPPLS', value: 'A_HLTH_DIABSUPPLS', text: 'A HLTH DIABSUPPLS' },
  A_HLTH_DIETSUPMT: { key: 'A_HLTH_DIETSUPMT', value: 'A_HLTH_DIETSUPMT', text: 'A HLTH DIETSUPMT' },
  A_HLTH_DISPOSABLE_PACK: { key: 'A_HLTH_DISPOSABLE_PACK', value: 'A_HLTH_DISPOSABLE_PACK', text: 'A HLTH DISPOSABLE PACK' },
  A_HLTH_FACIALTISSUE: { key: 'A_HLTH_FACIALTISSUE', value: 'A_HLTH_FACIALTISSUE', text: 'A HLTH FACIALTISSUE' },
  A_HLTH_FAMPLANTEST: { key: 'A_HLTH_FAMPLANTEST', value: 'A_HLTH_FAMPLANTEST', text: 'A HLTH FAMPLANTEST' },
  A_HLTH_FEMHYG: { key: 'A_HLTH_FEMHYG', value: 'A_HLTH_FEMHYG', text: 'A HLTH FEMHYG' },
  A_HLTH_FEMHYGSPRAY: { key: 'A_HLTH_FEMHYGSPRAY', value: 'A_HLTH_FEMHYGSPRAY', text: 'A HLTH FEMHYGSPRAY' },
  A_HLTH_HANDSANITIZER: { key: 'A_HLTH_HANDSANITIZER', value: 'A_HLTH_HANDSANITIZER', text: 'A HLTH HANDSANITIZER' },
  A_HLTH_HEARINGAID_BATT: { key: 'A_HLTH_HEARINGAID_BATT', value: 'A_HLTH_HEARINGAID_BATT', text: 'A HLTH HEARINGAID BATT' },
  A_HLTH_INCONT: { key: 'A_HLTH_INCONT', value: 'A_HLTH_INCONT', text: 'A HLTH INCONT' },
  A_HLTH_INCONTPADS: { key: 'A_HLTH_INCONTPADS', value: 'A_HLTH_INCONTPADS', text: 'A HLTH INCONTPADS' },
  A_HLTH_INCONTPADS_DISP: { key: 'A_HLTH_INCONTPADS_DISP', value: 'A_HLTH_INCONTPADS_DISP', text: 'A HLTH INCONTPADS DISP' },
  A_HLTH_MEDHAIRLOSS: { key: 'A_HLTH_MEDHAIRLOSS', value: 'A_HLTH_MEDHAIRLOSS', text: 'A HLTH MEDHAIRLOSS' },
  A_HLTH_MISCDURABLEMED: { key: 'A_HLTH_MISCDURABLEMED', value: 'A_HLTH_MISCDURABLEMED', text: 'A HLTH MISCDURABLEMED' },
  A_HLTH_MISCMOBILITY1: { key: 'A_HLTH_MISCMOBILITY1', value: 'A_HLTH_MISCMOBILITY1', text: 'A HLTH MISCMOBILITY1' },
  A_HLTH_MISCMOBILITY2: { key: 'A_HLTH_MISCMOBILITY2', value: 'A_HLTH_MISCMOBILITY2', text: 'A HLTH MISCMOBILITY2' },
  A_HLTH_MOBILITY: { key: 'A_HLTH_MOBILITY', value: 'A_HLTH_MOBILITY', text: 'A HLTH MOBILITY' },
  A_HLTH_MONITOR: { key: 'A_HLTH_MONITOR', value: 'A_HLTH_MONITOR', text: 'A HLTH MONITOR' },
  A_HLTH_NASALSTRIP: { key: 'A_HLTH_NASALSTRIP', value: 'A_HLTH_NASALSTRIP', text: 'A HLTH NASALSTRIP' },
  A_HLTH_OTCMED: { key: 'A_HLTH_OTCMED', value: 'A_HLTH_OTCMED', text: 'A HLTH OTCMED' },
  A_HLTH_PROSTHETIC: { key: 'A_HLTH_PROSTHETIC', value: 'A_HLTH_PROSTHETIC', text: 'A HLTH PROSTHETIC' },
  A_HLTH_REMEDY: { key: 'A_HLTH_REMEDY', value: 'A_HLTH_REMEDY', text: 'A HLTH REMEDY' },
  A_HLTH_SPFCORALHYG: { key: 'A_HLTH_SPFCORALHYG', value: 'A_HLTH_SPFCORALHYG', text: 'A HLTH SPFCORALHYG' },
  A_HLTH_SPFCOTCMED: { key: 'A_HLTH_SPFCOTCMED', value: 'A_HLTH_SPFCOTCMED', text: 'A HLTH SPFCOTCMED' },
  A_HLTH_SUNSCRN: { key: 'A_HLTH_SUNSCRN', value: 'A_HLTH_SUNSCRN', text: 'A HLTH SUNSCRN' },
  A_HLTH_TENS: { key: 'A_HLTH_TENS', value: 'A_HLTH_TENS', text: 'A HLTH TENS' },
  A_HLTH_THRMTR: { key: 'A_HLTH_THRMTR', value: 'A_HLTH_THRMTR', text: 'A HLTH THRMTR' },
  A_HLTH_TISSUETOW: { key: 'A_HLTH_TISSUETOW', value: 'A_HLTH_TISSUETOW', text: 'A HLTH TISSUETOW' },
  A_HLTH_TOOTHPASTEFL: { key: 'A_HLTH_TOOTHPASTEFL', value: 'A_HLTH_TOOTHPASTEFL', text: 'A HLTH TOOTHPASTEFL' },
  A_HOUSEHOLD_LINEN: { key: 'A_HOUSEHOLD_LINEN', value: 'A_HOUSEHOLD_LINEN', text: 'A HOUSEHOLD LINEN' },
  A_HOUSEHOLD_PAPERPRODS: { key: 'A_HOUSEHOLD_PAPERPRODS', value: 'A_HOUSEHOLD_PAPERPRODS', text: 'A HOUSEHOLD PAPERPRODS' },
  A_HOUSEHOLD_SOLAR: { key: 'A_HOUSEHOLD_SOLAR', value: 'A_HOUSEHOLD_SOLAR', text: 'A HOUSEHOLD SOLAR' },
  A_NEWS_104PLUS: { key: 'A_NEWS_104PLUS', value: 'A_NEWS_104PLUS', text: 'A NEWS 104PLUS' },
  A_NEWS_12MTHLY: { key: 'A_NEWS_12MTHLY', value: 'A_NEWS_12MTHLY', text: 'A NEWS 12MTHLY' },
  A_NEWS_26BIWKLY: { key: 'A_NEWS_26BIWKLY', value: 'A_NEWS_26BIWKLY', text: 'A NEWS 26BIWKLY' },
  A_NEWS_4QTLY: { key: 'A_NEWS_4QTLY', value: 'A_NEWS_4QTLY', text: 'A NEWS 4QTLY' },
  A_NEWS_52WKLY: { key: 'A_NEWS_52WKLY', value: 'A_NEWS_52WKLY', text: 'A NEWS 52WKLY' },
  A_PERDCL_104PLUS: { key: 'A_PERDCL_104PLUS', value: 'A_PERDCL_104PLUS', text: 'A PERDCL 104PLUS' },
  A_PERDCL_52W_4Q: { key: 'A_PERDCL_52W_4Q', value: 'A_PERDCL_52W_4Q', text: 'A PERDCL 52W 4Q' },
  A_PERDCL_52WKLY: { key: 'A_PERDCL_52WKLY', value: 'A_PERDCL_52WKLY', text: 'A PERDCL 52WKLY' },
  A_PERDCL_ANNUAL: { key: 'A_PERDCL_ANNUAL', value: 'A_PERDCL_ANNUAL', text: 'A PERDCL ANNUAL' },
  A_PERDCL_SEMIANNL: { key: 'A_PERDCL_SEMIANNL', value: 'A_PERDCL_SEMIANNL', text: 'A PERDCL SEMIANNL' },
  A_REST_COLDPREPARED: { key: 'A_REST_COLDPREPARED', value: 'A_REST_COLDPREPARED', text: 'A REST COLDPREPARED' },
  A_REST_DELIVERY: { key: 'A_REST_DELIVERY', value: 'A_REST_DELIVERY', text: 'A REST DELIVERY' },
  A_REST_HOTPREPARED: { key: 'A_REST_HOTPREPARED', value: 'A_REST_HOTPREPARED', text: 'A REST HOTPREPARED' },
  A_SCHL_GRAPHCALC: { key: 'A_SCHL_GRAPHCALC', value: 'A_SCHL_GRAPHCALC', text: 'A SCHL GRAPHCALC' },
  A_SCHL_INSTRUMENT: { key: 'A_SCHL_INSTRUMENT', value: 'A_SCHL_INSTRUMENT', text: 'A SCHL INSTRUMENT' },
  A_SCHL_SUPPLS: { key: 'A_SCHL_SUPPLS', value: 'A_SCHL_SUPPLS', text: 'A SCHL SUPPLS' },
  A_SERV_CLEAN: { key: 'A_SERV_CLEAN', value: 'A_SERV_CLEAN', text: 'A SERV CLEAN' },
  A_SERV_INSTALL: { key: 'A_SERV_INSTALL', value: 'A_SERV_INSTALL', text: 'A SERV INSTALL' },
  A_SPORT_ASUPPORT: { key: 'A_SPORT_ASUPPORT', value: 'A_SPORT_ASUPPORT', text: 'A SPORT ASUPPORT' },
  A_SPORT_ATHLSHOES: { key: 'A_SPORT_ATHLSHOES', value: 'A_SPORT_ATHLSHOES', text: 'A SPORT ATHLSHOES' },
  A_SPORT_BIKEHLMT: { key: 'A_SPORT_BIKEHLMT', value: 'A_SPORT_BIKEHLMT', text: 'A SPORT BIKEHLMT' },
  A_SPORT_MISCSPORTS1: { key: 'A_SPORT_MISCSPORTS1', value: 'A_SPORT_MISCSPORTS1', text: 'A SPORT MISCSPORTS1' },
  A_SPORT_PROTECTIVEGEAR: { key: 'A_SPORT_PROTECTIVEGEAR', value: 'A_SPORT_PROTECTIVEGEAR', text: 'A SPORT PROTECTIVEGEAR' },
  A_SPORT_SKISUIT: { key: 'A_SPORT_SKISUIT', value: 'A_SPORT_SKISUIT', text: 'A SPORT SKISUIT' },
  A_SPORT_WINTERSPORTBOOTS: { key: 'A_SPORT_WINTERSPORTBOOTS', value: 'A_SPORT_WINTERSPORTBOOTS', text: 'A SPORT WINTERSPORTBOOTS' },
  A_WARR_PARTSNSVCE: { key: 'A_WARR_PARTSNSVCE', value: 'A_WARR_PARTSNSVCE', text: 'A WARR PARTSNSVCE' },
})

function getTaxCodeEnum(predicate, predicateKey = 'key') {
  const result = find(taxCodeEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getTaxCodeEnumList() {
  const options = []
  Object
    .keys(taxCodeEnum)
    .map(key => options.push(
      {
        value: taxCodeEnum[key].value,
        text: taxCodeEnum[key].text,
      },
    ))
  return options
}

export default taxCodeEnum

export {
  getTaxCodeEnum,
  getTaxCodeEnumList,
}
