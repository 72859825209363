<template>
  <div class="card mb-1">
    <div class="card-body">
      <div v-for="item in data" :key="item.wpid" class="d-flex flex-row justify-content-start align-items-center w-100">
        <img
        :src="item.image ? item.image : defaultImageUrl"
        :alt="item.name"
        class="img-fluid mr-1"
        style="max-height: 64px"
      >
      <div class="d-flex flex-column flex-fill">
        <div class="text-warning font-weight-bold">
          {{ item.title }}
        </div>
        <div>
        <div class="d-flex flex-row row justify-content-start">
          <div class="col-lg-6 col-md-12">
            Barcode: {{ item.gtin ? item.upc : '-' }}
          </div>
          <div class="col-lg-6 col-md-12">
            MSKU: {{ item.msku ? item.msku : '-' }}
          </div>
        </div>
        <div class="d-flex flex-row row justify-content-start">
          <div class="col-lg-6 col-md-12">
            Item ID: <a :href="`https://www.walmart.com/ip/${item.itemId}`" target="_blank">{{ item.itemId ? item.itemId : '-' }}</a>
          </div>
          <div class="col-lg-6 col-md-12">
            GTIN: {{ item.gtin }}
          </div>
        </div>
        </div>
      </div>
      <div>
        <dx-util-button
            type="success"
            :text="actionButtonText(item)"
            :disabled="isAllowedToAction(item)"
            @click="onEmitSelectedItem(item)"
          />
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'

const imgUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  emits: ['emited-selected-item'],
  setup(props, ctx) {
    const { data } = props
    const itemInfo = ref({ ...data })
    const defaultImageUrl = ref(imgUrl)
    const actionButtonText = item => {
      if (item.msku) {
        return 'Replenish'
      }
      return 'Select'
    }
    const isAllowedToAction = item => !item.marketPlaceItem
    const onEmitSelectedItem = item => {
      ctx.emit('emited-selected-item', item)
    }
    return {
      itemInfo,
      defaultImageUrl,
      actionButtonText,
      isAllowedToAction,
      onEmitSelectedItem,
    }
  },
}
</script>
