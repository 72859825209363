import find from 'lodash/find'
/**
 * Enum for Ship To County Code.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const shipToCountyCodeEnum = Object.freeze({
  US: { key: 'US', value: 'US', text: 'United States' },
  CA: { key: 'CA', value: 'CA', text: 'Canada' },
  MX: { key: 'MX', value: 'MX', text: 'Mexico' },
  DE: { key: 'DE', value: 'DE', text: 'Germany' },
  ES: { key: 'ES', value: 'ES', text: 'Spain' },
  FR: { key: 'FR', value: 'FR', text: 'France' },
  GB: { key: 'GB', value: 'GB', text: 'United Kingdom' },
  IT: { key: 'IT', value: 'IT', text: 'Italy' },
})

function getShipToCountyCodeEnum(predicate, predicateKey = 'key') {
  const result = find(shipToCountyCodeEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getShipToCountryCodeEnumList() {
  const options = []
  Object
    .keys(shipToCountyCodeEnum)
    .map(key => options.push(
      {
        value: shipToCountyCodeEnum[key].value,
        text: shipToCountyCodeEnum[key].text,
      },
    ))
  return options
}

export default shipToCountyCodeEnum

export {
  getShipToCountyCodeEnum,
  getShipToCountryCodeEnumList,
}
