import { ref } from '@vue/composition-api'
import useMPItem from './useMpItem.js'
import fieldEnum, { getFieldEnumList } from './consts/field-type.enum.js'
import itemConditionEnum, { getItemConditionEnumList } from './consts/item-condition.enum.js'
import taxCodeEnum, { getTaxCodeEnumList } from './consts/tax-code.enum.js'

export default function useWfsShipmentItem() {
  const {
    MPItem,
    setWFSReplenishItem,
    addProductIdentifiers,
    addExternalProductIdentifier,
    addStateRestrictions,
    addAdditionalProductAttribute,
    addAdditionalOfferAttribute,
  } = useMPItem()

  const barcodeTypes = ref(getFieldEnumList(true))
  const itemConditions = ref(getItemConditionEnumList())
  const taxCodes = ref(getTaxCodeEnumList())

  const bundleItems = ref({
    productId: null, // Long
    itemInfo: [], // List<ItemInfo>
  })

  const itemInfo = ref({
    productId: null, // Long
    inventoryId: null, // Long
    msku: null, // String
    mskuFnskuPairId: null, // Long
    quantity: 0, // Integer
  })

  const shipmentItemBasicData = ref({
    uuid: null, // String
    id: null, // Long
    productId: null, // Long
    inventoryId: null, // Long
    mskuFnskuPairId: null, // Long
    itemSource: null, // Items Source Enum
    quantity: 0, // Integer
  })

  const shipmentPlanItem = ref({
    id: null, // Long
    companyId: null, // Long
    warehouseId: null, // Long
    storeId: null, // Long
    shipmentPlanId: null, // Long
    quantity: 0, // Integer
    vendorPackQty: 0, // Integer
    innerPackQty: 0, // Integer
    productId: null, // Long
    mskuFnskuPairId: null, // Long
    cost: 0, // BigDecimal
    salePrice: 0, // BigDecimal
    supplier: null, // String
    title: null, // String
    itemDesc: null, // String
    barcodeType: fieldEnum.UPC.value, // Fied Type Enum
    barcode: null, // String
    itemId: null, // String
    msku: null, // String
    mskuAuto: null, // Boolean
    itemCondition: itemConditionEnum.NEW.value, // Item Condition Enum
    mpItem: MPItem, // MP Item Object
    planId: null, // Long
    taxCode: taxCodeEnum.A_GEN_TAX.value, // Tax Code Enum
    expirationDate: null, // Instant
    notes: null, // String
    productGroup: null, // String
    packType: null, // Pack Type Enum
    createType: null, // Create Type Enum
    bundleItems: bundleItems.value, // Bundle Items Object
    items: [], // List<ShipmentItemBasicData>
    charges: [], // List<ItemChargeDTO>
    updateDefaultCharges: false, // Boolean
  })

  /**
   * @param {Object} data
   * @param {Number} data.productId
   * @param {Number} data.mskuFnskuPairId
   * @param {String} data.itemsSource
   * @param {Number} data.quantity
   */
  function addShipmentItemBasicData(data) {
    const existingItem = shipmentPlanItem.value.items.find(item => item.inventoryId === data.inventoryId)
    if (existingItem) {
      const index = shipmentPlanItem.value.items.indexOf(existingItem)
      shipmentPlanItem.value.items.splice(index, 1)
      shipmentPlanItem.value.quantity -= existingItem.quantity
    }

    shipmentItemBasicData.value = {
      id: data.id,
      productId: data.productId,
      inventoryId: data.inventoryId,
      mskuFnskuPairId: data.mskuFnskuPairId,
      itemSource: data.itemSource,
      quantity: data.quantity,
    }
    shipmentPlanItem.value.items.push(shipmentItemBasicData.value)

    shipmentPlanItem.value.vendorPackQty = shipmentPlanItem.value.quantity
    shipmentPlanItem.value.innerPackQty = 1
    // Reset shipmentItemBasicData to default
    shipmentItemBasicData.value = {
      id: null,
      productId: null,
      inventoryId: null,
      mskuFnskuPairId: null,
      itemSource: null,
      quantity: 0,
    }
  }

  function mpItemAddProductIdentifiers(productIdType, productId) {
    addProductIdentifiers({
      productIdType,
      productId,
    })
  }

  function mpItemAddExternalProductIdentifier(externalProductId, externalProductIdType) {
    addExternalProductIdentifier({
      externalProductId,
      externalProductIdType,
    })
  }

  function mpItemAddStateRestrictions(stateRestrictionsText, states, zipCodes) {
    addStateRestrictions({
      stateRestrictionsText,
      states,
      zipCodes,
    })
  }

  function mpItemAddAdditionalProductAttribute(productAttributeName, productAttributeValue) {
    addAdditionalProductAttribute({
      productAttributeName,
      productAttributeValue,
    })
  }

  function mpItemAddAdditionalOfferAttribute(additionalOfferAttributeName, additionalOfferAttributeValue) {
    addAdditionalOfferAttribute({
      additionalOfferAttributeName,
      additionalOfferAttributeValue,
    })
  }

  function mpItemSetWFSReplenishItem(sku, price, itemQty, vendorPackQty, innerPackQty, itemDesc, ShippingWeight, productCategory) {
    setWFSReplenishItem(sku, price, itemQty, vendorPackQty, innerPackQty, itemDesc, ShippingWeight, productCategory)
  }

  return {
    barcodeTypes,
    itemConditions,
    taxCodes,
    shipmentPlanItem,
    bundleItems,
    itemInfo,
    addShipmentItemBasicData,
    mpItemAddProductIdentifiers,
    mpItemAddExternalProductIdentifier,
    mpItemAddStateRestrictions,
    mpItemAddAdditionalProductAttribute,
    mpItemAddAdditionalOfferAttribute,
    mpItemSetWFSReplenishItem,
  }
}
