import find from 'lodash/find'
/**
 * Enum for PSH Shipment Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const shipmentStatusEnum = Object.freeze({
  IN_PREP: { key: 'IN_PREP', value: 0, text: 'In Prep' },
  PRE_SHIP: { key: 'PRE_SHIP', value: 1, text: 'Pre Ship' },
  READY_TO_SHIP: { key: 'READY_TO_SHIP', value: 2, text: 'Ready To Ship' },
  SHIPPED: { key: 'SHIPPED', value: 3, text: 'Shipped' },
  DELIVERED: { key: 'DELIVERED', value: 4, text: 'Delivered' },
  RECONCILED: { key: 'RECONCILED', value: 5, text: 'Reconciled' },
  ALL: { key: 'ALL', value: 6, text: 'ALL' },
  NOT_CREATED_YET: { key: 'NOT_CREATED_YET', value: 7, text: 'Not Created Yet' },
  CONSOLIDATED: { key: 'CONSOLIDATED', value: 8, text: 'Consolidated' },
  CONSOLIDATING: { key: 'CONSOLIDATING', value: 9, text: 'Consolidating' },
})

function getShipmentStatusEnum(predicate, predicateKey = 'key') {
  const result = find(shipmentStatusEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

export default shipmentStatusEnum

export {
  getShipmentStatusEnum,
}
