import find from 'lodash/find'
/**
 * Enum for Field Type.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const fieldEnum = Object.freeze({
  PRODUCTNAME: {
    key: 'PRODUCTNAME', value: 'PRODUCTNAME', text: 'Product Name', visible: false,
  },
  SKU: {
    key: 'SKU', value: 'SKU', text: 'SKU', visible: false,
  },
  UPC: {
    key: 'UPC', value: 'UPC', text: 'UPC', visible: true,
  },
  GTIN: {
    key: 'GTIN', value: 'GTIN', text: 'GTIN', visible: true,
  },
  WPID: {
    key: 'WPID', value: 'WPID', text: 'WPID', visible: false,
  },
  ISBN: {
    key: 'ISBN', value: 'ISBN', text: 'ISBN', visible: true,
  },
  EAN: {
    key: 'EAN', value: 'EAN', text: 'EAN', visible: false,
  },
  ITEMID: {
    key: 'ITEMID', value: 'ITEMID', text: 'Item ID', visible: false,
  },
})

function getFieldEnum(predicate, predicateKey = 'key') {
  const result = find(fieldEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getFieldEnumList(isOnlyVisible = false) {
  const options = []
  Object
    .keys(fieldEnum)
    .map(key => {
      if (fieldEnum[key].visible === isOnlyVisible) {
        options.push({
          value: fieldEnum[key].value,
          text: fieldEnum[key].text,
        })
      }
      return options
    })
  return options
}

export default fieldEnum

export {
  getFieldEnum,
  getFieldEnumList,
}
