export const FBAEvents = Object.freeze({
  PRINT_STATIC_LABEL: { event: 'PRINT_STATIC_LABEL' },
  PRINT_MANUAL_FNSKU_LABEL: { event: 'PRINT_MANUAL_FNSKU_LABEL' },
  PRINT_CODE_LABEL_FNSKU_DIRECT: { event: 'PRINT_CODE_LABEL_FNSKU_DIRECT' },
  PRINT_CODE_LABEL_FNSKU_CONDITIONAL: { event: 'PRINT_CODE_LABEL_FNSKU_CONDITIONAL' },
  PRINT_CODE_LABEL_DIRECT: { event: 'PRINT_CODE_LABEL_DIRECT' },
  PRINT_CODE_LABEL_CONDITIONAL: { event: 'PRINT_CODE_LABEL_CONDITIONAL' },
  BATCH_SEARCH_ITEM: { event: 'BATCH_SEARCH_ITEM' },
  BOX_FILTER_SELECTED_FNSKU: { event: 'BOX_FILTER_SELECTED_FNSKU' },
  BOX_UPDATE_AFTER_SHIP_ACTION: { event: 'BOX_UPDATE_AFTER_SHIP_ACTION', action: 'UPDATED' },
  UPDATED_BATCH_BOX: { event: 'UPDATED_BATCH_BOX' },
  CLEAR_SEARCH_TEXT: { event: 'CLEAR_SEARCH_TEXT' },
  UPDATE_SELECTED_BATCH: { event: 'UPDATE_SELECTED_BATCH' },
  UPDATE_SHIPMENT_BATCHES_AFTER_ADDED_BOX_ITEM: { event: 'UPDATE_SHIPMENT_BATCHES_AFTER_ADDED_BOX_ITEM' },
})

export const FBMEvents = Object.freeze({
  SEARCH_BY_SELECTED_STORE: { event: 'SEARCH_BY_SELECTED_STORE' },
  SELECT_ORDER_STATUS: { event: 'SELECT_ORDER_STATUS' },
})

export const ItemSortEvents = Object.freeze({
  PRINT_CODE_LABEL_FNSKU_DIRECT: { event: 'PRINT_CODE_LABEL_FNSKU_DIRECT' },
})
