<template>
  <div class="row">
    <div class="col-md-8">
      <div class="row">
        <!--Begin:: Walmart Item Info-->
        <div class="col-12">
          <walmart-item-info :data="searchItems" @emited-selected-item="onEmitSelectedItemData" />
        </div>
        <!--End:: Walmart Item Info-->
        <!--Begin:: Inventory Item Info-->
        <div class="col-12">
          <div class="card mb-0">
            <div class="card-body">
              <div class="row">
            <div class="col-12">
              <dx-util-text-box v-model="inventoryItemSearchText" mode="search" @key-down="getInventoryDatSearchByFilter" />
            </div>
            <!--Begin:: Header -->
            <div class="col-12 border-bottom new-item-header">
              <div class="row">
                <div class="col-6 py-1 text-yellow">
                  Item
                </div>
                <div class="col-2 py-1 text-yellow">
                  Quantities
                </div>
                <div class="col-1 py-1 text-yellow">
                  Receive
                </div>
                <div class="col-1 py-1 text-yellow">
                  Condition
                </div>
                <div class="col-2 py-1 text-yellow">
                  Source
                </div>
              </div>
            </div>
            <!--End:: Header -->
            <div v-for="item in inventoryItems" :key="`${item.id}`" class="col-12 border-bottom new-item-detail">
              <div class="row">
                <div class="col-6">
                  <item-info
                    :item="item"
                    :is-printable="false"
                    :is-truncated="true"
                    :item-info-visible="true"
                  />
                </div>
                <div class="col-2 d-flex flex-column">
                  <div class="d-flex flex-row justify-content-start align-content-center">
                    <div v-b-tooltip.hover="{ variant: 'info' }" title="Receive all avaible quantity" class="flex-fill px-0">
                      Available
                      <span class="count-item count-success cursor-pointer" @click="setAvailableQuantity(item)">
                        {{ item.quantity }}
                        <i class="bi-caret-right-fill" />
                      </span>
                    </div>
                    <div v-if="item.shippedCount > 0" class="flex-fill px-0">
                      Shipped
                      <span class="count-item" :class="item.shippedCount > 0 ? 'count-warning' : ''">
                        {{ item.shippedCount }}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-start">
                    <div v-if="item.reservedCount > 0" class="flex-fill px-0">
                      Reserved
                      <span class="count-item" :class="item.reservedCount > 0 ? 'count-warning' : ''">
                        {{ item.reservedCount }}
                      </span>
                    </div>
                    <div v-if="item.countInBundle > 0" class="flex-fill px-0">
                      In Bundle
                      <span class="count-item" :class="item.countInBundle > 0 ? 'count-warning' : ''">
                        {{ item.countInBundle }}
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-start">
                    <div v-if="item.sortedCount > 0" class="flex-fill px-0">
                      Sorted
                      <span class="count-item" :class="item.sortedCount > 0 ? 'count-warning' : ''">
                        {{ item.sortedCount }}
                      </span>
                    </div>
                    <div v-if="item.countPerBundle > 0" class="flex-fill px-0">
                      Per Bundle
                      <span class="count-item">
                        <span v-if="item.countPerBundle > 0" class="badge badge-danger rounded">
                          {{ item.countPerBundle }}
                        </span>
                        <span v-else> {{ item.countPerBundle }}</span>
                      </span>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-start">
                    <div v-if="item.damagedCount > 0" class="flex-fill px-0">
                      Damaged
                      <span class="count-item" :class="item.damagedCount > 0 ? 'count-danger' : ''">
                        {{ item.damagedCount }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-1 align-self-center">
                  <dx-util-number-box
                    v-model="item.inUsedCount"
                    width="100%"
                    :read-only="isReadOnlyQuantity"
                    :min="0"
                    :max="item.quantity"
                    :show-spin-buttons="false"
                    :input-attr="{ style: 'padding-top:0px; padding-bottom:0px ' }"
                    @value-changed="onChangeAddQuantity($event, item)"
                  />
                </div>
                <div class="col-1 align-self-center text-center">
                  <span v-if="item.itemCondition" class="badge badge-pill badge-info">
                    {{ item.itemCondition }}
                  </span>
                  <span v-else class="badge badge-pill badge-warning">
                    -
                  </span>
                </div>
                <div class="col-2 d-flex flex-column align-self-center">
                  <span class="badge mb-half" :class="`badge-${getEnumToValue($enums.ItemSourceEnum, item.itemSource).variant}`">
                    {{ getEnumToValue($enums.ItemSourceEnum, item.itemSource).text }}
                  </span>
                  <dx-util-drop-down-button
                    text="Copy All Info"
                    icon="square"
                    key-expr="id"
                    display-expr="text"
                    styling-mode="contained"
                    :drop-down-options="usePropertyDropDownOptions"
                    :items="usePropertyActions"
                    :split-button="true"
                    @item-click="onClickCopyItemDataDropDown($event, item)"
                    @button-click="onClickCopyItemData($event, item)"
                  />
                  <dx-util-button
                    text="Update"
                    icon="bi bi-pencil-square"
                    :visible="item.itemSource === 'inbound'"
                    :element-attr="{ class: 'btn-primary mt-half' }"
                    @click="onClickInboundItemUpdate($event, item)"
                  />
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>

        </div>
        <!--End:: Inventory Item Info-->
      </div>
    </div>
    <div class="col-md-4">
      <div class="card mb-0">
        <div class="card-header border-bottom py-1">
          <h4 class="card-title mb-0">
            Create New Shipment Plan Item
          </h4>
        </div>
        <div class="card-body pt-1 pb-0">
          <dx-util-form
            ref="batchItemFormRef"
            :form-data="shipmentPlanItem"
            :col-count="1"
            :show-colon-after-label="true"
            label-location="top"
            :scrolling-enabled="true"
          >
            <dx-util-item
              data-field="planId"
              :visible="isServingTenant"
              editor-type="dxSelectBox"
              :editor-options="planOptions"
              :label="{ text: 'User Plan' }"
            />
            <dx-util-item data-field="title" editor-type="dxTextBox" />
            <dx-util-group-item :col-count="2">
              <dx-util-item data-field="barcodeType" editor-type="dxSelectBox" :editor-options="barcodeTypeEditorOptions" />
              <dx-util-item data-field="barcode" editor-type="dxTextBox" />
            </dx-util-group-item>
            <dx-util-group-item :col-count="2">
            <dx-util-item data-field="expirationDate" editor-type="dxDateBox" :editor-options="expirationDateEditorOptions" />
              <dx-util-item data-field="productGroup" editor-type="dxTextBox" />
            </dx-util-group-item>
            <dx-util-group-item :col-count="3">
              <dx-util-item data-field="itemCondition" editor-type="dxSelectBox" :editor-options="itemConditionEditorOptions" />
              <dx-util-item data-field="cost" editor-type="dxNumberBox" />
              <dx-util-item data-field="salePrice" editor-type="dxNumberBox" />
            </dx-util-group-item>
            <dx-util-group-item :col-count="3">
              <dx-util-item data-field="quantity" editor-type="dxNumberBox" :editor-options="quantityEditorOptions" />
              <dx-util-item data-field="vendorPackQty" editor-type="dxNumberBox" />
              <dx-util-item data-field="innerPackQty" editor-type="dxNumberBox" />
            </dx-util-group-item>
            <dx-util-item data-field="itemDesc" :label="{text: 'Description'}" template="itemDescInputTemplate" />
            <template #itemDescInputTemplate>
              <div>
                <dx-html-editor
                  v-model="shipmentPlanItem.itemDesc"
                  value-type="html"
                >
                  <dx-toolbar :items="editorItems" />
                </dx-html-editor>
              </div>
            </template>
          </dx-util-form>
        <div class="card-footer px-0 py-1 mt-1">
          <div class="d-flex flex-row justify-content-between">
            <div>
              <button class="btn btn-danger" @click="onClickCancel($event)">
                Cancel
              </button>
            </div>
            <div>
              <button class="btn btn-primary" @click="onClickCreate($event)">
                Add Item
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div></template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable vue/no-unused-components */

import {
  ref, computed, onBeforeMount, onMounted,
} from '@vue/composition-api'
import useCurrentUser from '@/libs/app/current-user'
import wfsService from '@/http/requests/wfs'
import shipService from '@/http/requests/ship/shipService'
import { formatedDateStr, formatedIsoDateStr } from '@/@core/utils/dateHelper.js'
import SearchFilter from '@/http/models/search-filters/ReceivePageSearchFilter'
import PlanSearchFilter from '@/http/models/search-filters/PlanSearchFilter'
import planManagementService from '@/http/requests/plan/planManagementService'
import isEmpty from 'lodash/isEmpty'
import { ValueToEnum, Notify } from '@robustshell/utils/index'
import Pager from '@core/dev-extreme/utils/pager'
import { DxHtmlEditor, DxToolbar } from 'devextreme-vue/html-editor'
import ItemInfo from './ItemInfo.vue'
import WalmartItemInfo from './WalmartItemInfo.vue'
import useWfsShipmentItem from '../useWfsShipmentItem.js'

// const defaultItemImageUrl = require('@/assets/images/undraw/no_images.svg')
// TODO: shipment batch item product id

export default {
  components: {
    ItemInfo,
    WalmartItemInfo,
    DxHtmlEditor,
    DxToolbar,
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
    storeId: {
      type: Number,
      required: true,
    },
    searchText: {
      type: String,
      required: true,
    },
    shipmentPlanId: {
      type: Number,
      required: true,
    },
  },
  emits: ['emited-close'],
  setup(props, ctx) {
    const {
      companyId, storeId, searchText, shipmentPlanId,
    } = props

    const { isServingTenant, userWarehouseId } = useCurrentUser()

    const editorItems = [
      'bold', 'italic', 'separator', {
        formatName: 'size',
        formatValues: ['11px', '12px', '16px'],
      }, 'separator',
      'alignLeft', 'alignCenter', 'alignRight',
    ]

    const isReadOnlyQuantity = ref(false)
    const renderInventoryItem = ref(false)
    const searchItems = ref([])
    const inventoryItems = ref([])
    const inventoryItemSearchText = ref('')

    async function getInventoryData(queryText) {
      inventoryItems.value.length = 0
      const filter = new SearchFilter()
      const pager = new Pager({ pageSize: 20 })
      filter.companyId = companyId
      filter.storeId = storeId
      filter.q = queryText
      filter.itemAction = 'WFS'
      if (filter.upc && filter.q.startsWith('0')) {
        filter.q = filter.q.substring(1)
      }
      const result = await shipService.fetchFbaShipmentBatchParentItemByQuery(filter, pager.toCreatePageable())

      if (!isEmpty(result.data.content)) {
        result.data.content.forEach(item => {
          const shipmentDate = formatedIsoDateStr(item.shipmentDate)
          const exprDate = formatedDateStr(item.expirationDate)
          const catalogItem = item.catalogItem ? item.catalogItem : null
          const itemData = {
            id: item.id,
            amzRank: item.amzRank,
            batchParentId: item.batchParentId,
            itemSource: item.itemSource,
            companyId: item.companyId,
            storeId: item.storeId,
            storeName: item.storeName,
            warehouseId: item.warehouseId,
            productId: item.productId,
            mskuFnskuPairId: item.mskuFnskuPairId,
            title: item.title,
            upc: item.upc,
            asin: item.asin,
            msku: item.msku,
            fnsku: item.fnsku,
            packType: item.packType,
            planId: item.planId,
            palletId: item.palletId,
            priceList: item.priceList,
            quantity: item.quantity,
            quantityPerCase: item.quantityPerCase,
            reservedCount: item.reservedCount,
            receivedCount: item.receivedCount,
            sortedCount: item.sortedCount,
            damagedCount: item.damagedCount,
            shippedCount: item.shippedCount,
            countInBundle: item.countInBundle,
            countPerBundle: item.countPerBundle,
            itemAction: item.itemAction,
            itemCost: item.itemCost,
            salePrice: item.salePrice,
            itemCondition: item.itemCondition,
            supplier: item.supplier,
            batchId: item.batchId,
            batchName: item.batchName,
            tracking: item.tracking,
            orderNumber: item.orderNumber,
            notes: item.notes,
            catalogItem: catalogItem,
            productType: item.productType,
            taxCode: item.taxCode,
            expirationDate: exprDate,
            listingItem: '',
            inUsedCount: 0,
            shipmentDate: shipmentDate,
            smallImage: item.smallImage,
            largeImage: item.largeImage,
            batchUploadedAt: item.batchUploadedAt,
          }
          inventoryItems.value.push(itemData)
        })
      }
      renderInventoryItem.value = true
    }

    async function getInventoryDatSearchByFilter(e) {
      if (e.event.keyCode !== 13) return
      await getInventoryData(inventoryItemSearchText.value)
    }

    async function getShipmentItemsByQuery() {
      const filter = new SearchFilter()
      filter.companyId = companyId
      filter.storeId = storeId
      filter.q = searchText
      const result = await wfsService.searchItem(filter)
      searchItems.value.length = 0
      searchItems.value = [...result]
      inventoryItemSearchText.value = searchText
      await getInventoryData(searchText)
    }

    onBeforeMount(async () => {
      await getShipmentItemsByQuery()
    })

    function getEnumToValue(enumType, value) {
      return ValueToEnum(enumType, value, 'value')
    }

    const {
      barcodeTypes,
      itemConditions,
      shipmentPlanItem,
      addShipmentItemBasicData,
      mpItemAddProductIdentifiers,
      mpItemSetWFSReplenishItem,
    } = useWfsShipmentItem()

    /* This emit function set item info replenish or listing */
    function onEmitSelectedItemData(itemInfo) {
      searchItems.value.length = 0
      searchItems.value.push(itemInfo)

      shipmentPlanItem.value.title = itemInfo.title
      shipmentPlanItem.value.barcode = itemInfo.upc
      shipmentPlanItem.value.barcodeType = itemInfo.fieldEnum
      shipmentPlanItem.value.itemDesc = itemInfo.description
      shipmentPlanItem.value.msku = itemInfo.msku
      shipmentPlanItem.value.itemId = itemInfo.itemId
      mpItemAddProductIdentifiers(
        itemInfo.fieldEnum,
        itemInfo.upc,
      )
    }
    // Set Company Info
    shipmentPlanItem.value.companyId = companyId
    shipmentPlanItem.value.storeId = storeId
    shipmentPlanItem.value.warehouseId = userWarehouseId.value
    shipmentPlanItem.value.shipmentPlanId = shipmentPlanId

    const itemConditionEditorOptions = computed(() => ({
      dataSource: itemConditions.value,
      valueExpr: 'value',
      displayExpr: 'text',
      searchEnabled: true,
    }))

    const quantityEditorOptions = computed(() => ({
      readOnly: true,
      showSpinButtons: false,
      inputAttr: { style: 'color: #2b2d42; font-weight: 700; border: solid 1px #2b2d42; background-color:#ffd6a5' },
    }))

    const barcodeTypeEditorOptions = computed(() => ({
      dataSource: barcodeTypes.value,
      valueExpr: 'value',
      displayExpr: 'text',
      searchEnabled: true,
    }))

    // #region Plan Options
    const plans = ref([])
    function getPlans() {
      PlanSearchFilter.setDefaultFilters()
      PlanSearchFilter.warehouseId = userWarehouseId.value
      PlanSearchFilter.companyId = companyId
      const searchFilter = PlanSearchFilter.getFilters()
      const pageable = 'page=0&size=200'
      planManagementService
        .fetchAllUserPlansByQuery(searchFilter, pageable)
        .then(result => {
          const data = result.data.content
          return data
        })
        .then(data => {
          data.forEach(element => {
            if (element.companyId === companyId) {
              plans.value.push({
                value: element.id,
                text: `${element.planCategory.toUpperCase()}:${
                  element.planName
                }:${element.planType}`,
              })
            }
          })
          const selectedPlanId = data[0].id || null
          return selectedPlanId
        })
        .then(selectedPlanId => {
          shipmentPlanItem.value.planId = selectedPlanId
        })
    }
    onMounted(() => {
      getPlans()
    })

    const planOptions = computed(() => ({
      dataSource: plans.value,
      valueExpr: 'value',
      displayExpr: 'text',
      searchEnabled: false,
    }))
    // #endregion

    const expirationDateEditorOptions = computed(() => ({
      type: 'date',
      displayFormat: 'MM/dd/yyyy',
      useMaskBehavior: true,
      useHiddenSubmitElement: true,
      showClearButton: true,
      pickerType: 'calendar',
      onValueChanged: e => {
        shipmentPlanItem.value.expirationDate = e.value
      },
    }))

    // #region Inventory Item Actions
    const usePropertyActions = ref([
      { id: 1, text: 'Copy All Info', icon: 'square' },
      { id: 2, text: 'Copy (Supplier, Cost, Sale, Plan)', icon: 'copy' },
      { id: 3, text: 'Add as a Bundle/Multi-Pack Item', icon: 'box' },
    ])

    const usePropertyDropDownOptions = ref({
      width: 300,
    })

    function onClickCopyItemData(e, item) {
      const selectedPlanId = item.planId || shipmentPlanItem.value.planId
      shipmentPlanItem.value.productId = item.productId
      shipmentPlanItem.value.title = item.title
      shipmentPlanItem.value.planId = selectedPlanId
      shipmentPlanItem.value.barcode = item.upc
      shipmentPlanItem.value.salePrice = item.salePrice
      shipmentPlanItem.value.cost = item.itemCost
      shipmentPlanItem.value.packType = item.packType
      shipmentPlanItem.value.expirationDate = null
      shipmentPlanItem.value.mskuAuto = false
    }

    function onClickCopyItemDataDropDown(e, item) {
      if (e.itemData.id === 1) {
        onClickCopyItemData(e, item)
      } else if (e.itemData.id === 2) {
        const selectedPlanId = item.planId || shipmentPlanItem.value.planId
        shipmentPlanItem.value.title = item.title
        shipmentPlanItem.value.planId = selectedPlanId
        shipmentPlanItem.value.barcode = item.upc
        shipmentPlanItem.value.salePrice = item.salePrice
        shipmentPlanItem.value.cost = item.itemCost
      } else if (e.itemData.id === 3) {
        Notify.warning('Add as a Bundle/Multi-Pack Item')
      }
    }

    function setAvailableQuantity(item) {
      item.inUsedCount = item.quantity
      Notify.success('Set available quantity')
    }

    function onChangeAddQuantity(e, item) {
      item.inUsedCount = e.value
      const shipmentItemBasicData = {
        id: item.id,
        productId: item.productId,
        inventoryId: item.id,
        mskuFnskuPairId: item.mskuFnskuPairId,
        itemSource: item.itemSource,
        quantity: e.value,
      }
      shipmentPlanItem.value.quantity += e.value
      addShipmentItemBasicData(shipmentItemBasicData)
    }

    function onClickInboundItemUpdate(e, item) {
    }
    // #endregion

    // #region Shipment Item Create
    async function onClickCreate(e) {
      // shipmentPlanItem.value.itemDesc
      mpItemSetWFSReplenishItem(
        shipmentPlanItem.value.msku,
        shipmentPlanItem.value.salePrice,
        shipmentPlanItem.value.quantity,
        shipmentPlanItem.value.vendorPackQty,
        shipmentPlanItem.value.innerPackQty,
        shipmentPlanItem.value.title,
        null,
        'HOME_DECOR_KITCHEN_OTHER',
      )
      shipmentPlanItem.value.expirationDate = formatedIsoDateStr(shipmentPlanItem.value.expirationDate, 'MM/DD/YYYY')
      const result = await wfsService.createShipmentPlanItem(shipmentPlanItem.value)
      ctx.emit('emited-close', result)
    }

    function onClickCancel(e) {
      ctx.emit('emited-cancel', null)
    }
    // #endregion
    return {
      editorItems,
      isReadOnlyQuantity,
      searchItems,
      inventoryItems,
      inventoryItemSearchText,
      renderInventoryItem,
      onEmitSelectedItemData,
      getInventoryDatSearchByFilter,
      barcodeTypeEditorOptions,
      itemConditionEditorOptions,
      quantityEditorOptions,
      expirationDateEditorOptions,
      shipmentPlanItem,
      usePropertyActions,
      usePropertyDropDownOptions,
      planOptions,
      isServingTenant,
      onClickCopyItemDataDropDown,
      getEnumToValue,
      setAvailableQuantity,
      onChangeAddQuantity,
      onClickCopyItemData,
      onClickInboundItemUpdate,
      onClickCreate,
      onClickCancel,
    }
  },
}
</script>
<style lang="scss">
  .new-item-header {
    font-display: block;
    font-weight: 500;
    font-size: 1rem;
  }

  .new-item-detail {
    .count-item {
      font-display: block;
      font-weight: 500;

      &.count-success {
        color: rgba(0, 184, 148, 1);
      }

      &.count-warning {
        color: rgba(253, 203, 110, 1);
      }

      &.count-danger {
        color: rgba(214, 48, 49, 1);
      }

      &.count-info {
        color: rgba(0, 206, 201, 1);
      }

      &::before {
        content: ': ';
        color: rgba(223, 230, 233, 1);
      }
    }
}
</style>
