/**
 * Enum for Freight Class.
 * @readonly
 * @enum {{key: string, value: string}}
 */

const FreightClassEnum = Object.freeze({
  Auto: { key: 'Auto', value: '', text: 'Estimate my freight class' },
  Class50: { key: 'Class50', value: 50, text: 'Class 50' },
  Class55: { key: 'Calss55', value: 55, text: 'Class 55' },
  Class60: { key: 'Calss60', value: 60, text: 'Class 60' },
  Class65: { key: 'Calss65', value: 65, text: 'Class 65' },
  Class70: { key: 'Calss70', value: 70, text: 'Class 70' },
  Class77_5: { key: 'Calss77_5', value: 77.5, text: 'Class 77.5' },
  Class85: { key: 'Calss85', value: 85, text: 'Class 85' },
  Class92_5: { key: 'Calss92_5', value: 92.5, text: 'Class 92.5' },
  Class100: { key: 'Calss100', value: 100, text: 'Class 100' },
  Class110: { key: 'Calss110', value: 110, text: 'Class 110' },
  Class125: { key: 'Calss125', value: 125, text: 'Class 125' },
  Class150: { key: 'Calss150', value: 150, text: 'Class 150' },
  Class175: { key: 'Calss175', value: 175, text: 'Class 175' },
  Class200: { key: 'Calss200', value: 200, text: 'Class 200' },
  Class250: { key: 'Calss250', value: 250, text: 'Class 250' },
  Class300: { key: 'Calss300', value: 300, text: 'Class 300' },
  Class400: { key: 'Calss400', value: 400, text: 'Class 400' },
  Class500: { key: 'Calss500', value: 500, text: 'Class 500' },
  list: () => Object.values(FreightClassEnum)
    .filter(value => typeof value === 'object')
    .map(value => ({
      value: value.value,
      text: value.text,
    })),
  find: query => Object.values(FreightClassEnum)
    .find(value => typeof value === 'object' && (value.key === query || value.value === query)),
})

export default FreightClassEnum
