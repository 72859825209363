import { ref } from '@vue/composition-api'

export default function useMPitem() {
  const productIdentifiers = ref({
    productIdType: null, // Product Id Type Enum
    productId: null, // String
  })

  const externalProductIdentifiers = ref([])

  const externalProductIdentifier = ref({
    externalProductId: null, // String
    externalProductIdType: null, // External Product Id Type Enum
  })

  const stateRestrictions = ref([])

  const stateRestriction = ref({
    stateRestrictionsText: null, // State Restrictions Text Enum
    states: null, // String
    zipCodes: null, // String
  })

  const additionalProductAttributes = ref([])

  const additionalProductAttribute = ref({
    productAttributeName: null, // String
    productAttributeValue: null, // String
  })

  const additionalOfferAttributes = ref([])

  const additionalOfferAttribute = ref({
    additionalOfferAttributeName: null, // String
    additionalOfferAttributeValue: null, // String
  })

  /* WFSReplenishItem */
  const WFSReplenishItem = ref({
    sku: null, // String
    productIdentifiers: null, // Product Identifiers Object
    price: 0, // BigDecimal
    itemDesc: null, // String
    itemQty: 0, // BigDecimal
    innerPackQty: 0, // BigDecimal
    vendorPackQty: 0, // BigDecimal
    ShippingWeight: 0, // BigDecimal (Shipping Weight (lbs))
    productCategory: null, // Product Category Enum
    externalProductIdentifier: [], // List<External Product Identifier Object>
    stateRestrictions: [], // List<State Restriction Object>
    additionalProductAttributes: [], // List<Additional Product Attribute Object>
    additionalOfferAttributes: [], // List<Additional Offer Attribute Object>
  })

  const MPItem = ref({
    Item: WFSReplenishItem.value, // WFS Replenish Item Object
  })

  /**
   * @param {Object} data
   * @param {String} data.productIdType
   * @param {String} data.productId
   */
  function addExternalProductIdentifier(data) {
    externalProductIdentifier.value = data
    externalProductIdentifiers.push(externalProductIdentifier.value)
  }

  /**
   * @param {Object} data
   * @param {String} data.stateRestrictionsText
   * @param {String} data.states
   * @param {String} data.zipCodes
   */
  function addStateRestrictions(data) {
    stateRestriction.value = data
    stateRestrictions.value.push(stateRestriction.value)
  }

  /**
   * @param {Object} data
   * @param {String} data.productAttributeName
   * @param {String} data.productAttributeValue
   */
  function addAdditionalProductAttribute(data) {
    additionalProductAttribute.value = data
    additionalProductAttributes.value.push(additionalProductAttribute.value)
  }

  /**
   * @param {Object} data
   * @param {String} data.additionalOfferAttributeName
   * @param {String} data.additionalOfferAttributeValue
   */
  function addAdditionalOfferAttribute(data) {
    additionalOfferAttribute.value = data
    additionalOfferAttributes.value.push(additionalOfferAttribute.value)
  }

  /**
   * @param {Object} data
   * @param {String} data.productIdType
   * @param {String} data.productId
   */
  function addProductIdentifiers(data) {
    productIdentifiers.value = data
  }

  function setWFSReplenishItem(sku, price, itemQty, vendorPackQty, innerPackQty, itemDesc, ShippingWeight, productCategory) {
    WFSReplenishItem.value = {
      sku,
      productIdentifiers: productIdentifiers.value,
      price,
      itemQty,
      vendorPackQty,
      innerPackQty,
      itemDesc,
      ShippingWeight,
      productCategory,
      externalProductIdentifier: externalProductIdentifiers.value,
      stateRestrictions: stateRestrictions.value,
      additionalProductAttributes: additionalProductAttributes.value,
      additionalOfferAttributes: additionalOfferAttributes.value,
    }
    MPItem.value = {
      Item: WFSReplenishItem.value,
    }
  }
  return {
    MPItem,
    setWFSReplenishItem,
    addProductIdentifiers,
    addExternalProductIdentifier,
    addStateRestrictions,
    addAdditionalProductAttribute,
    addAdditionalOfferAttribute,
  }
}
