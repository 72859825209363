<template>
  <div class="row">
    <div class="col-12">
      <div class="card mb-1">
        <div class="card-header border-bottom py-1">
          <h4 class="card-title mb-0">
            Shipment Batches
          </h4>
        </div>
        <div class="card-body pt-1 pb-1">
          <div class="row">
            <div class="col-12">
              <dx-data-grid
                id="shipmentPlanGrid"
                ref="shipmentPlanRef"
                :data-source="shipmentBatchDataSource"
                height="auto"
                :cache-eyarnabled="false"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :show-borders="true"
                :row-alternation-enabled="false"
                :hover-state-enabled="true"
                :customize-columns="customizeColumns"
                @initialized="onInitialized"
              >
                <dx-column data-field="batchName" />
                <dx-column data-field="shipmentId" />
                <dx-column data-field="creationTime" caption="Created At" data-type="date" :format="'yyyy-MM-dd HH:mm'" />
                <!-- <dx-column data-field="prepType" /> -->
                <dx-column data-field="skuCount" caption="SKU QTY" alignment="right" />
                <dx-column data-field="itemCount" caption="Item QTY" alignment="right" />
                <dx-column data-field="addedQuantity" caption="Boxed" cell-template="addedQtyTemplate" alignment="center" />
                <template #addedQtyTemplate="{ data }">
                  <div>
                    <span v-if="isBoxingDoneForBatch(data)" title="Ready to Transmit." class="d-block badge badge-success">
                      {{ data.value }}
                    </span>
                    <span v-else title="More items to add!" class="d-block badge badge-light-warning">
                      {{ data.value }}
                    </span>
                  </div>
                </template>
                <dx-column data-field="remainingQuantity" caption="Remaining" alignment="right" />
                <dx-column data-field="status" caption="PSH Status" cell-template="statusCellTemplate" />
                <template #statusCellTemplate="{data}">
                  <div>
                    {{ renderShipmentStatus(data.value).text }}
                  </div>
                </template>
                <dx-column data-field="amzStatus" caption="AMZ Status" cell-template="amzStatusCellTemplate" />
                <template #amzStatusCellTemplate="{data}">
                  <div>
                    {{ renderAmzStatus(data.value).text }}
                  </div>
                </template>
                <dx-column data-field="transportStatus" cell-template="transportStatusCellTemplate" />
                <template #transportStatusCellTemplate="{data}">
                  <div>
                    {{ renderTransporStatus(data.value).text }}
                  </div>
                </template>
                <dx-column cell-template="buttonsCellTemplate" caption="Actions" :width="190" type="buttons" />
                <template #buttonsCellTemplate="{data}">
                  <div>
                    <dx-util-button :disabled="isConfirmed(data.row.data)" type="default" icon="bi bi-box-seam-fill" hint="Items" :element-attr="{class: 'mr-half'}" @click="onClicklShipmentBatchBoxing(data.row.data)" />
                    <dx-util-button type="success" icon="bi bi-truck" hint="Items" @click="onClickShipmentBatchShip(data.row.data)" />
                  </div>
                </template>
              </dx-data-grid>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showBoxingSection" class="card mb-0">
        <div class="card-header border-bottom py-1">
          <h4 class="card-title mb-0">
            <span class="text-warning font-weight-bold">
              {{ selectedShipmentBatchName }}
            </span>
            Shipment Batch Boxing
          </h4>
          <div class="heading-elements">
            <ul class="list-inline">
              <li>
                <dx-util-button type="success" text="Add Box" icon="bi bi-plus" hint="Add Box" @click="onClickAddBox" />
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body pt-1 pb-1">
          <div class="row">
            <div id="drag-zone" class="col-6">
              <div
                v-for="item in shipmentBatchItemsDataSource"
                :key="item.id"
                :draggable="item.quantity !== item.boxedQuantity"
                class="d-flex flex-row text-white px-1 border p-1 rounded mb-1 drag-item"
                @dragstart="onItemDragStart($event, item)"
                @dragend="onItemDragEnd($event)"
              >
                <div class="flex-grow-1">
                  <item-info :item="item" :item-info-visible="false" />
                </div>
                <div title="Quantity" class="align-self-center rounded px-half badge-item mr-1" style="width: 96px;" :class="item.quantity !== item.boxedQuantity ? 'bg-secondary': 'bg-success'">
                  {{ item.boxedQuantity }} / {{ item.quantity }}
                </div>
                <div class="align-self-center">
                  <dx-util-button
                    type="default"
                    hint="Edit Quantity"
                    icon="bi bi-pencil-square"
                    @click="onClickEditShipmentBatchItemQuantity(item)"
                  />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div id="drop-zone" class="d-flex flex-row" :class="{'drop-zone-zero':!startDrag, 'mb-1': startDrag}">
                <transition name="fade" mode="out-in">
                  <div
                    v-if="startDrag"
                    id="drop-box-and-all"
                    class="drop-box-option"
                    :class="{ 'drop-box-option-hover': overDropZoneBoxAll }"
                    @drop="onDropBoxAll"
                    @dragover="onDragOverBoxAll"
                    @dragleave="overDropZoneBoxAll = false"
                  >
                    Create Box And Drop All
                  </div>
                </transition>
                <transition name="fade" mode="out-in">
                  <div
                    v-if="startDrag"
                    class="drop-box-option ml-1"
                    :class="{ 'drop-box-option-hover': overDropZoneBoxExisting }"
                    @drop="onDropBoxExisting"
                    @dragover="onDragOverBoxExisting"
                    @dragleave="overDropZoneBoxExisting = false"
                  >
                  Add Items Existing Box
                  </div>
                </transition>
                <transition name="fade" mode="out-in">
                  <div
                    v-if="startDrag"
                    class="drop-box-option mx-1"
                    :class="{ 'drop-box-option-hover': overDropZoneBoxSome }"
                    @drop="onDropBoxSome"
                    @dragover="onDragOverBoxSome"
                    @dragleave="overDropZoneBoxSome = false"
                  >
                    Create Box And Drop Some
                  </div>
                </transition>
                <transition name="fade" mode="out-in">
                  <div
                    v-if="startDrag"
                    class="drop-box-option"
                    :class="{ 'drop-box-option-hover': overDropZoneBoxMultipack }"
                    @drop="onDropBoxMultipack"
                    @dragover="onDragOverBoxMultipack"
                    @dragleave="overDropZoneBoxMultipack = false"
                  >
                  Create Multiple Boxes
                  </div>
                </transition>
              </div>
              <div id="boxes">
                  <div v-for="box in boxes" :key="box.id" class="list-group mb-1">
                    <div class="list-group-item d-flex justify-content-start align-items-center">
                      <span class="icon-transition" @click="getBoxItems(box.id)">
                        <i class="bi bi-chevron-double-right font-weight-bold font"></i>
                      </span>
                      <span class="flex-grow-1">
                        {{ box.boxName }}
                      </span>
                      <div title="Quantity" class="align-self-center bg-primary rounded px-half badge-item" style="width: 48px;">
                        {{ box.itemQuantity }}
                      </div>
                      <div class="d-flex flex-row col-6">
                        <dx-util-number-box
                          v-model="box.length"
                          label="Length"
                          label-mode="floating"
                          height="36px"
                          :min="0"
                          :max="9999"
                          :show-spin-buttons="false"
                          :element-attr="{ class: 'mr-1' }"
                        />
                        <dx-util-number-box
                          v-model="box.width"
                          label="Width"
                          label-mode="floating"
                          height="36px"
                          :min="0"
                          :max="9999"
                          :show-spin-buttons="false"
                          :element-attr="{ class: 'mr-1' }"
                        />
                        <dx-util-number-box
                          v-model="box.height"
                          label="Height"
                          label-mode="floating"
                          height="36px"
                          :min="0"
                          :max="9999"
                          :show-spin-buttons="false"
                          :element-attr="{ class: 'mr-1' }"
                        />
                        <dx-util-number-box
                          v-model="box.weight"
                          label="Weight"
                          label-mode="floating"
                          height="36px"
                          :min="0"
                          :max="9999"
                          :show-spin-buttons="false"
                        />
                      </div>
                      <dx-util-button-group
                        :items="boxActionButtons"
                        key-expr="event"
                        selection-mode="none"
                        styling-mode="contained"
                        @item-click="boxActionClickEvent($event, box)"
                      />
                    </div>
                    <div v-if="box.boxItems.length > 0 || showBoxCollapse" class="list-group-item px-0 pb-0">
                      <div class="list-group">
                        <div class="list-group-item d-flex justify-content-start align-items-center">
                          <dx-util-text-box
                            :placeholder="'Scan ' + box.boxName + ' items'"
                            :element-attr="{ class: 'flex-grow-1' }"
                            :show-clear-button="true"
                            mode="search"
                            @key-down="onBoxItemSearchTextKeyDown($event, box)"
                          />
                        </div>
                        <div v-for="item in box.boxItems" :key="item.id" class="list-group-item d-flex justify-content-start align-items-center">
                          <div title="Quantity" class="align-self-center bg-primary rounded px-half badge-item font-weight-bolder mr-1" style="width: 32px; height: 32px;">
                            {{ item.quantity }}
                          </div>
                          <div class="flex-grow-1">
                            <div class="d-flex flex-column">
                              <span class="font-weight-bold">
                                {{ item.title }}
                              </span>
                              <div class="d-flex flex-row">
                                <span class="mr-1">
                                <span class="font-weight-bold">Barcode: </span>{{ item.upc }}
                                </span>
                                <span>
                                  <span class="font-weight-bold">MSKU: </span>{{ item.msku }}
                                </span>
                              </div>
                            </div>
                          </div>
                            <dx-util-number-box
                              v-model="item.quantity"
                              :min="0"
                              :max="9999"
                              :show-spin-buttons="false"
                              width="96px"
                              :element-attr="{ class: 'mr-1' }"
                              @key-down="onBoxItemQuantityUpdateKeyDown($event, item, box)"
                            >
                              <dx-number-box-button
                                :name="`box-item-quantity-${item.id}`"
                                :options="{
                                  icon: 'bi bi-save',
                                  type: 'normal',
                                  hint: 'Update',
                                  elementAttr: { id: `box-item-quantity-${item.id}` },
                                  onClick: (e) => onBoxItemQuantityUpdate(e, item, box)
                                }"
                                location="after"
                              />
                            </dx-util-number-box>
                          <dx-util-button-group
                            :items="boxItemActionButtons"
                            key-expr="event"
                            selection-mode="none"
                            styling-mode="contained"
                            @item-click="boxItemActionClickEvent($event, item, box)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showShipSection" class="card mb-0">
        <div class="card-header border-bottom py-1">
          <h4 class="card-title mb-0">
            Shipping Detais
          </h4>
        </div>
        <div class="card-body pt-1 pb-1">
          <ul class="nav nav-pills nav-fill">
            <li class="nav-item">
              <button
                id="shipping-items-tab"
                class="nav-link w-100"
                type="button"
                role="tab"
                :class="{ 'show active': activeTab === 'shipping-items' }"
                @click="changeShippingTab('shipping-items')"
              >
                Items
              </button>
            </li>
            <li class="nav-item">
              <button
                id="shipping-shipping-tab"
                class="nav-link w-100"
                type="button"
                role="tab"
                :class="{ 'show active': activeTab === 'shipping-shipping' }"
                @click="changeShippingTab('shipping-shipping')"
              >
               Shipping
              </button>
            </li>
            <li class="nav-item">
              <button
                id="shipping-charges-tab"
                class="nav-link w-100"
                type="button"
                role="tab"
                :class="{ 'show active': activeTab === 'shipping-charges' }"
                @click="changeShippingTab('shipping-charges')"
              >
                Extra Charge
              </button>
            </li>
          </ul>
          <div id="shippingTabContent" class="tab-content">
            <div
              id="shipping-items"
              class="card tab-pane fade"
              :class="{ 'show active': activeTab === 'shipping-items' }"
              role="tabpanel"
            >
              <div class="card-header border-bottom py-1 px-0">
                <div class="d-flex flex-row align-items-center">
                  <h4 class="card-title mr-1">
                    Item Details
                  </h4>
                  <h6 class="card-subtitle text-muted my-0">
                    Review your items for this shipment. You can edit the quantity of each item.
                  </h6>
                </div>
              </div>
              <div class="card-body px-0">
                <dx-data-grid
                  id="shippingItemsGrid"
                  ref="shippingItemsGridRef"
                  :data-source="shipmentBatchItemsDataSource"
                  height="auto"
                  :cache-eyarnabled="false"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :show-borders="true"
                  :row-alternation-enabled="false"
                  :customize-columns="customizeColumns"
                >
                  <dx-column data-field="title" caption="Item" />
                  <dx-column data-field="upc" caption="Barcode" />
                  <dx-column data-field="msku" caption="SKU" />
                  <dx-column data-field="quantity" caption="Qty" alignment="left" />
                </dx-data-grid>
              </div>
            </div>
            <div
              id="shipping-shipping"
              class="tab-pane card fade"
              :class="{ 'show active': activeTab === 'shipping-shipping' }"
              role="tabpanel"
            >
              <div class="card-header border-bottom py-1 px-0">
                <div class="d-flex flex-row align-items-center w-100">
                  <h4 class="card-title mr-1">
                    Shipping Details
                  </h4>
                  <h6 class="card-subtitle text-muted my-0 flex-grow-1">
                    Tell us how you want to ship your items to Walmart.
                  </h6>
                  <div>
                    <dx-util-button
                      type="default"
                      icon="bi bi-box-arrow-down"
                      text="Download"
                      hint="Download Label"
                      width="120px"
                      class="mr-1"
                      @click="onClickDownloadLabel"
                    />
                  <dx-util-button
                    type="danger"
                    icon="bi bi-x-circle"
                    text="Void"
                    hint="Void Label"
                    width="120px"
                    :disabled="isConfirmedShipment"
                    @click="onClickVoidCarrierRateQuote"
                  />
                  </div>
                </div>
              </div>
              <div class="card-body px-0">
                <form class="form mt-1">
                  <div class="row match-height">
                    <div class="col-md-6 align-items-stretch">
                      <fieldset class="border px-2 py-1">
                        <legend class="w-auto fs-h-5 text-warning">
                          Carrier Options
                        </legend>
                        <div class="form-row">
                          <div class="form-group col-md-12">
                            Choose the Preferred Carrier Program for reduced shipping rates, or use your own carrier.
                          </div>
                          <div class="form-group col-md-6 mb-0">
                            <dx-util-select-box
                              v-model="shippingForm.carrierType"
                              :data-source="carrierTypes"
                              display-expr="text"
                              value-expr="value"
                              label="Carrier"
                              label-mode="floating"
                            />
                          </div>
                          <div class="form-group col-md-6 mb-0">
                            <dx-util-select-box
                              v-model="shippingForm.freightType"
                              :data-source="freightTypes"
                              display-expr="text"
                              value-expr="value"
                              label="Frieght Type"
                              label-mode="floating"
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div class="col-md-6 align-items-stretch">
                      <fieldset class="border px-2 py-1 h-100">
                        <legend class="w-auto fs-h-5 text-warning">
                          Ship To
                        </legend>
                        <div class="form-row">
                          <div class="form-group col-md-12">
                            {{ shippedToAddress }}
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div class="row mt-1 match-height">
                    <div class="col-md-6 align-items-stretch">
                      <fieldset v-if="isCarrierSmallParcel || isCarrierLTL" class="border px-2 py-1 h-100">
                        <legend class="w-auto fs-h-5 text-warning">
                          Ship From
                        </legend>
                        <div class="form-row">
                          <div class="form-group col-md-12" style="min-height: 40px;">
                            We'll return undeliverable shipments to this address.
                          </div>
                          <div class="form-group col-md-12 mb-0">
                            <dx-util-select-box
                              v-model="shippingForm.originLocationId"
                              :data-source="fromAddresses"
                              :show-clear-button="true"
                              display-expr="text"
                              value-expr="id"
                              label="Address"
                              label-mode="floating"
                              @value-changed="onFromAddressChanged"
                            >
                            <template #item="{ data }">
                              <div v-html="data.textHtml"></div>
                            </template>
                            </dx-util-select-box>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div class="col-md-6">
                      <fieldset v-if="isCarrierLTL" class="border px-2 py-1  align-items-stretch">
                        <legend class="w-auto fs-h-5 text-warning">
                          Pickup Details
                        </legend>
                        <div class="form-row">
                          <div class="form-group col-md-12">
                            <dx-util-date-box
                              v-model="shippingForm.pickupDate"
                              :min="today"
                              label="Freight Ready Date"
                              label-mode="floating"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <dx-util-text-box
                              v-model="shippingForm.contactDetails.firstName"
                              label="First Name"
                              label-mode="floating"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <dx-util-text-box
                              v-model="shippingForm.contactDetails.lastName"
                              label="Last Name"
                              label-mode="floating"
                            />
                          </div>
                          <div class="form-group col-md-6 mb-0">
                            <dx-util-text-box
                              v-model="shippingForm.contactDetails.email"
                              label="Email"
                              label-mode="floating"
                            />
                          </div>
                          <div class="form-group col-md-6 mb-0">
                            <dx-util-text-box
                              v-model="shippingForm.contactDetails.phone"
                              label="Phone Number"
                              label-mode="floating"
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div class="col-md-12">
                      <fieldset v-if="isCarrierSmallParcel" class="border px-2 py-1 mt-1">
                        <legend class="w-auto fs-h-5 text-warning">
                        Master Case Details
                        </legend>
                        <div class="form-row">
                          <div class="form-group col-md-12">
                            <div class="d-flex flex-row w-100">
                              <span class="flex-grow-1">
                              For FedEx small parcel, you can ship packages up to 150 lb, 108" in length, and 165" in length plus girth (L+2W+2H).
                            </span>
                              <dx-util-button
                                  type="default"
                                  icon="bi bi-calculator"
                                  text="Calculate"
                                  hint="Calculate"
                                  @click="onClickCreateCarrierQuote"
                                />
                            </div>
                          </div>
                          <div class="form-group col-md-12">
                           <table class="table table-bordered">
                             <thead>
                               <tr>
                                <th>Length (in)</th>
                                <th>Width (in)</th>
                                <th>Height (in)</th>
                                <th>Weight (lb)</th>
                                <th>Master Case</th>
                                <th>SKU Type</th>
                                <th style="width: 124px;">
                                  <dx-util-button
                                    type="default"
                                    icon="bi bi-plus"
                                    text="Add"
                                    hint="Add"
                                    width="100%"
                                    @click="onClickAddSpShipmentPackage"
                                  />
                                </th>
                               </tr>
                             </thead>
                             <tbody>
                               <tr v-for="(shipmentPackage,index) in shippingForm.spShipmentPackages" :key="index">
                                 <td>
                                    <dx-util-number-box
                                      v-model="shipmentPackage.length"
                                      :min="0"
                                      :max="9999"
                                      :show-spin-buttons="false"
                                    />
                                 </td>
                                  <td>
                                      <dx-util-number-box
                                        v-model="shipmentPackage.width"
                                        :min="0"
                                        :max="9999"
                                        :show-spin-buttons="false"
                                      />
                                  </td>
                                  <td>
                                      <dx-util-number-box
                                        v-model="shipmentPackage.height"
                                        :min="0"
                                        :max="9999"
                                        :show-spin-buttons="false"
                                      />
                                  </td>
                                  <td>
                                      <dx-util-number-box
                                        v-model="shipmentPackage.weight"
                                        :min="0"
                                        :max="150"
                                        :show-spin-buttons="false"
                                      />
                                  </td>
                                  <td>
                                    <dx-util-number-box
                                      v-model="shipmentPackage.numberOfCases"
                                      :min="0"
                                      :max="9999"
                                      :show-spin-buttons="false"
                                    />
                                  </td>
                                  <td>
                                    <dx-util-select-box
                                      v-model="shipmentPackage.packageContent"
                                      :data-source="packageContentTypes"
                                      display-expr="text"
                                      value-expr="value"
                                    />
                                  </td>
                                  <td class="text-center">
                                    <dx-util-button
                                      type="default"
                                      icon="bi bi-printer"
                                      hint="Edit"
                                      class="mr-half"
                                      @click="onClickDownloadShippingLabel(index)"
                                    />
                                    <dx-util-button
                                      type="danger"
                                      icon="bi bi-trash"
                                      hint="Delete"
                                      @click="onClickDeleteSpShipmentPackage(index)"
                                    />
                                  </td>
                                </tr>
                             </tbody>
                            </table>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset v-if="isCarrierLTL" class="border px-2 py-1 mt-1">
                        <legend class="w-auto fs-h-5 text-warning">
                        Pallet Details
                        </legend>
                        <div class="row">
                          <div class="form-group col-md-2">
                            <dx-util-number-box
                              v-model="shippingForm.ltlShipmentPackageInfo.singleSku"
                              label="Number of Single SKU Master Cases"
                              :min="0"
                              :max="9999"
                              :show-spin-buttons="false"
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <dx-util-number-box
                              v-model="shippingForm.ltlShipmentPackageInfo.mixedSku"
                              label="Number of Multiple SKU Master Cases"
                              :min="0"
                              :max="9999"
                              :show-spin-buttons="false"
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <dx-util-select-box
                              v-model="shippingForm.ltlShipmentPackageInfo.freightClass"
                              :data-source="freightClass"
                              display-expr="text"
                              value-expr="value"
                              label="Pallet Type"
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <dx-util-number-box
                              v-model="shippingForm.ltlShipmentPackageInfo.declaredValue"
                              label="Declared Value"
                              :min="0"
                              :max="9999"
                              format="$ #,##0.##"
                              :show-spin-buttons="false"
                            />
                          </div>
                          <div class="col-md-4 text-right">
                            <dx-util-button
                              type="default"
                              icon="bi bi-calculator"
                              text="Calculate"
                              hint="Calculate"
                              width="120px"
                              @click="onClickCreateCarrierQuote"
                            />
                          </div>
                          <div class="col-md-12">
                           <table class="table table-bordered">
                             <thead>
                               <tr>
                                <th>Length (in)</th>
                                <th>Width (in)</th>
                                <th>Height (in)</th>
                                <th>Weight (lb)</th>
                                <th>Pallets </th>
                                <th>Total Weight (lb)</th>
                                <th>Stackable</th>
                                <th style="width: 72px;">
                                  <dx-util-button
                                    type="default"
                                    icon="bi bi-plus"
                                    text="Add"
                                    hint="Add"
                                    @click="onClickAddLTLPallet"
                                  />
                                </th>
                               </tr>
                             </thead>
                             <tbody>
                               <tr v-for="(pallet,index) in shippingForm.ltlShipmentPackages" :key="index">
                                <td>
                                    <dx-util-number-box
                                      v-model="pallet.length"
                                      :read-only="true"
                                      :min="0"
                                      :max="9999"
                                      :show-spin-buttons="false"
                                    >
                                     <dx-number-box-button
                                        :name="`pallet-length-${index}`"
                                        :options="{
                                          text: 'Maximum Inches',
                                          type: 'normal',
                                          elementAttr: { id: `pallet-length-${index}`, disabled: true },
                                        }"
                                        location="after"
                                      />
                                    </dx-util-number-box>
                                 </td>
                                 <td>
                                    <dx-util-number-box
                                      v-model="pallet.width"
                                      :read-only="true"
                                      :min="0"
                                      :max="9999"
                                      :show-spin-buttons="false"
                                    >
                                      <dx-number-box-button
                                        :name="`pallet-width-${index}`"
                                        :options="{
                                          text: 'Maximum Inches',
                                          type: 'normal',
                                          elementAttr: { id: `pallet-width-${index}`, disabled: true },
                                        }"
                                        location="after"
                                      />
                                    </dx-util-number-box>
                                 </td>
                                 <td>
                                    <dx-util-number-box
                                      v-model="pallet.height"
                                      :min="0"
                                      :max="9999"
                                      :show-spin-buttons="false"
                                    />
                                 </td>
                                  <td>
                                      <dx-util-number-box
                                        v-model="pallet.weight"
                                        :min="0"
                                        :max="9999"
                                        :show-spin-buttons="false"
                                      />
                                  </td>
                                  <td>
                                      <dx-util-number-box
                                        v-model="pallet.numberOfPallet"
                                        :min="0"
                                        :max="9999"
                                        :show-spin-buttons="false"
                                      />
                                  </td>
                                  <td>
                                      <dx-util-number-box
                                        :value="(pallet.numberOfPallet * pallet.weight)"
                                        :disabled="true"
                                        :show-spin-buttons="false"
                                      />
                                  </td>
                                  <td>
                                    <dx-util-select-box
                                      v-model="pallet.isStackable"
                                      :data-source="stackableTypes"
                                      display-expr="text"
                                      value-expr="value"
                                    />
                                  </td>
                                  <td class="text-center">
                                    <dx-util-button
                                      type="danger"
                                      icon="bi bi-trash"
                                      hint="Delete"
                                      width="100%"
                                      @click="onClickDeleteLTLPallet(index)"
                                    />
                                  </td>
                                </tr>
                             </tbody>
                            </table>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset v-if="isNotCarrierSmallParcel" class="border px-2 py-1 mt-1">
                        <legend class="w-auto fs-h-5 text-warning">
                        Master Case Details
                        </legend>
                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <dx-util-number-box
                              v-model="shippingForm.noCarrierSmallParcel.singleSku"
                              label="Number of Single SKU Master Cases"
                              :min="0"
                              :max="9999"
                              :show-spin-buttons="false"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <dx-util-number-box
                              v-model="shippingForm.noCarrierSmallParcel.mixedSku"
                              label="Number of Multiple SKU Master Cases"
                              :min="0"
                              :max="9999"
                              :show-spin-buttons="false"
                            />
                          </div>
                        </div>
                      </fieldset>
                      <fieldset v-if="isNotCarrierLTL" class="border px-2 py-1 mt-1">
                        <legend class="w-auto fs-h-5 text-warning">
                        Pallet Details
                        </legend>
                        <div class="form-row">
                          <div class="form-group col-md-4">
                            <dx-util-number-box
                              v-model="shippingForm.noCarrierLtl.pallets"
                              label="Number of Single SKU Master Cases"
                              :min="0"
                              :max="9999"
                              :show-spin-buttons="false"
                            />
                            </div>
                          <div class="form-group col-md-4">
                            <dx-util-number-box
                              v-model="shippingForm.noCarrierLtl.singleSku"
                              label="Number of Single SKU Master Cases"
                              :min="0"
                              :max="9999"
                              :show-spin-buttons="false"
                            />
                          </div>
                          <div class="form-group col-md-4">
                            <dx-util-number-box
                              v-model="shippingForm.noCarrierLtl.mixedSku"
                              label="Number of Multiple SKU Master Cases"
                              :min="0"
                              :max="9999"
                              :show-spin-buttons="false"
                            />
                          </div>
                        </div>
                      </fieldset>
                      <fieldset v-if="showRateInfo" class="border px-2 py-1 mt-1">
                        <legend class="w-auto fs-h-5 text-warning">
                        Rate Info
                        </legend>
                        <div class="form-row">
                          <div class="form-group col-md-12">
                            <div class="d-flex flex-row w-100">
                              <span class="flex-grow-1">
                                Choose the Preferred Carrier Program for reduced shipping rates, or use your own carrier.
                              </span>
                              <dx-util-button
                                type="warning"
                                icon="bi bi-check2-all"
                                text="Confirm"
                                hint="Confirm"
                                @click="confirmCarrierRateQuote"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3">
                            Carrier:
                          </div>
                          <div class="col-9 font-weight-bolder">
                            {{ rateInfo.rateQuotes.carrier.carrierName }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3">
                            Estimated delivery date:
                          </div>
                          <div class="col-9 font-weight-bolder">
                            {{ rateInfo.rateQuotes.estimatedDeliveryDateTime | formatDate }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3">
                            Estimated shipping price:
                          </div>
                          <div class="col-9 font-weight-bolder">
                            ${{ rateInfo.rateQuotes.netCharge }}
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              id="shipping-charges"
              class="tab-pane fade"
              :class="{ 'show active': activeTab === 'shipping-charges' }"
              role="tabpanel"
            >
              <div class="row">
                <div class="col-12">
                  <charge-item v-if="reloadChargeComp" :item-id="selectedShipmentBatchId" :product-id="selectedShipmentBatchId" :is-item-id="true" :is-selected-type="true" />
                </div>
                <div class="col-2 offset-10 px-half">
                  <dx-util-button
                    icon="save"
                    text="Save Charges"
                    type="success"
                    class="mt-1"
                    width="100%"
                    @click="addCharges"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <dx-util-popup
      ref="popupBoxExistingRef"
      title="Boxing Items"
      :width="640"
      :height="480"
      :show-close-button="true"
      :show-title="true"
      :visible="showDropZoneBoxExistingPopup"
      :drag-enabled="true"
      :close-on-click-outside="true"
      :close-on-escape="true"
      content-template="popup-content"
      @hiding="onHidingBoxExistingPopup"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item toolbar="bottom" location="after" template="cancelPopupButton" />
      <template #cancelPopupButton>
        <dx-util-button id="popupBoxExistingCancelButton" text="Cancel" type="danger" @click="showDropZoneBoxExistingPopup = false" />
      </template>
      <dx-util-toolbar-item toolbar="bottom" location="before" template="submitPopupButton" />
      <template #submitPopupButton>
        <dx-util-button id="popupBoxExistingSubmitButton" text="Save" type="success" @click="onClickSaveBoxItem" />
      </template>
      <template #popup-content>
        <div>
          <div class="row">
            <div class="col-md-12 display-h-5 mb-2 text-primary">
              {{ draggedItem.title }}
            </div>
            <div class="col-md-12">
              <dx-util-select-box
                v-model="boxItem.boxId"
                :data-source="boxes"
                display-expr="boxName"
                value-expr="id"
                label="Box"
                label-mode="floating"
                height="36px"
                class="mb-2"
                >
              </dx-util-select-box>
              <dx-util-number-box
                v-model="boxItem.quantity"
                label="Quantity"
                label-mode="floating"
                height="36px"
                :min="1"
                :max="boxItem.quantity"
                :show-spin-buttons="false"
                >
              </dx-util-number-box>
            </div>
          </div>
        </div>
      </template>
    </dx-util-popup>
    <dx-util-popup
      ref="popupBoxSomeItemRef"
      title="Create Box And Drop Some Items"
      :width="640"
      :height="480"
      :show-close-button="true"
      :show-title="true"
      :visible="showDropZoneBoxSomeItemPopup"
      :drag-enabled="true"
      :close-on-click-outside="true"
      :close-on-escape="true"
      content-template="popup-content"
      @hiding="onHidingBoxSomeItemPopup"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item toolbar="bottom" location="after" template="cancelPopupButton" />
      <template #cancelPopupButton>
        <dx-util-button id="popupBoxSomeItemCancelButton" text="Cancel" type="danger" @click="showDropZoneBoxSomeItemPopup = false" />
      </template>
      <dx-util-toolbar-item toolbar="bottom" location="before" template="submitPopupButton" />
      <template #submitPopupButton>
        <dx-util-button id="popupBoxSomeItemSubmitButton" text="Save" type="success" @click="onClickSaveBoxSomeItem" />
      </template>
      <template #popup-content>
        <div>
          <div class="row">
            <div class="col-md-12 display-h-5 mb-2 text-primary">
              {{ draggedItem.title }}
            </div>
            <div class="col-md-12">
              <dx-util-number-box
                v-model="dropItem.assignmentQuantity"
                label="Quantity"
                label-mode="floating"
                height="36px"
                :element-attr="{ class: 'mb-1' }"
                :min="1"
                :show-spin-buttons="false"
                >
              </dx-util-number-box>
              <div class="mb-1">
                <a href="javascript:void(0)" class="text-primary" @click="showEnterBoxDimensions = !showEnterBoxDimensions">Enter Box Dimensions</a>
              </div>
                <div v-show="showEnterBoxDimensions">
                  <div class="row">
                    <div class="col-md-6">
                      <dx-util-number-box
                        v-model="dropItem.length"
                        label="Length"
                        label-mode="floating"
                        height="36px"
                        :min="0"
                        :max="9999"
                        :show-spin-buttons="false"
                        :element-attr="{ class: 'mb-1' }"
                      />
                    </div>
                    <div class="col-md-6">
                      <dx-util-number-box
                          v-model="dropItem.width"
                          label="Width"
                          label-mode="floating"
                          height="36px"
                          :min="0"
                          :max="9999"
                          :show-spin-buttons="false"
                          :element-attr="{ class: 'mb-1' }"
                        />
                    </div>
                    <div class="col-md-6">
                      <dx-util-number-box
                        v-model="dropItem.height"
                        label="Height"
                        label-mode="floating"
                        height="36px"
                        :min="0"
                        :max="9999"
                        :show-spin-buttons="false"
                        :element-attr="{ class: 'mb-1' }"
                      />
                    </div>
                    <div class="col-md-6">
                      <dx-util-number-box
                          v-model="dropItem.weight"
                          label="Weight"
                          label-mode="floating"
                          height="36px"
                          :min="0"
                          :max="9999"
                          :show-spin-buttons="false"
                          :element-attr="{ class: 'mb-1' }"
                        />
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </template>
    </dx-util-popup>
    <dx-util-popup
      ref="popupBoxMultipackRef"
      title="Create Multiple Boxes And Drop Items"
      :width="640"
      :height="480"
      :show-close-button="true"
      :show-title="true"
      :visible="showDropZoneBoxMultipackPopup"
      :drag-enabled="false"
      :close-on-click-outside="true"
      :close-on-escape="true"
      :scrolling-enabled="true"
      content-template="popup-content"
      @hiding="onHidingBoxMultipackPopup"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item toolbar="bottom" location="after" template="cancelPopupButton" />
      <template #cancelPopupButton>
        <dx-util-button id="popupBoxMultipackCancelButton" text="Cancel" type="danger" @click="showDropZoneBoxMultipackPopup = false" />
      </template>
      <dx-util-toolbar-item toolbar="bottom" location="before" template="submitPopupButton" />
      <template #submitPopupButton>
        <dx-util-button id="popupBoxMultipackSubmitButton" text="Save" type="success" @click="onClickSaveBoxMultipackItem" />
      </template>
      <template #popup-content>
        <div>
          <div class="row">
            <div class="col-md-12 display-h-5 mb-2 text-primary">
              {{ draggedItem.title }}
            </div>
            <div class="col-md-12">
              <dx-util-switch
                :value="false"
                switched-on-text="Create Multiple Boxes By Box Count"
                switched-off-text="Create Multiple Boxes By Item Count"
                class="mb-2 w-100"
                @value-changed="onChangeMultipackSwitcher($event)"
              />
              <dx-util-number-box
                v-model="dropItem.assignmentQuantity"
                :label="multipleQuantityText"
                label-mode="floating"
                height="36px"
                :element-attr="{ class: 'mb-1' }"
                :min="1"
                :show-spin-buttons="false"
                >
              </dx-util-number-box>
              <div class="mb-1">
                <a href="javascript:void(0)" class="text-primary" @click="showEnterBoxDimensions = !showEnterBoxDimensions">Enter Box Dimensions</a>
              </div>
                <div v-show="showEnterBoxDimensions">
                  <div class="row">
                    <div class="col-md-6">
                      <dx-util-number-box
                        v-model="dropItem.length"
                        label="Length"
                        label-mode="floating"
                        height="36px"
                        :min="0"
                        :max="9999"
                        :show-spin-buttons="false"
                        :element-attr="{ class: 'mb-1' }"
                      />
                    </div>
                    <div class="col-md-6">
                      <dx-util-number-box
                          v-model="dropItem.width"
                          label="Width"
                          label-mode="floating"
                          height="36px"
                          :min="0"
                          :max="9999"
                          :show-spin-buttons="false"
                          :element-attr="{ class: 'mb-1' }"
                        />
                    </div>
                  <div class="col-md-6">
                      <dx-util-number-box
                        v-model="dropItem.height"
                        label="Height"
                        label-mode="floating"
                        height="36px"
                        :min="0"
                        :max="9999"
                        :show-spin-buttons="false"
                        :element-attr="{ class: 'mb-1' }"
                      />
                    </div>
                    <div class="col-md-6">
                      <dx-util-number-box
                          v-model="dropItem.weight"
                          label="Weight"
                          label-mode="floating"
                          height="36px"
                          :min="0"
                          :max="9999"
                          :show-spin-buttons="false"
                          :element-attr="{ class: 'mb-1' }"
                        />
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </template>
    </dx-util-popup>
    <!--begin:: Update Shipment Plan Item -->
    <dx-util-popup
        ref="popupUpdateShipmentBatchItemQuantityRef"
        :show-close-button="true"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-title="true"
        :full-screen="true"
        title="Update Shipment Batch Item Quantity"
        content-template="popup-content"
        @shown="onShownUpdateShipmentBatchItemQuantityPopup"
        @hidden="onHiddenUpdateShipmentBatchItemQuantityPopup"
      >
        <dx-util-position at="center" my="center" />
        <template #popup-content>
          <dx-util-scroll-view width="100%" height="100%">
            <div>
              <update-shipment-plan-item-quantity
                v-if="renderUpdateShipmentBatchItemQuantity"
                :shipment-plan-item-id="editableShipmentBatchItemId"
                :barcode="editableShipmentBatchItemBarcode"
                @emited-close-update="onEmitCloseEditShipmentBatchItemQuantityPopup"
              />
            </div>
          </dx-util-scroll-view>
        </template>
    </dx-util-popup>
    <!--end:: Update Shipment Plan Item -->
  </div>
</template>
<script>
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import moment from 'moment'
import wfsService from '@/http/requests/wfs'
import commonEntityService from '@/http/requests/common/commonEntityService'
import useCurrentUser from '@/libs/app/current-user'
import {
  computed, onMounted, ref, watch, nextTick,
} from '@vue/composition-api'
import { Notify } from '@robustshell/utils/index'
import { updateCountryCode } from '@/libs/utils'
import itemChargeService from '@/http/requests/finance/itemChargeService'
import store from '@/store'
import shipmentStatusEnum, { getShipmentStatusEnum } from '@/enums/shipmentStatusEnum'
import tenantService from '@/http/requests/tenant/tenan.settings'
import managedKeysEnum from '@/enums/managedKeysEnum'
import valueTypeEnum from '@/enums/valueTypeEnum'
import scopeTypeEnum from '@/enums/scopeTypeEnum'
import { getAmzShipmentStatusEnum } from '@/enums/amzShipmentStatusEnum'
import { getTransportStatusEnum } from '@/enums/transportStatusEnum'
import ItemActionTypeEnum from '@/enums/itemActionTypeEnum'
import router from '@/router'
import useDownloadLabel from '@/libs/printer/label-download'
import { DxButton as DxNumberBoxButton } from 'devextreme-vue/text-box'
import ChargeItem from '@components/charge/TheChargeItem.vue'
import ItemInfo from './ItemInfo.vue'
import UpdateShipmentPlanItemQuantity from './UpdateShipmentPlanItemQuantity.vue'
import WFSCarrierTypeEnum, { WFSFreightTypeEnum } from '../consts/wfs-carrier-type.enum.js'
import FreightClassEnum from '../consts/freight-class.enum.js'
import StatusEnum from '../consts/stackable.enum.js'

const boxActionEnum = Object.freeze({
  PRINT: {
    event: 'print',
    text: 'Print Label',
  },
  SCAN: {
    event: 'scan',
    text: 'Scan Box',
  },
  UPDATE: {
    event: 'update',
    text: 'Update Dimensions',
  },
  DELETE: {
    event: 'delete',
    text: 'Delete Box',
  },
})

const boxItemActionEnum = Object.freeze({
  PRINT: {
    event: 'print',
    text: 'Print Label',
  },
  DELETE: {
    event: 'delete',
    text: 'Delete Box',
  },
})

const assingmentTypeEnum = Object.freeze({
  CreateSingleAssignAll: {
    value: 'createSingleAssignAll',
    text: 'Create Single Assign All',
  },
  CreateSingleAssignSome: {
    value: 'createSingleAssignSome',
    text: 'Create Single Assign Some',
  },
  CreateMultipleAssignAllBox: {
    value: 'createMultipleAssignAllBox',
    text: 'Create Multiple Assign All Box',
  },
  CreateMultipleAssignAllItem: {
    value: 'createMultipleAssignAllItem',
    text: 'Create Multiple Assign All Item',
  },
})

const transportStatusEnum = Object.freeze({
  WORKING: {
    value: 'WORKING',
    text: 'Working',
  },
  CONFIRMED: {
    value: 'CONFIRMED',
    text: 'Confirmed',
  },
})

export default {
  components: {
    DxNumberBoxButton,
    ItemInfo,
    ChargeItem,
    UpdateShipmentPlanItemQuantity,
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
    storeId: {
      type: Number,
      required: true,
    },
    shipmentPlanId: {
      type: Number,
      required: true,
    },
    planStatus: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      companyId, storeId, shipmentPlanId, planStatus,
    } = props

    const {
      downloadWalmartShippingLabel,
    } = useDownloadLabel()

    const { userWarehouseId } = useCurrentUser()
    const selectedShipmentBatchId = ref(null)
    const selectedShipmentBatchName = ref(null)
    const shipmentBatchDataSource = ref([])
    // #region Shipment Batch Items
    const shipmentBatchItemsDataSource = ref([])
    const showBoxingSection = ref(false)
    const showShipSection = ref(false)
    // #endregion
    const accountNo = computed(() => router.currentRoute.params.accountNo)
    function onInitialized(e) {
      e.component.option('loadPanel.enabled', false)
    }
    function customizeColumns(columns) {
      columns.forEach(column => {
        column.cssClass = 'dx-column-vertical-middle'
      })
    }
    function isDisabledAction(row, key) {
      const { data } = row
      const rowStatus = getShipmentStatusEnum(data.status)
      return !key.includes(rowStatus.key)
    }
    function isBoxingDoneForBatch(data) {
      return data.data.addedQuantity === data.data.itemCount
    }
    function renderShipmentStatus(status) {
      return getShipmentStatusEnum(status)
    }
    function renderAmzStatus(status) {
      const result = getAmzShipmentStatusEnum(status)
      return result
    }
    function renderTransporStatus(status) {
      const result = getTransportStatusEnum(status)
      return result
    }
    function isConfirmed(data) {
      const { transportStatus } = data
      return transportStatus === transportStatusEnum.CONFIRMED.value
    }
    async function getBatchDataByShipmentPlanId() {
      const res = await wfsService.getBatchDataByShipmentPlanId(shipmentPlanId)
      shipmentBatchDataSource.value.length = 0
      shipmentBatchDataSource.value = res
    }
    async function getShipmentPlanItemDataByQueryBatch(planId) {
      const filter = {
        batchId: planId,
        status: 'IN_PREP',
        batchStatus: 'all',
        amzStatus: 'WORKING',
        channel: 'wfs',
        productCode: '',
      }
      const items = await wfsService.getShipmentPlanItemDataByQueryBatch(filter)
      shipmentBatchItemsDataSource.value.length = 0
      shipmentBatchItemsDataSource.value = items
    }
    onMounted(() => {
      getBatchDataByShipmentPlanId()
    })
    // #region Boxing
    const boxActionButtons = [
      {
        event: boxActionEnum.SCAN.event,
        hint: boxActionEnum.SCAN.text,
        icon: 'bi bi-upc-scan',
        type: 'normal',
      },
      {
        event: boxActionEnum.UPDATE.event,
        hint: boxActionEnum.UPDATE.text,
        icon: 'bi bi-save text-success font-weight-bolder',
        type: 'normal',
      },
      {
        event: boxActionEnum.DELETE.event,
        hint: boxActionEnum.DELETE.text,
        icon: 'bi bi-trash text-danger font-weight-bolder',
        type: 'normal',
      },
    ]
    // Box Items
    const boxItem = ref({
      id: null,
      boxId: null,
      batchId: null,
      itemId: null,
      quantity: 0,
    })
    const dropItem = ref({
      itemId: null,
      quantity: 0,
      assignmentQuantity: 0,
      assignmentType: null, // createSingleAssignAll, createSingleAssignSome, createMultipleAssignAllBox, createMultipleAssignAllItem
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
    })
    function setInitialBoxItem() {
      boxItem.value = {
        id: null,
        boxId: null,
        batchId: null,
        itemId: null,
        quantity: 0,
      }
    }
    function setInitialDropItem() {
      dropItem.value = {
        itemId: null,
        quantity: 0,
        assignmentQuantity: 0,
        assignmentType: null,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
      }
    }
    const boxes = ref([])
    // Get Boxes
    async function getBoxes(shipmentBatchId) {
      const result = await wfsService.findAllBoxesByBatchId(shipmentBatchId)
      boxes.value.length = 0
      boxes.value = result.map(box => ({
        ...box,
        boxItems: [],
      }))
      if (result.length === 0) {
        const data = {
          id: null,
          companyId,
          storeId,
          warehouseId: userWarehouseId.value,
          batchId: shipmentBatchId,
          itemAction: ItemActionTypeEnum.wfs.value,
        }
        await wfsService.createBox(data)
        await getBoxes(shipmentBatchId)
      }
    }
    const showBoxCollapse = ref(false)
    // Get Box Items
    async function getBoxItems(boxId) {
      const filter = {
        accountNo: '',
        companyId: null,
        storeId: null,
        warehouseId: null,
        q: '',
        beginDate: null,
        endDate: null,
        batchId: selectedShipmentBatchId.value,
        boxId: boxId,
      }
      const result = await wfsService.getShipmentPlanItemBoxMapItemByBox(filter)
      if (result.length > 0) {
        const totalQuantity = result.reduce((acc, cur) => acc + cur.quantity, 0)
        boxes.value.find(x => x.id === boxId).boxItems = result
        boxes.value.find(x => x.id === boxId).itemQuantity = totalQuantity
      } else {
        boxes.value.find(x => x.id === boxId).boxItems = []
        boxes.value.find(x => x.id === boxId).itemQuantity = 0
      }
    }
    async function onClickAddBox() {
      const data = {
        id: null,
        companyId,
        storeId,
        warehouseId: userWarehouseId.value,
        batchId: selectedShipmentBatchId.value,
        itemAction: ItemActionTypeEnum.wfs.value,
      }
      await wfsService.createBox(data)
      await getBoxes(selectedShipmentBatchId.value)
    }
    async function reloadBatchData() {
      await getBatchDataByShipmentPlanId()
      await getShipmentPlanItemDataByQueryBatch(selectedShipmentBatchId.value)
      await getBoxes(selectedShipmentBatchId.value)
    }
    async function triggerBoxingItem(boxId) {
      await getBoxItems(boxId)
      await getBatchDataByShipmentPlanId()
      await getShipmentPlanItemDataByQueryBatch(selectedShipmentBatchId.value)
    }
    // Scan Box
    async function onClickScanBox(data) {
      await getBoxItems(data.id)
      showBoxCollapse.value = true
    }
    async function onBoxItemSearchTextKeyDown(e, box) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          const data = {
            id: null,
            batchId: selectedShipmentBatchId.value,
            boxId: box.id,
            itemId: null,
            code: e.event.target.value,
            quantity: 1,
          }
          await wfsService.scanItemMapByCode(data)
          e.component.option('value', '')
          await triggerBoxingItem(box.id)
        }
      }
    }
    async function onBoxItemQuantityUpdateKeyDown(e, item, box) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          const data = {
            id: item.id,
            batchId: item.batchId,
            boxId: item.boxId,
            itemId: item.itemId,
            code: item.upc,
            quantity: parseInt(e.event.target.value, 10) || 0,
          }
          const result = await wfsService.updateItemMapQuantity(data)
          await triggerBoxingItem(box.id)
        }
      }
    }
    async function onBoxItemQuantityUpdate(e, item, box) {
      const data = {
        id: item.id,
        batchId: item.batchId,
        boxId: item.boxId,
        itemId: item.itemId,
        code: item.upc,
        quantity: parseInt(e.event.target.value, 10) || 0,
      }
      const result = await wfsService.updateItemMapQuantity(data)
      await triggerBoxingItem(box.id)
    }
    // Update Box Dimensions
    async function onClickUpdateBox(data) {
      try {
        const dimensions = {
          id: data.id,
          length: parseInt(data.length, 10) || 0,
          width: parseInt(data.width, 10) || 0,
          height: parseInt(data.height, 10) || 0,
          weight: parseInt(data.weight, 10) || 0,
        }
        await wfsService.updateBoxDimensions(dimensions)
        Notify.success('Box dimensions updated successfully.')
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      }
    }
    // Delete Box
    async function onClickDeleteBox(box) {
      await wfsService.deleteBatchBoxById(box.id)
      await reloadBatchData()
    }
    function boxActionClickEvent(e, box) {
      const { event } = e.itemData
      switch (event) {
        case boxActionEnum.SCAN.event:
          onClickScanBox(box)
          break
        case boxActionEnum.UPDATE.event:
          onClickUpdateBox(box)
          break
        case boxActionEnum.DELETE.event:
          onClickDeleteBox(box)
          break
        default:
          break
      }
    }
    // #endregion
    // #region Box Items Actions
    const boxItemActionButtons = [
      {
        event: boxItemActionEnum.PRINT.event,
        hint: boxItemActionEnum.PRINT.text,
        icon: 'bi bi-printer',
        type: 'normal',
      },
      {
        event: boxItemActionEnum.DELETE.event,
        hint: boxItemActionEnum.DELETE.text,
        icon: 'bi bi-trash',
        type: 'normal',
      },
    ]
    async function deleteBoxItem(item, box) {
      await wfsService.deleteShipmentBoxMapById(item.id)
      await triggerBoxingItem(box.id)
    }
    function boxItemActionClickEvent(e, item, box) {
      const { event } = e.itemData
      switch (event) {
        case boxItemActionEnum.DELETE.event:
          deleteBoxItem(item, box)
          break
        default:
          break
      }
    }
    // #endregion
    async function onClicklShipmentBatchBoxing(data) {
      selectedShipmentBatchName.value = data.shipmentId
      selectedShipmentBatchId.value = data.id
      await getShipmentPlanItemDataByQueryBatch(data.id)
      await getBoxes(data.id)
      if (shipmentBatchItemsDataSource.value.length > 0) {
        showBoxingSection.value = true
        showShipSection.value = false
      } else {
        showBoxingSection.value = false
      }
    }
    const draggedItem = ref(null)
    const startDrag = ref(false)
    const showEnterBoxDimensions = ref(false)
    const isCarrierSmallParcel = ref(false)
    const isCarrierLTL = ref(false)
    const isNotCarrierSmallParcel = ref(false)
    const isNotCarrierLTL = ref(false)
    // Drag Items
    function onItemDragStart(e, item) {
      draggedItem.value = item
      const qty = item.quantity - item.boxedQuantity
      boxItem.value = {
        id: null,
        boxId: null,
        batchId: selectedShipmentBatchId.value,
        itemId: item.id,
        quantity: qty,
      }
      dropItem.value = {
        itemId: item.id,
        quantity: qty,
        assignmentQuantity: 0,
        assignmentType: null,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
      }
      startDrag.value = true
    }
    function onItemDragEnd(e) {
      setTimeout(() => {
        startDrag.value = false
      }, 100)
    }
    // #region Create Box And Drop All
    const overDropZoneBoxAll = ref(false)
    async function onDropBoxAll(e) {
      e.preventDefault()
      dropItem.value.assignmentType = assingmentTypeEnum.CreateSingleAssignAll.value
      dropItem.value.assignmentQuantity = dropItem.value.quantity
      const data = dropItem.value
      try {
        await wfsService.autoAssignBoxItemMap(data)
        await reloadBatchData()
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      } finally {
        setInitialDropItem()
        setInitialDropItem()
        overDropZoneBoxAll.value = false
      }
    }
    function onDragOverBoxAll(e) {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
      overDropZoneBoxAll.value = true
    }
    // #endregion
    // #region Create Box And Drop Existing Box
    const overDropZoneBoxExisting = ref(false)
    const showDropZoneBoxExistingPopup = ref(false)
    function onDropBoxExisting(e) {
      e.preventDefault()
      showDropZoneBoxExistingPopup.value = true
      overDropZoneBoxExisting.value = false
    }
    function onDragOverBoxExisting(e) {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
      overDropZoneBoxExisting.value = true
    }
    function onHidingBoxExistingPopup(e) {
      setInitialBoxItem()
    }
    async function onClickSaveBoxItem() {
      const list = []
      if (boxItem.value.quantity === 0) {
        Notify.error('Quantity must be greater than 0.')
        return
      }

      if (!boxItem.value.boxId) {
        Notify.error('Please select a box.')
        return
      }

      list.push(boxItem.value)
      await wfsService.assignBulkBoxItemMap(list)
      showDropZoneBoxExistingPopup.value = false
      await reloadBatchData()
    }
    // #endregion
    // #region Create Box And Drop Some
    const overDropZoneBoxSome = ref(false)
    const showDropZoneBoxSomeItemPopup = ref(false)
    function onDropBoxSome(e) {
      e.preventDefault()
      dropItem.value.assignmentType = assingmentTypeEnum.CreateSingleAssignSome.value
      overDropZoneBoxSome.value = false
      showDropZoneBoxSomeItemPopup.value = true
    }
    function onDragOverBoxSome(e) {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
      overDropZoneBoxSome.value = true
    }
    function onHidingBoxSomeItemPopup(e) {
      showEnterBoxDimensions.value = false
      setInitialBoxItem()
      setInitialDropItem()
    }
    async function onClickSaveBoxSomeItem() {
      const data = dropItem.value
      await wfsService.autoAssignBoxItemMap(data)
      showDropZoneBoxSomeItemPopup.value = false
      await reloadBatchData()
    }
    // #endregion
    // #region Create Box And Drop Multipack
    const multipleQuantityText = ref('Quantity Per Box')
    function onChangeMultipackSwitcher(e) {
      dropItem.value.assignmentType = e.value
        ? assingmentTypeEnum.CreateMultipleAssignAllBox.value
        : assingmentTypeEnum.CreateMultipleAssignAllItem.value

      multipleQuantityText.value = e.value ? 'Box Quantity' : 'Quantity Per Box'
    }
    const overDropZoneBoxMultipack = ref(false)
    const showDropZoneBoxMultipackPopup = ref(false)
    function onDropBoxMultipack(e) {
      e.preventDefault()
      dropItem.value.assignmentType = assingmentTypeEnum.CreateMultipleAssignAllItem.value
      showDropZoneBoxMultipackPopup.value = true
      overDropZoneBoxMultipack.value = false
    }
    function onDragOverBoxMultipack(e) {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
      overDropZoneBoxMultipack.value = true
    }
    function onHidingBoxMultipackPopup(e) {
      showEnterBoxDimensions.value = false
      setInitialBoxItem()
      setInitialDropItem()
    }
    async function onClickSaveBoxMultipackItem() {
      const data = dropItem.value
      await wfsService.autoAssignBoxItemMap(data)
      showDropZoneBoxMultipackPopup.value = false
      await reloadBatchData()
    }
    // #endregion
    // #region Ship Section
    const packageContentTypes = ref(
      [
        {
          text: 'Single SKU',
          value: 'SINGLE SKU',
        },
        {
          text: 'Multiple SKU',
          value: 'MIXED SKUS',
        },
      ],
    )
    const spShipmentPackage = ref({
      length: 0,
      lengthUOM: 'IN',
      width: 0,
      height: 0,
      weight: 0,
      weightUOM: 'LB',
      numberOfCases: 0,
      packageContent: '',
    })

    const ltlShipmentPackage = ref({
      length: 48,
      lengthUOM: 'IN',
      width: 40,
      height: 0,
      weight: 0,
      weightUOM: 'LB',
      numberOfPallet: 0,
      isStackable: null,
      packageType: 'PALLET',
    })

    const shippingForm = ref({
      carrierType: null,
      freightType: null,
      originLocationId: null,
      originLocation: null,
      returnLocation: null,
      pickupDate: new Date(),
      contactDetails: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
      },
      spShipmentPackages: [],
      ltlShipmentPackages: [],
      ltlShipmentPackageInfo: {
        singleSku: 0,
        mixedSku: 0,
        freightClass: null,
        declaredValue: 0,
      },
      noCarrierSmallParcel: {
        singleSku: 0,
        mixedSku: 0,
      },
      noCarrierLtl: {
        pallets: 0,
        singleSkuMasterCases: 0,
        multipleSkuMasterCases: 0,
      },
    })

    const currentPalletContact = ref({
      id: null,
      key: null,
      value: null,
      tenantId: null,
      scopeType: null,
      valueType: null,
      version: null,
      referenceId: null,
    })

    // init get contact details
    function getCurrentPalletContactInfo() {
      tenantService.findByKeyAndCurrentTenantId(managedKeysEnum.WFS_LTL_CONTACT_INFO.key).then(result => {
        const data = { ...result.data[0] }
        if (data.id) {
          currentPalletContact.value = {
            id: data.id,
            key: data.key,
            value: JSON.parse(data.value),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEFRONTEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
          shippingForm.value.contactDetails = {
            firstName: currentPalletContact.value.firstName,
            lastName: currentPalletContact.value.lastName,
            email: currentPalletContact.value.email,
            phone: currentPalletContact.value.fax,
          }
        }
      })
    }

    function createOrUpdatePalletContactSetting() {
      let data = {
        firstName: shippingForm.value.contactDetails.firstName,
        lastName: shippingForm.value.contactDetails.lastName,
        email: shippingForm.value.contactDetails.email,
        phone: shippingForm.value.contactDetails.phone,
      }
      data = JSON.stringify(data)
      let palletContact = {
        id: null,
        key: managedKeysEnum.PALLETCONTACT.key,
        value: data,
        tenantId: this.userTenantId,
        scopeType: scopeTypeEnum.TENANTSCOPEFRONTEND.key,
        valueType: valueTypeEnum.JSON.key,
        version: null,
        referenceId: this.headerInfo.storeId,
      }
      if (currentPalletContact.value.id) {
        currentPalletContact.value = data
        palletContact = { ...currentPalletContact.value }
        tenantService
          .update(palletContact)
          .then(result => {})
      } else {
        tenantService
          .create(palletContact)
          .then(result => {})
      }
    }

    onMounted(() => {
      getCurrentPalletContactInfo()
    })

    shippingForm.value.spShipmentPackages.push(spShipmentPackage.value)
    shippingForm.value.ltlShipmentPackages.push(ltlShipmentPackage.value)

    async function getBoxesForShipment() {
      const result = await wfsService.findAllBoxesByBatchId(selectedShipmentBatchId.value)
      result.forEach(item => {
        shippingForm.value.spShipmentPackages.push({
          length: item.length,
          lengthUOM: 'IN',
          width: item.width,
          height: item.height,
          weight: item.weight,
          weightUOM: 'LB',
          numberOfCases: 0,
          packageContent: '',
        })
      })
    }

    const carrierTypes = ref(WFSCarrierTypeEnum.list())
    const freightClass = ref(FreightClassEnum.list())
    const stackableTypes = ref(StatusEnum.list())
    const freightTypes = ref([])
    const addresses = ref([])
    const shippedToAddress = ref('')

    watch(() => shippingForm.value.carrierType, newValue => {
      if (newValue) {
        const result = WFSFreightTypeEnum.list(newValue)
        freightTypes.value = result
      }
    })

    watch(() => shippingForm.value.freightType, newValue => {
      if (newValue) {
        shippingForm.value.spShipmentPackages.length = 0
        shippingForm.value.ltlShipmentPackages.length = 0

        if (newValue === WFSFreightTypeEnum.SP.value) {
          isCarrierSmallParcel.value = true
          isCarrierLTL.value = false
          isNotCarrierSmallParcel.value = false
          isNotCarrierLTL.value = false
          getBoxesForShipment()
        }
        if (newValue === WFSFreightTypeEnum.LTL.value) {
          isCarrierLTL.value = true
          isCarrierSmallParcel.value = false
          isNotCarrierSmallParcel.value = false
          isNotCarrierLTL.value = false
        }
        if (newValue === WFSFreightTypeEnum.SPO.value) {
          isNotCarrierSmallParcel.value = true
          isNotCarrierLTL.value = false
          isCarrierLTL.value = false
          isCarrierSmallParcel.value = false
        }
        if (newValue === WFSFreightTypeEnum.LTLO.value) {
          isNotCarrierLTL.value = true
          isNotCarrierSmallParcel.value = false
          isCarrierLTL.value = false
          isCarrierSmallParcel.value = false
        }
      }
    })

    const existingAddresses = ref([])
    const fromAddresses = ref([])
    function getCompanyAddresses() {
      commonEntityService.fetchAddressesByTenantAndCompany(companyId).then(result => {
        const { data } = result
        existingAddresses.value = data
        data.forEach(item => {
          let suiteNumber = ''
          if (item.commonHub.commonHubType === 'warehouse') {
            suiteNumber = `Suite # ${accountNo.value}`
          }
          let line = item.line1
          line += item.line2 ? ` ${item.line2}` : ''
          line += item.line3 ? ` ${item.line3}` : ''
          const addressStringHtml = `${item.name}<br />${line} ${suiteNumber}<br />${item.city}, ${item.state} ${item.zipcode}`
          const addressString = `${item.name} ${line} ${suiteNumber} ${item.city}, ${item.state} ${item.zipcode}`
          fromAddresses.value.push({
            id: item.id,
            textHtml: addressStringHtml,
            text: addressString,
          })
        })
      })
    }
    const reloadChargeComp = ref(false)
    const isConfirmedShipment = ref(false)

    async function onClickShipmentBatchShip(data) {
      isConfirmedShipment.value = isConfirmed(data)

      selectedShipmentBatchName.value = data.shipmentId
      const canTransmit = await wfsService.canTransmitBatch(data.id)
      if (!canTransmit) {
        Notify.warning('Please complete boxing for all items.')
        return
      }
      const { id, shippedTo } = data
      selectedShipmentBatchId.value = id
      showBoxingSection.value = false
      reloadChargeComp.value = true
      showShipSection.value = true
      await getShipmentPlanItemDataByQueryBatch(id)
      const result = await commonEntityService.getAmazonAddressById(shippedTo)
      if (result) {
        shippedToAddress.value = `${result.line1 || ''} ${result.line2 || ''}, ${result.city || ''}, ${result.state || ''} ${result.country || ''}, ${result.zipcode || ''}`
      }
      getCompanyAddresses()
    }

    const activeTab = ref('shipping-items')
    function changeShippingTab(tab) {
      activeTab.value = tab
    }

    function onFromAddressChanged(e) {
      const { value } = e
      const result = existingAddresses.value.find(x => x.id === value)
      if (result) {
        shippingForm.value.originLocation = {
          name: result.name,
          line1: result.line1,
          line2: result.line2 ? `${result.line2} Suite ${accountNo.value}` : `Suite ${accountNo.value}`,
          city: result.city,
          state: result.state,
          zipcode: result.zipcode,
          country: updateCountryCode(result.country),
          phone: result.phone,
        }

        shippingForm.value.returnLocation = {
          name: result.name,
          line1: result.line1,
          line2: result.line1 ? `${result.line2} Suite ${accountNo.value}` : `Suite ${accountNo.value}`,
          city: result.city,
          state: result.state,
          zipcode: result.zipcode,
          country: updateCountryCode(result.country),
          phone: result.phone,
        }
      }
    }
    // Small Parcel Master Case Details
    function onClickDeleteSpShipmentPackage(index) {
      if (shippingForm.value.spShipmentPackages.length > 1) {
        shippingForm.value.spShipmentPackages.splice(index, 1)
      }
    }
    function onClickDownloadShippingLabel(index, isBox = true) {
      let box = null
      let totalCount = 0
      let packageContent = ''
      if (isBox) {
        box = shippingForm.value.spShipmentPackages[index]
        totalCount = shippingForm.value.spShipmentPackages.length
        packageContent = box.packageContent
      }

      const params = {
        shipmentId: selectedShipmentBatchName.value,
        companyName: 'Devalli LLC',
        isBox: isBox,
        originLocation: shippingForm.value.originLocation,
        returnLocation: shippingForm.value.returnLocation,
        numberOf: index + 1,
        totalCount: totalCount,
        packageContent: packageContent,
      }
      downloadWalmartShippingLabel(params)
    }
    function onClickAddSpShipmentPackage() {
      shippingForm.value.spShipmentPackages.push(
        {
          length: 0,
          lengthUOM: 'IN',
          width: 0,
          height: 0,
          weight: 0,
          weightUOM: 'LB',
          numberOfCases: 0,
          packageContent: '',
        },
      )
    }
    // LTL Pallet
    function onClickDeleteLTLPallet(index) {
      if (shippingForm.value.ltlShipmentPackages.length > 1) {
        shippingForm.value.ltlShipmentPackages.splice(index, 1)
      }
    }
    function onClickAddLTLPallet() {
      shippingForm.value.ltlShipmentPackages.push({
        length: 48,
        lengthUOM: 'IN',
        width: 40,
        height: 0,
        weight: 0,
        weightUOM: 'LB',
        numberOfPallet: 0,
        isStackable: null,
        packageType: 'PALLET',
      })
    }
    // #endregion
    // #region Create Carrier Quote
    const rateInfo = ref({
      shipmentId: null,
      shipmentNumber: null,
      rateQuotes: null,
    })
    const showRateInfo = ref(false)
    async function onClickCreateCarrierQuote() {
      let data = {}
      // Todo: Need to add validation
      if (isCarrierSmallParcel.value) {
        const packages = shippingForm.value.spShipmentPackages.map((item, index) => (
          {
            packageSequenceNumber: index + 1,
            stackable: false,
            ...item,
          }
        ))
        data = {
          batchId: selectedShipmentBatchId.value,
          mode: 'PARCEL',
          shipmentPackages: packages,
        }
      } else if (isCarrierLTL.value) {
        const packages = shippingForm.value.ltlShipmentPackages.map((item, index) => (
          {
            packageSequenceNumber: index + 1,
            ...item,
          }
        ))
        const loadTypes = []
        const totalPallets = shippingForm.value.ltlShipmentPackages.reduce((accumulator, currentItem) => accumulator + currentItem.numberOfPallet, 0)
        loadTypes.push({
          loadType: 'PALLET',
          count: totalPallets,
        })
        loadTypes.push({
          loadType: 'Single SKU',
          count: shippingForm.value.ltlShipmentPackageInfo.singleSku,
        })
        loadTypes.push({
          loadType: 'Mixed SKU',
          count: shippingForm.value.ltlShipmentPackageInfo.mixedSku,
        })
        data = {
          batchId: selectedShipmentBatchId.value,
          mode: 'LTL',
          customerName: `${shippingForm.value.contactDetails.firstName} ${shippingForm.value.contactDetails.lastName}`,
          phone: shippingForm.value.contactDetails.phone,
          freightClass: shippingForm.value.ltlShipmentPackageInfo.freightClass,
          declaredValue: shippingForm.value.ltlShipmentPackageInfo.declaredValue,
          shipmentPackages: packages,
          loadTypes,
        }
      }
      data = {
        ...data,
        originLocation: shippingForm.value.originLocation,
        returnLocation: shippingForm.value.returnLocation,
      }
      const result = await wfsService.getCarrierRateQuote(data)
      const rate = result.data.body
      if (rate && rate.rateQuotes && rate.rateQuotes.length > 0) {
        showRateInfo.value = true
      }
      rateInfo.value = {
        shipmentId: rate.shipmentId,
        shipmentNumber: rate.shipmentNumber,
        rateQuotes: { ...rate.rateQuotes[0] },
      }
      createOrUpdatePalletContactSetting()
    }
    async function confirmCarrierRateQuote() {
      await wfsService.confirmCarrierRateQuote(selectedShipmentBatchId.value)
    }
    async function onClickDownloadLabel() {
      const result = await wfsService.printCarrierLabel(selectedShipmentBatchId.value)
      const data = result.data.body
      const linkSource = `data:application/pdf;base64,${data.labelData}`
      const downloadLink = document.createElement('a')
      const fileName = `${data.shipmentId}.pdf`
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    }

    async function onClickVoidCarrierRateQuote() {
      const data = shipmentBatchDataSource.value.find(x => x.id === selectedShipmentBatchId.value)
      if (data) {
        const { prepTime } = data
        if (!prepTime) {
          Notify.warning('Cannot void shipment. Please enter prep time.')
          return
        }
        const now = moment(moment().format('YYYY-MM-DD HH:mm:ss'))
        const prepTimeFormated = moment(prepTime).add(24, 'hours')
        const result = moment.duration(moment(prepTimeFormated).diff(now))
        const diff = Math.trunc(result.asMilliseconds())
        if (diff <= 0) {
          Notify.warning('Cannot void shipment. Prep time is expired.')
          return
        }
        await wfsService.voidCarrierRateQuote(selectedShipmentBatchId.value)
      }
    }
    // #endregion
    // #region Add Extra Charges
    function addCharges() {
      store.dispatch('charge/setCharges', selectedShipmentBatchId.value)
      const chargeItems = store.getters['charge/getCharges']
      const getChargeErrors = store.getters['charge/getChargeErrors']
      if (getChargeErrors.length > 0) {
        Notify.danger(getChargeErrors.join(','))
      } else {
        const charges = [...chargeItems]
        itemChargeService.create(selectedShipmentBatchId.value, charges)
          .then(() => {
            store.dispatch('charge/clearCharges')
            reloadChargeComp.value = false
          }).then(() => {
            reloadChargeComp.value = true
          })
      }
    }
    // #endregion
    // #region Edit Item Quantity
    const popupUpdateShipmentBatchItemQuantityRef = ref(null)
    const editableShipmentBatchItemId = ref(null)
    const editableShipmentBatchItemBarcode = ref(null)
    const renderUpdateShipmentBatchItemQuantity = ref(false)
    function onClickEditShipmentBatchItemQuantity(item) {
      const { upc, id } = item
      editableShipmentBatchItemBarcode.value = upc
      editableShipmentBatchItemId.value = parseInt(id, 10)
      const popup = popupUpdateShipmentBatchItemQuantityRef.value.instance
      popup.show()
    }
    function onEmitCloseEditShipmentBatchItemQuantityPopup() {
      renderUpdateShipmentBatchItemQuantity.value = false
      const popup = popupUpdateShipmentBatchItemQuantityRef.value.instance
      popup.hide()
    }
    function onShownUpdateShipmentBatchItemQuantityPopup() {
      renderUpdateShipmentBatchItemQuantity.value = true
    }
    function onHiddenUpdateShipmentBatchItemQuantityPopup() {
      editableShipmentBatchItemId.value = null
      editableShipmentBatchItemBarcode.value = null
    }
    const today = new Date()
    // #endregion
    return {
      onInitialized,
      customizeColumns,
      shipmentBatchDataSource,
      isDisabledAction,
      renderShipmentStatus,
      renderAmzStatus,
      renderTransporStatus,
      isConfirmed,
      isBoxingDoneForBatch,
      onClicklShipmentBatchBoxing,
      selectedShipmentBatchId,
      selectedShipmentBatchName,
      shipmentBatchItemsDataSource,
      showBoxingSection,
      boxActionButtons,
      boxes,
      showBoxCollapse,
      boxItem,
      getBoxItems,
      onBoxItemSearchTextKeyDown,
      onBoxItemQuantityUpdateKeyDown,
      onBoxItemQuantityUpdate,
      onClickAddBox,
      boxActionClickEvent,
      boxItemActionButtons,
      boxItemActionClickEvent,
      startDrag,
      onItemDragStart,
      onItemDragEnd,
      overDropZoneBoxAll,
      onDropBoxAll,
      onDragOverBoxAll,
      overDropZoneBoxExisting,
      onDropBoxExisting,
      onDragOverBoxExisting,
      showDropZoneBoxExistingPopup,
      onHidingBoxExistingPopup,
      onClickSaveBoxItem,
      overDropZoneBoxSome,
      onDropBoxSome,
      onDragOverBoxSome,
      showDropZoneBoxSomeItemPopup,
      onHidingBoxSomeItemPopup,
      onClickSaveBoxSomeItem,
      overDropZoneBoxMultipack,
      onDropBoxMultipack,
      onDragOverBoxMultipack,
      onChangeMultipackSwitcher,
      multipleQuantityText,
      showDropZoneBoxMultipackPopup,
      showEnterBoxDimensions,
      onHidingBoxMultipackPopup,
      onClickSaveBoxMultipackItem,
      dropItem,
      draggedItem,
      onClickShipmentBatchShip, // Ship Section
      fromAddresses,
      onFromAddressChanged,
      shippedToAddress,
      showShipSection,
      activeTab,
      changeShippingTab,
      isCarrierSmallParcel,
      isCarrierLTL,
      isNotCarrierSmallParcel,
      isNotCarrierLTL,
      shippingForm,
      packageContentTypes,
      carrierTypes,
      freightTypes,
      freightClass,
      stackableTypes,
      addresses,
      onClickAddSpShipmentPackage,
      onClickDeleteSpShipmentPackage,
      onClickDeleteLTLPallet,
      onClickAddLTLPallet,
      onClickDownloadLabel,
      onClickVoidCarrierRateQuote,
      onClickCreateCarrierQuote,
      onClickDownloadShippingLabel,
      rateInfo,
      showRateInfo,
      isConfirmedShipment,
      confirmCarrierRateQuote,
      reloadChargeComp,
      addCharges,
      popupUpdateShipmentBatchItemQuantityRef,
      onShownUpdateShipmentBatchItemQuantityPopup,
      onHiddenUpdateShipmentBatchItemQuantityPopup,
      editableShipmentBatchItemId,
      editableShipmentBatchItemBarcode,
      renderUpdateShipmentBatchItemQuantity,
      onClickEditShipmentBatchItemQuantity,
      onEmitCloseEditShipmentBatchItemQuantityPopup,
      today,
    }
  },
}
</script>
<style lang="scss">
.icon-transition {
  transition: transform 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #e5e7eb;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin-right: 0.625rem;
  i {
    font-size: 1.125rem;
    color: #15202b;
  }
  &:hover {
  transform: rotate(90deg) scale(1.2);
  background-color: #d2d4d9;
  }
}

#drop-zone {
  height: 96px;
  &.drop-zone-zero {
    height: 0;
  }
  .drop-box-option {
    font-size: 16px;
    text-transform: uppercase;
    word-wrap: break-word;
    color: #15202b;
    height: 100%;
    background-color: #e5e7eb;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    transition: background-color 0.1s ease;
  }
  .drop-box-option-hover {
    background-color: transparent;
    color: #e5e7eb;
    border: 1px dashed #d4d4d4;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.4s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}

#drag-zone {
  .drag-item {
    cursor: move;
  }
}
.dx-texteditor-with-floating-label, .dx-texteditor-with-label {
  margin-top: 0px !important;
}
.dark-layout .list-group .list-group-item:not([class*=list-group-item-]).active,
.dark-layout .list-group .list-group-item:not([class*=list-group-item-]):active,
.dark-layout .list-group .list-group-item.list-group-item-action.active,
.dark-layout .list-group .list-group-item.list-group-item-action:active {
    background-color: #283046;
}
.dx-switch-off, .dx-switch-on {
  font-size: 1.0rem;
  font-weight: 400;
  text-align: left;
  padding-left: 0.265rem;
}
</style>
