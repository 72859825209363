/**
 * Enum for Freight Class.
 * @readonly
 * @enum {{key: string, value: string}}
 */

const StackableEnum = Object.freeze({
  NONE: { key: 'NONE', value: null, text: 'Select' },
  YES: { key: 'YES', value: true, text: 'Yes' },
  NO: { key: 'NO', value: false, text: 'No' },
  list: () => Object.values(StackableEnum)
    .filter(value => typeof value === 'object')
    .map(value => ({
      value: value.value,
      text: value.text,
    })),
  find: query => Object.values(StackableEnum)
    .find(value => typeof value === 'object' && (value.key === query || value.value === query)),
})

export default StackableEnum
