<template>
  <div class="w-100">
    <div class="d-flex flex-row justify-content-start" :class="cssClass">
      <div style="margin-right: 0.625rem" class="align-self-center">
        <light-box :items="itemInfo.imageItems" :index="index" :effect="'fade'" @close="index = null" />
        <div class="cursor-pointer" @click="showImage">
          <p-lazy-img width="75px" height="75px" :src="itemInfo.imageUrl || defaultImg" />
        </div>
      </div>
      <div class="d-flex flex-column w-100">
        <div class="d-flex flex-row justify-content-start">
          <div class="font-weight-bold align-self-center flex-grow-1 text-warning">
              {{ itemInfo.title }}
          </div>
        </div>
        <div class="d-flex flex-row w-100">
        <div class="flex-grow-1">
        <div class="d-flex flex-row row justify-content-start">
          <div class="col-6">
            <i class="bi bi-back text-warning cursor-pointer" @click="doCopy(itemInfo.fnsku)"></i>
            Barcode Type:
            <b-dropdown
              v-if="isPrintable"
              split
              size="sm"
              split-variant="secondary"
              variant="secondary"
              :text="itemInfo.fnsku"
              boundary="window"
              @click="onClickPrintFnksuLabel(itemInfo.fnsku, 1)"
            >
              <b-dropdown-form style="width:150px !important; padding-top: 0.125rem !important; padding-bottom: 0.125rem !important;">
                <b-input-group size="sm">
                  <b-form-input v-model="printCount" type="number" autofocus />
                  <b-input-group-append>
                    <b-button size="sm" variant="warning" @click="onClickPrintFnksuLabel(itemInfo.fnsku)">
                      <b-icon-printer-fill scale="1" aria-label="Help" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-dropdown-form>
          </b-dropdown>
            <span v-else>
              {{ itemInfo.fnsku }}
            </span>
          </div>
          <div class="col-6">
            <i class="bi bi-back text-warning cursor-pointer" @click="doCopy(itemInfo.orderNumber)"></i>
            Order #: {{ itemInfo.orderNumber }}
          </div>
        </div>
        <div class="d-flex flex-row row justify-content-start">
          <div class="col-6">
            <i class="bi bi-back text-warning cursor-pointer" @click="doCopy(itemInfo.upc)"></i>
            Barcode: {{ itemInfo.upc }}
          </div>

          <div class="col-6">
            <i class="bi bi-back text-warning cursor-pointer" @click="doCopy(itemInfo.trackingNumber)"></i>
            Tracking #: {{ itemInfo.trackingNumber }}
          </div>
        </div>
        <div class="d-flex flex-row row justify-content-start">
          <div class="col-6">
            <i class="bi bi-back text-warning cursor-pointer" @click="doCopy(itemInfo.asin)"></i>
            Item ID: <a :href="`https://www.walmart.com/ip/${itemInfo.asin}`" target="_blank">{{ itemInfo.asin }}</a>
          </div>
          <div class="col-6">
            Pallet Id: {{ itemInfo.palletId }}
          </div>

        </div>
        <div class="d-flex flex-row row justify-content-start">
          <div class="col-6">
            <i class="bi bi-back text-warning cursor-pointer" @click="doCopy(itemInfo.msku)"></i>
            MSKU: {{ itemInfo.msku }}
          </div>
          <div class="col-6">
            Exp Date: {{ formatedDate(itemInfo.expirationDate) }}
          </div>
        </div>
        <div class="d-flex flex-row row justify-content-start">
          <div class="col-6 offset-6">
            Uploaded At: {{ itemInfo.batchUploadedAt }}
          </div>
        </div>
        </div>
        <div v-if="itemInfo.notes && itemInfoVisible" class="align-self-center">
            <div :id="`info-note-${itemInfo.id}`" class="h3 mb-0 align-self-center" @mouseenter="toggleWithAnimation" @mouseleave="toggleWithAnimation">
              <b-icon
                icon="exclamation-circle-fill"
                variant="warning"
                class="hover-cursor-pointer"
                :animation="iconAnimation"
                @click="doCopy(itemInfo.notes)"
              />
            </div>
            <dx-tooltip
              :target="`#info-note-${itemInfo.id}`"
              :position="'top'"
              :animation="animationConfig"
              :visible="withAnimationVisible"
              :close-on-outside-click="false"
            >
              {{ itemInfo.notes }}
            </dx-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { emitter } from '@/libs/bus'
import { FBAEvents } from '@/libs/constants/events.const'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import truncate from 'lodash/truncate'
import { formatedDateStr } from '@/@core/utils/dateHelper'
import { DxTooltip } from 'devextreme-vue/tooltip'
import { useClipboard } from '@vueuse/core'
import { computed, ref } from '@vue/composition-api'
import { Notify } from '@robustshell/utils/index'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'light-box': CoolLightBox,
    'dx-tooltip': DxTooltip,
  },
  props: {
    item: {
      type: Object,
      required: false,
      default: () => {},
    },
    isPrintable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    cssClass: {
      type: String,
      required: false,
      default: '',
    },
    isTruncated: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    batchUploadedAt: {
      type: String,
      required: false,
      default: '',
    },
    itemInfoVisible: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  setup(props) {
    const { item, isTruncated } = props
    const index = ref(null)
    const printCount = ref(1)
    const withAnimationVisible = ref(false)
    const iconAnimation = ref('throb')
    const animationConfig = ref({
      show: {
        type: 'pop',
        from: {
          scale: 0.1,
          opacity: 0,
        },
        to: {
          scale: 1,
          opacity: 1,
        },
      },
      hide: {
        type: 'pop',
        from: {
          scale: 1,
          opacity: 1,
        },
        to: {
          scale: 0.1,
          opacity: 0,
        },
      },
    })

    const itemInfo = computed(() => {
      const imageItems = item.largeImage ? [item.largeImage] : []
      return {
        id: item.id,
        title: item.title ? item.title : '-',
        notes: item.notes ? item.notes : '',
        asin: item.asin ? item.asin : '-',
        fnsku: item.fnsku ? item.fnsku : '-',
        msku: item.msku ? item.msku : '-',
        upc: item.upc ? item.upc : '-',
        rank: item.rank ? item.rank : '-',
        trackingNumber: item.tracking ? item.tracking : '-',
        orderNumber: item.orderNumber ? item.orderNumber : '-',
        productType: item.productType ? item.productType : '-',
        imageUrl: item.largeImage ? item.largeImage : defaultImageUrl,
        expirationDate: item.expirationDate ? item.expirationDate : '-',
        palletId: item.palletId ? item.palletId : '-',
        batchUploadedAt: item.batchUploadedAt ? item.batchUploadedAt : '-',
        imageItems: imageItems,
      }
    })

    function formatedDate(date) {
      return formatedDateStr(date, 'MM/DD/YYYY')
    }

    function toggleWithAnimation() {
      iconAnimation.value = withAnimationVisible.value ? 'throb' : ''
      withAnimationVisible.value = !withAnimationVisible.value
    }

    function getTitle(title) {
      return isTruncated ? truncate(title, { length: 80 }) : title
    }

    function showImage() {
      index.value = 0
    }

    const { copy } = useClipboard()

    function doCopy(message = '') {
      copy(message)
      Notify.success(`Copied to clipboard: ${message}`, 'Copy')
    }
    return {
      doCopy,
      index,
      printCount,
      withAnimationVisible,
      iconAnimation,
      animationConfig,
      itemInfo,
      formatedDate,
      toggleWithAnimation,
      getTitle,
      showImage,
    }
  },
  methods: {
    onClickPrintFnksuLabel(data, count) {
      const exprDate = formatedDateStr(this.expirationDate, 'MM/DD/YYYY')
      const copies = count || parseInt(this.printCount, 10)
      const payload = {
        barcode: data,
        title: this.itemInfo.title,
        expirationDate: exprDate,
        copies: copies,
      }
      this.printCount = 1

      emitter.emit(FBAEvents.PRINT_FNSKU_LABEL.event, payload)
    },
  },
}
</script>

<style lang="scss" scoped>
  .hover-cursor-pointer {
    &:hover,
    &:focus,
    &:active {
      cursor: pointer;
    }
  }
</style>
