/* eslint-disable prefer-rest-params */
import find from 'lodash/find'
/**
 * Enum for Charge Service Type Enums.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const serviceChargeTypeEnum = Object.freeze({
  PER_ITEM: { key: 'PER_ITEM', value: 'PER_ITEM', text: 'Per Item' },
  PER_BOX: { key: 'PER_BOX', value: 'PER_BOX', text: 'Per Box' },
})

function getServiceChargeTypeEnum(predicate, predicateKey = 'key') {
  const result = find(serviceChargeTypeEnum, { [predicateKey]: predicate })
  return result || { key: 'None', value: null, text: 'None' }
}

function getListServiceChargeTypeEnum() {
  const result = Object.keys(serviceChargeTypeEnum).map(key => serviceChargeTypeEnum[key])
  return result
}

export default serviceChargeTypeEnum

export {
  getServiceChargeTypeEnum,
  getListServiceChargeTypeEnum,
}
