<template>
  <div class="container-fluid">
    <div class="row">
      <div class="px-0 col-12">
        <div class="wizard">
          <div class="work-flow">
              {{ headerInfo.accountNo }} | {{ headerInfo.companyName }} | {{ headerInfo.storeName }}
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import store from '@/store'

export default {
  data() {
    return {
      isIndividualTenant: false,
    }
  },
  computed: {
    headerInfo() {
      const headerInfo = store.getters['wfsForce/getAccountInfo']
      if (this.isIndividualTenant) {
        headerInfo.accountNo = ''
      }
      return headerInfo
    },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData.tenantType === 'INDIVIDUAL') {
      this.isIndividualTenant = true
    }
  },
}
</script>
  <style lang="scss">
  .wizard {
    margin: 0 !important;
    padding: 0 !important;
    background-color: rgba(223, 230, 233, 1);
    width: 100% !important;
    height: 36px;
    font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans,
      Helvetica Neue, Arial, sans-serif;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    >.work-flow {
      padding: 8px 8px;
      font-weight: 500;
      text-align: left;
      font-size: 1rem;
      color: rgba(45, 52, 54,1.0);
      text-transform: uppercase;
    }
  }
  </style>
